import React, { FC } from "react";
import checkMark from "../../assets/icons/circle-check-light.svg";
import "./styles.css";
import styled from "styled-components";

type TabsProps = {
  tabs: {
    label: string;
    index: number;
    completed?: boolean;
    Component: FC<{ index: number; data: any }>;
  }[];
  selectedTab: number;
  onClick?: (index: number) => void;
  orientation?: "horizontal" | "vertical";
  className?: string;
  data?: any;
};

/**
 * Avalible Props
 * @param className string
 * @param tab Array of object
 * @param selectedTab number
 * @param onClick Function to set the active tab
 * @param orientation Tab orientation Vertical | Horizontal
 */
const Tabs: FC<TabsProps> = ({
  className = "tabs-component",
  tabs = [],
  selectedTab = 0,
  onClick,
  orientation = "horizontal",
  data,
}) => {
  const Panel = tabs && tabs.find((tab) => tab.index === selectedTab);

  return (
    <div
      className={
        orientation === "vertical" ? className + " vertical" : className
      }
    >
      <div role="tablist" aria-orientation={orientation}>
        {tabs.map((tab) => (
          <button
            className={
              selectedTab === tab.index
                ? "active"
                : tab.completed
                ? "active completed"
                : ""
            }
            onClick={() => (onClick ? onClick(tab.index) : undefined)}
            key={tab.index}
            type="button"
            role="tab"
            aria-selected={selectedTab === tab.index}
            aria-controls={`tabpanel-${tab.index}`}
            tabIndex={selectedTab === tab.index ? 0 : -1}
            id={`btn-${tab.index}`}
          >
            {tab.completed && <Image src={checkMark} alt="check-circle" />}
            {tab.label}
          </button>
        ))}
      </div>
      <div
        role="tabpanel"
        aria-labelledby={`btn-${selectedTab}`}
        id={`tabpanel-${selectedTab}`}
      >
        {Panel && <Panel.Component index={selectedTab} data={data} />}
      </div>
    </div>
  );
};

const Image = styled.img`
  margin-left: 3px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 1px;
  right:1px;
`;
export default Tabs;
