import React from "react";

type CardValidationErrorProps = {
    show: boolean;
    message?: string
}

const CardValidationError = (props: CardValidationErrorProps) => {

    if (!props.show) {
        return <></>;
    }

    return <div style={{
        backgroundColor: '#F09C8E',
        width: '100%',
        padding: '8px',
        paddingBottom: '30px',
        margin: '0 0 16px 0',
        borderRadius: '8px'
    }}>
        <h2 style={{
            fontSize: '18px',
            fontWeight: 'normal'
        }}>Noe gikk galt!</h2>
        <span>{props.message}</span>
    </div>
}

export default CardValidationError;