import { Damage, UpdateDamage } from "../../../typings";

export enum DamageAction {
    Insert,
    Update,
}

export type DamageCall = (damage: Damage) => void
export type ErrorCall = (err: Error) => void
export type RegisterDamageType = (action: DamageCall, onRequestError: ErrorCall, damage: Damage) => void | Promise<void>
export type UpdateDamageType = (action: DamageCall, onRequestError: ErrorCall, damage: UpdateDamage) => void | Promise<void>