import React from "react";
import styled from "styled-components";

const Header = styled.h2`
  font-size: ${(props) => props.theme.fontSizes.lg};
  font-weight: ${(props) => props.theme.fontWeights.normal};
`;

interface Props {
  children: string;
}
function SectionHeader(props: Props) {
  return <Header>{props.children}</Header>;
}

export default SectionHeader;
