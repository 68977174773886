import React, { ChangeEvent, useState, useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import styled from "styled-components";
import { damageTypesState, carState, damageState, orderState } from "../../../atoms";
import {
  Damage,
  DamagePositionKeys,
  DamagePositionTexts,
  DamageType,
} from "../../../typings";
import MultiplePhotos from "../../Photo/MultiplePhotos";
import Button from "../../View/Button";
import SectionHeader from "../../View/SectionHeader";
import Dropdown, { DropdownItem } from "../../View/Dropdown";
import ValueLabel from "../../View/ValueLabel";
import DamageModal from "./DamageModal";
import Resources from "./../../../resources/Resources";
import { DamageAction, DamageCall } from "./DamageForm.types";
import {
  getActionTitle,
  onRequestError,
  dropdownSelector,
  registerDamage,
  updateDamage,
  getActionButtonTitle,
} from "./DamageForm.utils";
import SimpleSpinner from "../../View/SimpleSpinner";

export interface DamageFormProps {
  action: DamageAction;
  onClose: () => void;
  editedDamage?: Damage | null;
}

const DamageForm = ({ action, onClose, editedDamage }: DamageFormProps) => {
  const order = useRecoilValue(orderState);
  const [registerDamageClicked, setRegisterDamageClicked] = useState(false);
  const [description, setdescription] = useState(
    editedDamage ? editedDamage.description : ""
  );
  const [isDamageModalVisible, setIsDamageModalVisible] = useState(false);
  const damageTypes = useRecoilValue(damageTypesState);
  const isUpdate = action === DamageAction.Update;
  const [imageIds, setImageIds] = useState<string[]>(
    isUpdate ? editedDamage?.imagesIds || [] : []
  );
  const car = useRecoilValue(carState);
  const [choosenDamageType, setChoosenDamageType] = useState<DamageType | null>(
    editedDamage ? (editedDamage?.damageType as DamageType) : null
  );
  const [damages, setDamages] = useRecoilState(damageState);
  const selectType = dropdownSelector(setChoosenDamageType, damageTypes);
  const noTypeError = registerDamageClicked && !choosenDamageType;
  const photosError = registerDamageClicked && imageIds.length === 0;
  const actionTitle = getActionTitle(action);
  const actionButtonTitle = getActionButtonTitle(action);
  const [
    choosenDamagePosition,
    setChoosenDamagePosition,
  ] = useState<DropdownItem | null>(
    editedDamage?.position
      ? {
          id: editedDamage.position.toString(),
          description: DamagePositionTexts[editedDamage.position.toString()],
        }
      : null
  );
  const damagePositions = DamagePositionKeys.map((key) => {
    return {
      id: key,
      description: DamagePositionTexts[key],
    };
  });
  const selectDamagePosition = dropdownSelector(
    setChoosenDamagePosition,
    damagePositions
  );
  const [isLoading, setIsLoading] = useState(false);

  const isFormValid =
    choosenDamageType && choosenDamagePosition && imageIds.length > 0;

  useEffect(() => {
    if (isUpdate) {
      selectType((editedDamage?.damageType as unknown) as DropdownItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const add: DamageCall = (damage: Damage) => {
    setDamages([...damages, damage]);
    onClose();
  };

  const update: DamageCall = (damage: Damage) => {
    setDamages((previousDamages) => {
      let updatedDamages = [...previousDamages];
      let index = updatedDamages.findIndex(
        (element) => element.id === damage.id
      );
      damage.reported_on = new Date();
      damage.reported_by = "Leietager"
      updatedDamages.splice(index, 1, damage);
      return updatedDamages;
    });
    onClose();
  };

  const removeAction: DamageCall = async (damage: Damage) => {
    let res = await Resources.damage.delete(damage.id, order.id);
    if (!res || !res.ok) {
      return console.error("error: ", res);
    }
    setDamages(damages.filter((el) => el.id !== damage.id));
  };

  const removeDamageAction = () => {
    setIsDamageModalVisible(true);
  };

  const getCommon = (
    choosenDamageType: DamageType,
    imagesIds: string[],
    description: string
  ) => ({
    damageType: choosenDamageType,
    imagesIds: imagesIds,
    description: description,
    reported_on: new Date(),
    reported_by: "Leietager",
  });

  async function submit() {
    if (!isFormValid) {
      setRegisterDamageClicked(true);
      return;
    }

    let common = getCommon(
      choosenDamageType as DamageType,
      imageIds,
      description
    );

    setIsLoading(true);

    try {
      switch (action) {
        case DamageAction.Insert:
          await registerDamage(add, onRequestError, {
            carId: car.id as string,
            ...common,
            position: choosenDamagePosition?.id ?? "",
          });
          break;
        case DamageAction.Update:
          await updateDamage(update, onRequestError, {
            damageId: editedDamage?.id || "",
            orderId: order?.id || "",
            data: {
              damageTypeId: choosenDamageType.id,
              imagesIds: imageIds,
              description,
              position: choosenDamagePosition?.id ?? "",
            }
          });
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const submitDamageAction: () => void = () => {
    removeAction(editedDamage as Damage);
    setIsDamageModalVisible(false);
  };

  const onChangeDescription = (evt: ChangeEvent<HTMLTextAreaElement>): void =>
    setdescription(evt.target.value);

  return (
    <NewDamage>
      <DamageModal
        action={DamageAction.Update}
        isOpen={isDamageModalVisible}
        setIsOpen={setIsDamageModalVisible}
        damage={editedDamage as Damage}
        submit={submitDamageAction}
      />
      <SectionHeader>{actionTitle}</SectionHeader>
      <ValueLabel
        label="Skadetype"
        value={
          <Dropdown
            validationError={noTypeError}
            validationMessage="Skadetype må legges til"
            choosenItem={choosenDamageType}
            defaultText="-Velg skadetype-"
            items={damageTypes}
            onClickItem={selectType}
          />
        }
      />
      <ValueLabel
        label="Skadested"
        value={
          <Dropdown
            validationError={noTypeError}
            validationMessage="Skadested må legges til"
            choosenItem={choosenDamagePosition}
            defaultText="-Velg skadested-"
            items={damagePositions}
            onClickItem={selectDamagePosition}
          />
        }
      />
      <TextContainer>
        <TextLabel validationError={false} htmlFor="skadebeskrivelse">
          Beskrivelse:
        </TextLabel>
        <DescriptionTextArea
          validationError={false}
          name="skadebeskrivelse"
          value={description}
          onChange={onChangeDescription}
        />
      </TextContainer>
      <MultiplePhotos imageIds={imageIds} setImageIds={setImageIds} />
      <Warning on={photosError} text="Bilder av skaden må legges til" />
      {!isLoading && (
        <ButtonBar>
          {action === DamageAction.Insert && (
            <Button darkcolor={true} onClick={onClose}>
              Avbryt
            </Button>
          )}
          {isUpdate && (
            <Button
              darkcolor={true}
              onClick={removeDamageAction}
              backgroundColor={"#BB4837"}
              color={"#FFFFFF"}
            >
              Slett
            </Button>
          )}
          <Button notValid={!isFormValid} darkcolor={false} onClick={submit}>
            {actionButtonTitle}
          </Button>
        </ButtonBar>
      )}
      {isLoading && <SimpleSpinner style={{ marginTop: 24 }} />}
      {isUpdate && <CancelLink onClick={onClose}>Avbryt</CancelLink>}
    </NewDamage>
  );
};

export default DamageForm;

export const Warning = ({ on, text }: { on: boolean; text: string }) =>
  on ? <ValidationMessage>{text}</ValidationMessage> : null;

export const DescriptionTextArea = styled.textarea<{
  validationError: boolean;
}>`
  padding: 5px;
  height: 82px;
  border: ${(props) =>
    props.validationError
      ? `1px solid ${props.theme.colors.signalRed}`
      : `0.5px solid ${props.theme.colors.black}`};
  border-radius: 8px;
  resize: none;
`;

export const NewDamage = styled.div`
  width: 100%;
`;

export const TextContainer = styled.div`
  max-width: 380px;
  display: flex;
  flex-direction: column;
`;

export const ButtonBar = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
  gap: 10px;
`;

export const TextLabel = styled.label<{ validationError: boolean }>`
  margin-bottom: ${(props) => props.theme.margins.xs};
  font-size: ${(props) => props.theme.fontSizes.sm};
  color: ${(props) =>
    props.validationError ? props.theme.colors.signalRed : "default"};
`;

export const ValidationMessage = styled.div`
  color: ${(props) => props.theme.colors.signalRed};
  font-size: ${(props) => props.theme.fontSizes.sm};
`;

export const CancelLink = styled.div`
      width: calc(100% - 45px);
  padding-top: 15px;
  height: 30px;
  text-decoration: underline;
  cursor: pointer;
  text-align: right;
`;
