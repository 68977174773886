import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  Header,
  MessageInput,
  ModalContainer,
  SendButton,
  StarsContainer,
  StarWrapper,
  Subtitle,
} from "./FeedbackModal.styled";
import Star from "./View/Star";
import Spinner from "./View/Spinner";

const Ratings = [1, 2, 3, 4, 5];

const FeedbackModal = (props: FeedbackModalProps) => {
  const [currentRating, setCurrentRating] = useState(0);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  async function onSendClick() {
    setLoading(true);
    await props.onSend(currentRating, message);
    setLoading(false);
  }

  useEffect(() => {
    Modal.setAppElement("body");
  }, []);
  return (
    <ModalContainer
      isOpen={props.isOpen}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          zIndex: 200,
        },
      }}
      className="Modal"
    >
      <Header>Fornøyd med opplevelsen?</Header>
      <Subtitle>Din tilbakemelding betyr mye for oss</Subtitle>
      {!loading && (
        <>
          <StarsContainer>
            {Ratings.map((rating) => (
              <StarWrapper
                key={rating}
                onClick={() => setCurrentRating(rating)}
              >
                <Star selected={currentRating >= rating} />
              </StarWrapper>
            ))}
          </StarsContainer>
          <MessageInput
            placeholder="Din tilbakemelding... (valgfri)"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <SendButton onClick={onSendClick} disabled={currentRating === 0}>
            Send
          </SendButton>
        </>
      )}
      {loading && <Spinner marginTop={0} />}
    </ModalContainer>
  );
};

interface FeedbackModalProps {
  isOpen: boolean;
  onSend: (rating: number, message: string) => Promise<void>;
}

export default FeedbackModal;
