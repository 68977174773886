import React, { useState } from "react";
import styled from "styled-components";
import { api } from "../../resources/Resources";
import SimpleSpinner from "../View/SimpleSpinner";
import drivingLicenseFront from "../../assets/icons/driving_license_front.svg";
import drivingLicenseBack from "../../assets/icons/driving_license_back.svg";

interface Props {
  onOpenFile: (file: File) => void;
  imageId?: string | null;
  front: boolean;
  viewOnly?: boolean;
}

const Image = styled.img`
  width: 300px;
  grid-area: first;
  background: white;
  z-index: 100;
  @media screen and (max-width: 786px) {
    width: 110px;
    object-fit: contain;
  }
`;

const Label = styled.label<{ disabled?: boolean }>`
  width: 300px;
  display: grid;
  grid-template-areas: "first";
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  @media screen and (max-width: 786px) {
    width: 100%;
  }
`;

const Input = styled.input`
  grid-area: first;
  z-index: 1;
  height: 100%;
  font-size: 2px;
`;

export function DrivingLicensePhoto(props: Props) {
  const [fetchLoad, setFetchLoad] = useState(false);

  const placeholderImage = props.front
    ? drivingLicenseFront
    : drivingLicenseBack;
  const altLabel = props.front ? "Førerkort forside" : "Førerkort bakside";

  const finishedFetch = (
    evt: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    evt.preventDefault();
    setFetchLoad(false);
  };

  return (
    <div>
      <Label>
        {fetchLoad ? <SimpleSpinner /> : null}
        <Image
          onLoad={finishedFetch}
          src={
            props.imageId
              ? `${api}GetFile/${props.imageId}`
              : placeholderImage
              ? placeholderImage
              : undefined
          }
          alt={altLabel}
        />
        {!props.viewOnly ? <Input
          type="file"
          accept=".jpeg, .jpg, .png, .gif, .bmp"
          onChange={(evt) => {
            if (evt.target.files?.length) {
              setFetchLoad(true);
              props.onOpenFile(evt.target.files[0]);
            }
          }}
        /> : null}
      </Label>
    </div>
  );
}

export default DrivingLicensePhoto;
