import React, { useState, KeyboardEvent } from "react";
import addButton from "../../assets/icons/add_button.svg";
import DamageForm from "./DamageForm/DamageForm";
import { DamageAction } from "./DamageForm/DamageForm.types";
import Section from "../View/Section";
import { damagesBefore, damagesInitial, damagesDuring } from "../../atoms";
import { useRecoilValue } from "recoil";
import DamagesContainer from "./DamagesContainer";
import { Damage } from "./../../typings";
import { DamagesProps, DamagesPeriod, DamagesList } from "./Damages.types";
import { isOrderCanceled, isOrderFinished } from "./../Order/utils"
import styled from "styled-components";
import { CardContainer } from "../../styled-components";

const getDamagesList = (
  before: Damage[],
  initial: Damage[],
  during: Damage[]
): DamagesList => [
  {
    key: "damages-01",
    period: DamagesPeriod.Before,
    damages: before,
    title: "",
  },
  {
    key: "damages-02",
    period: DamagesPeriod.Initial,
    damages: initial,
    title: "Dine registrerte skader før leie",
  },
  {
    key: "damages-03",
    period: DamagesPeriod.During,
    damages: during,
    title: "",
  },
];

const Damages = ({ order, noDamagesText }: DamagesProps) => {
  const [isDamageFormVisible, setIsDamageFormVisible] = useState(false);
  const before: Damage[] = useRecoilValue(damagesBefore);
  const initial: Damage[] = useRecoilValue(damagesInitial);
  const during: Damage[] = useRecoilValue(damagesDuring);
  const damagesList: DamagesList = getDamagesList(before, initial, during);
  const canAddDamage = !(isOrderCanceled(order) || isOrderFinished(order));
  const noDamages: boolean =
    !before.length && !initial.length && !during.length;
  const hideDamageForm = () => setIsDamageFormVisible(false);
  const showDamageForm = () => setIsDamageFormVisible(true);
  const editDamage = () => hideDamageForm();

  const submitWithEnter = (evt: KeyboardEvent) => {
    if (evt.key === "Enter") {
      setIsDamageFormVisible(true);
    }
  };

  return (
    <Section header="Skader" expandable={false}>
      <CardContainer padding="20px 20px">
        { noDamages 
            ? <DamageDescription>{noDamagesText}</DamageDescription>
            : damagesList.map((el) => (
                <DamagesContainer
                  key={el.key}
                  period={el.period}
                  title={el.title}
                  damages={el.damages}
                  editDamage={editDamage}
                />
              ))
        }
        { isDamageFormVisible
            ? <DamageForm action={DamageAction.Insert} onClose={hideDamageForm} />
            : canAddDamage &&
                <AddButton
                  tabIndex={0}
                  src={addButton}
                  alt="legg til ny skade"
                  onClick={showDamageForm}
                  onKeyDown={submitWithEnter}
                />
        }
      </CardContainer>
    </Section>
  );
};

export default Damages;

export const DamageDescription = styled.p`
  font-size: ${props => props.theme.fontSizes.md};
`;

export const AddButton = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${(props) => props.theme.margins.lg};
  cursor: pointer;
  height: 80px;
  width: 80px;
  
  @media screen and (max-width: 786px) {
    height: 40px;
    width: 40px;
  }
`;
