import React from "react";
import { Damage } from "../../typings";
import DamageElement from "./Damage";
import { DamagesPeriod } from "./Damages.types";
import styled from "styled-components";

interface Props {
  damages: Damage[];
  period: DamagesPeriod;
  editDamage: Function;
  title?: string;
}

const DamagesContainer = ({ damages, period, editDamage, title }: Props) => (
  <>
    {damages.length > 0 && (
      <Container>
        {title ? <Title>{title}</Title> : null}
        {damages.map((damage: Damage) => (
          <DamageElement
            key={damage.id}
            period={period}
            damage={damage}
            editDamage={editDamage}
          />
        ))}
      </Container>
    )}
  </>
);
export default DamagesContainer;

export const Title = styled.div`
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  margin-bottom: ${(props) => props.theme.margins.sm};
`;

export const Container = styled.div`
  width: 100%;
  margin-bottom: ${(props) => props.theme.margins.lg};
`;
