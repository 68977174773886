import React, { Fragment } from "react";
import styled from "styled-components";
import Section from "../View/Section";
import ValueLabel from "../View/ValueLabel";
import { CardContainer } from "../../styled-components";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.theme.fontSizes.sm};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  width: 95%;
`;
const Input = styled.input<{ validationError: boolean }>`
  padding: 5px;
  font-size: ${(props) => props.theme.fontSizes.lg};
  border: ${(props) =>
    props.validationError
      ? `1px solid ${props.theme.colors.signalRed}`
      : `0.5px solid ${props.theme.colors.black}`};
  margin-top: 5px;
  height: 40px;
  border-radius: 8px;
`;

const ValidationMessage = styled.div`
  color: ${(props) => props.theme.colors.signalRed};
  font-size: ${(props) => props.theme.fontSizes.sm};
`;

interface Props {
  setKmStand: (input: number) => void;
  kmStand: number;
  registeredKmStand: number;
  notValidKmStand: boolean;
}
function RegisterKmStand(props: Props) {
  const inputOnBlur = (evt: React.FocusEvent<HTMLInputElement>) => {
    const kmStand = parseInt(evt.target.value, 10);
    if (kmStand) {
      props.setKmStand(kmStand);
    }
  }
  return (
    <Section header="Register km. stand" expandable={false}>
      <CardContainer>
        <ValueLabel value={props.kmStand} label="Km. stand før leie" bolderValue />
        <InputContainer>
          <label htmlFor="Km. stand">Km. stand ved levering:</label>
          <Input
            validationError={props.notValidKmStand}
            onBlur={inputOnBlur}
            pattern="[0-9]*"
            type="text"
            inputMode="numeric"
            min={props.kmStand}
            id="Km. stand"
            name="Km. stand"
            defaultValue={props.registeredKmStand}
          />
        </InputContainer>
        {props.notValidKmStand && (
          <ValidationMessage>
            {props.registeredKmStand === 0
              ? "Km. stand må registreres før bilen kan leveres"
              : "Km. stand må være mer enn registrert km. stand ved leiestart"}
          </ValidationMessage>
        )}
      </CardContainer>
    </Section>
  );
}

export default RegisterKmStand;
