import car_outside_back from "../../assets/icons/car_outside_back.svg";
import car_outside_front from "../../assets/icons/car_outside_front.svg";
import car_outside_left from "../../assets/icons/car_outside_left.svg";
import car_outside_right from "../../assets/icons/car_outside_right.svg";
import car_inside_backseat from "../../assets/icons/car_inside_backseat.svg";
import car_inside_front from "../../assets/icons/car_inside_front.svg";
import car_inside_trunk from "../../assets/icons/car_inside_trunk.svg";
import car_inside_battery from "../../assets/icons/car_inside_battery.svg";

export const outsidePictures = [
    {
      name: "car_outside_front",
      description: "Bilder av bil på utsiden, front",
      placeholderImage: car_outside_front,
    },
    {
      name: "car_outside_back",
      description: "Bilder av bil på utsiden, bak",
      placeholderImage: car_outside_back,
    },
    {
      name: "car_outside_left",
      description: "Bilder av bil på utsiden, venstre side",
      placeholderImage: car_outside_left,
    },
    {
      name: "car_outside_right",
      description: "Bilder av bil på utsiden, høyre side",
      placeholderImage: car_outside_right,
    },
  ];
  
export const insidePictures = [
    {
      name: "car_inside_backseat",
      description: "Bilder av bil på innsiden, baksete",
      placeholderImage: car_inside_backseat,
    },
    {
      name: "car_inside_front",
      description: "Bilder av bil på innsiden, framsete",
      placeholderImage: car_inside_front,
    },
    {
      name: "car_inside_trunk",
      description: "Bilder av bil på innsiden, bagasjerom",
      placeholderImage: car_inside_trunk,
    },
    {
      name: "car_inside_battery",
      description: "Bilder av bilen innvendig, batteri",
      placeholderImage: car_inside_battery,
    },
  ];