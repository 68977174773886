import React, { ReactElement } from "react";
import Section from "../View/Section";
import styled from "styled-components";
import { Order } from "./../../typings";
import Resources from "./../../resources/Resources";
import { getWindowDimensions } from "./../../utils";
import ImageLoader from "./../Base/ImageLoader";
import { CardContainer } from "../../styled-components";
import { orderState } from "../../atoms";
import { useRecoilValue } from "recoil";

const getImages = (imageIds: string[] | undefined) => {
  let size = getWindowDimensions().width * 0.45;
  return (
    imageIds &&
    imageIds.map((id, index) => (
      <div key={id || index}>
        <ImageLoader
          src={Resources.file.src(id, size, size)}
          key={id || index}
          alt="Bilde av bil"
        />
      </div>
    ))
  );
};

const hasImages = (...imageIds: Array<Array<string> | undefined>) =>
  0 <
  imageIds
    .map((el) => el || [])
    .map((el) => el.length)
    .reduce((a, b) => a + b, 0);

interface ImageHolderProps {
  title: string;
  photos: ReactElement[] | undefined;
}

const ImageHolder = ({ title, photos }: ImageHolderProps) => {
  return photos && photos?.length > 0 ? (
    <>
      <ImageHolderTitle>{title}</ImageHolderTitle>

      <Images>{photos}</Images>
    </>
  ) : null;
};

interface Props {
  title?: string;
  order: Order;
}

function ShowCarPhotos({ title }: Props) {
  const order = useRecoilValue(orderState);

  const photosBefore = getImages(order.carRenterImagesBeforeRentalStart);
  const photosAfter = getImages(order.carRenterImagesWhenRentalIsFinished);
  const noImages = !hasImages(
    order.carRenterImagesBeforeRentalStart,
    order.carRenterImagesWhenRentalIsFinished
  );

  return (
    <Section header={title || ""} expandable={false}>
      <CardContainer>
        {noImages && <Description>Du har ingen bilder</Description>}
        <ImageHolder title={"Før leie"} photos={photosBefore} />
        <ImageHolder title={"Etter Leie"} photos={photosAfter} />
      </CardContainer>
    </Section>
  );
}

export default ShowCarPhotos;

const Description = styled.div`
  font-size: ${(props) => props.theme.fontSizes.md};
`;
const Images = styled.div`
  display: inline-grid;
  grid-template-columns: 50% 50%;
  gap: 24px;
  width: 95%;
  align-items: end;
`;

const ImageHolderTitle = styled.div`
  padding-top: 10px;
  padding-bottom: 5px;
`;
