import React from "react";
import styled from "styled-components";
import bulletPoint from "../../assets/icons/bullet_point.svg";

const LeievilkarList = styled.ul`
  font-size: ${(props) => props.theme.fontSizes.sm};
  font-weight: ${(props) => props.theme.fontWeights.lighter};
  padding: 0px;
  margin: 0px;
  margin-top: 0px;
  padding-left: 0px;
  list-style-image: url(${bulletPoint});
`;

const ListElement = styled.li`
  margin: ${(props) => props.theme.margins.md};
  padding-left: ${(props) => props.theme.margins.xs};
  text-align: left;
  &:first-child {
    margin-top: 0px;
  }
`;

function TermsAndConditionsSummary() {
  return (
    <LeievilkarList>
      <ListElement>
        Bilen kan bare kjøres i Norge i avtaleperioden. 
      </ListElement>
      <ListElement>
        Røyking er ikke tillatt i bilen. Hvis det røykes i bilen tilkommer det et ekstra gebyr på 4500,- eks mva for rens. 
      </ListElement>
      <ListElement>
        Bilen returneres inkludert medfølgende ladekable(r) og adapter. 
      </ListElement>
      <ListElement>
        Bilen må returneres med samme batterinivå som ved utlevering - ved batterinivå lavere enn ved utlevering, vil det påløpe et gebyr på kr. 399,- 
      </ListElement>
      <ListElement>
        Bilen må returneres i generell ren tilstand. Det kan påløpe rengjøringsgebyr på kr. 500 for manglende rengjøring. 
      </ListElement>
      <ListElement>
      Som leietaker er du pliktet til å betale bøter, gebyrer, parkering, o.l. som inntreffer under leieperioden. Etterfakturering av dette vil medføre et administrasjonsgebyr på kr 189,-. 
      </ListElement>
    </LeievilkarList>
  );
}

export default TermsAndConditionsSummary;
