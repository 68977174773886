import React from "react";
import RentalInformation from "../CustomerAndCarInformation/RentalInformation";
import CustomerInformation from "../CustomerAndCarInformation/CustomerInformation";
import { Order } from "../../typings";
import cancelIcon from "../../assets/icons/canceled.svg";
import styled from "styled-components";

interface Props {
  order: Order;
}
const Image = styled.img`
  width: 20px;
  height: 20px;
`;

const ErrorText = styled.div`
  font-size: ${(props) => props.theme.fontSizes.lg};
  font-weight: ${(props) => props.theme.fontWeights.superBold};
  color: ${(props) => props.theme.colors.white};
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  background: ${(props) => props.theme.colors.softRed};
  gap: 10px;
  margin: 30px 0px;
`;

function RentalCanceled(props: Props) {
  return (
    <>
      <ErrorContainer>
        <Image src={cancelIcon} alt="cancel icon" />
        <ErrorText>Kansellert</ErrorText>
      </ErrorContainer>
      <RentalInformation
        order={props.order}
        showLeiebiltypeDropdown={false}
        asExpandableContainer={false}
      />
      <CustomerInformation order={props.order} showForerkortInfo={false} />
    </>
  );
}

export default RentalCanceled;
