import React from "react";
import { BrowserRouter, Route, Routes as RouterRoutes, } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { RecoilRoot } from "recoil";
import Main from "./Main";
import { myTheme } from "../assets/theme";
import "focus-visible";
import styled from "styled-components";
import { useWindowSize } from "../utils";
import ConfirmMessage from "./ConfirmMessage";
import RentalFinished from "./StatusPages/RentalFinished";
import OrderPage from "./OrderPage";
import {Routes} from "../routes";

const FocusVisible = styled.div`
  &.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }
  &.js-focus-visible .focus-visible {
    outline: none;
    outline: 2px solid ${(props) => props.theme.colors.dustyPink};
  }
`;

function App() {
  const size = useWindowSize();
  return (
    <RecoilRoot>
      <ThemeProvider theme={myTheme(size.width)}>
        <FocusVisible className="js-focus-visible focus-visible">
          <BrowserRouter>
            <RouterRoutes>
              <Route path="/links/:link" element={<Main />} >
                <Route path="" element={<OrderPage />} />
                <Route path={Routes.Finished} element={<RentalFinished />} />
              </Route>
            </RouterRoutes>
          </BrowserRouter>
        </FocusVisible>
      </ThemeProvider>
    </RecoilRoot>
  );
}

export default App;
