import Modal from "react-modal";
import styled from "styled-components";

export const ModalContainer = styled(Modal)`
  background: white;
  border: none;
  height: fit-content;
  width: fit-content;
  margin: 0 auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;

  @media (max-width: 900px) {
    padding: 20px;
  }
`;

export const Header = styled.h1`
  font-family: "spectral-regular";
  font-size: 36px;
  color: #080908;
  margin: 0;

  @media (max-width: 900px) {
    font-size: 24px;
  }
`;

export const Subtitle = styled.h2`
  font-family: "spectral-regular";
  font-size: 18px;
  color: #080908;
  margin: 0;
  margin-top: 19px;
  margin-bottom: 29px;

  @media (max-width: 900px) {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 15px;
  }  
`;

export const StarWrapper = styled.div`
  cursor: pointer;
`;

export const StarsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
`;

export const MessageInput = styled.textarea`
  resize: none;
  width: 280px;
  height: 83px;
  margin-top: 28px;
  border: 2px solid rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 2px;
  font-family: "spectral-regular";

  @media (max-width: 900px) {
    width: 250px;
    margin-top: 20px;
  }  
`;

export const SendButton = styled.button`
  border: none;
  background-color: #417630;
  color: #ffffff;
  font-size: 24px;
  line-height: 28px;
  width: 304px;
  height: 51px;
  border-radius: 8px;
  margin-top: 27px;
  cursor: pointer;

  &:disabled {
    background-color: #C4C4C4;
    cursor: default;
  }

  @media (max-width: 900px) {
    width: 274px;
    height: 41px;
    font-size: 16px;
  } 
`;
