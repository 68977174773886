import React, { useState, ChangeEvent } from "react";
import deleteCross from "../../assets/icons/delete_cross.svg";
import trashIcon from "../../assets/icons/trash_icon.svg";
import Resources from "./../../resources/Resources";

import {
  InputLabel,
  Input,
  LabelTextContainer,
  GridContainer,
  Image,
  ImageWrap,
  DeleteCross,
  Title,
} from "./styled/MultiplePhotos.styled";

interface MultiplePhotosProps {
  imageIds: string[];
  setImageIds: React.Dispatch<React.SetStateAction<string[]>>;
}

function MultiplePhotos(props: MultiplePhotosProps) {
  const [deleteImageClicked, setDeleteImageClicked] = useState(-1);

  const addImage = (data: any) =>
    props.setImageIds((prev) => [...prev, data.id]);

  const onError = (error: Error) => console.error(error);

  const deleteImage = (key: number, fileId: string) => () => {
    if (deleteImageClicked === key) {
      props.setImageIds(props.imageIds.filter((id) => id !== fileId));
      setDeleteImageClicked(-1);
    } else {
      setDeleteImageClicked(key);
    }
  };

  const onFileChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    const files = evt.target.files;
    if (!files) {
      return;
    }
    for (var i = 0; i < files.length; i++) {
      const file = files.item(i);
      if (!file) {
        return;
      }
      Resources.file.upload
        .picture(file)
        .then(Resources.response.json)
        .then(addImage, onError);
    }
  };

  return (
    <div>
      <Title>Bilder:</Title>
      {props.imageIds.length > 0 && (
        <GridContainer>
          {props.imageIds.map((fileId, key) => (
            <ImageWrap key={key}>
              <DeleteCross
                onClick={deleteImage(key, fileId)}
                src={deleteImageClicked === key ? trashIcon : deleteCross}
                alt="fjern bilde"
              />
              <Image
                src={Resources.file.src(fileId, 200, 200)}
                alt="Bilde av skade"
              />
            </ImageWrap>
          ))}
        </GridContainer>
      )}
      <InputLabel>
        <Input
          type="file"
          multiple
          accept=".jpeg, .jpg, .png, .gif, .bmp"
          onChange={onFileChange}
        />
        <LabelTextContainer>
          Klikk for å laste opp- eller ta bilder av skaden
        </LabelTextContainer>
      </InputLabel>
    </div>
  );
}

export default MultiplePhotos;
