import React from "react";
import ReactDOM from 'react-dom/client'
import App from "./components/App";
import "./assets/scss/index.scss";
import * as serviceWorker from "./serviceWorker";
import { IntlProvider } from 'react-intl';
import Danish from "./intl18/da.json"
import Swedish from "./intl18/sv.json"
import English from "./intl18/en.json"
import Norwegian from "./intl18/nb.json"

const locale = navigator.language;

const translations = {
  en: English,
  da: Danish,
  sv: Swedish,
  nb: Norwegian,
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <IntlProvider locale={locale} messages={{}}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </IntlProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
