import React from "react";
import Section from "../View/Section";
import ValueLabel from "../View/ValueLabel";
import { Car } from "../../typings";
import { CardContainer } from "../../styled-components";
import { CarImage, CarName, CarPlate, LeftIndicator, Row2, RowContainer, SubText } from "../../styled-components/YourRentalCar";

interface Props {
  car: Car;
  mileageStart: number | undefined;
  reservationNumber?: string | undefined;
}
function YourRentalCar({ car, mileageStart, reservationNumber }: Props) {
  const carImage = car?.carModelImagesUrls?.length
    ? car?.carModelImagesUrls[0]
    : "";
  return (
    <Section header="Din leiebil" expandable={false}>
      <CarImage src={carImage} alt={"car"} />
      <CardContainer>
        <RowContainer>
        <CarPlate><LeftIndicator/>{car.plateNumber}</CarPlate>
        <div>
        <CarName>{`${car?.producer} ${car?.model}`}</CarName>
        <SubText>eller lignende</SubText>
        </div>
        </RowContainer>

        <Row2>
          {mileageStart && (
            <ValueLabel
            value={`${mileageStart} km`}
            label="Km. stand"
            bolderValue={true}
            />
            )}
          <ValueLabel
            value={car.color}
            label="Farge"
            bolderValue={true}
            />
          <ValueLabel value={reservationNumber} label="Reservasjonsnr." bolderValue={true} />
        </Row2>

      </CardContainer>
    </Section>
  );
}

export default YourRentalCar;
