import { atom, selector } from "recoil";
import {
  Order,
  CarType,
  Damage,
  Car,
  DamageType,
  Driver,
  CarTypeExtra,
  InsurancePolicyType,
  ExtraCosts
} from "./typings";

export const orderState = atom<Order>({
  key: "order",
  default: <Order>{},
});

export const damageState = atom<Damage[]>({
  key: "damages",
  default: [],
});

export const carTypeState = atom<CarType[]>({
  key: "carType",
  default: [],
});
export const carState = atom<Car>({
  key: "car",
  default: {},
});

export const extraCostState = atom<ExtraCosts>({
  key: "extraCostState",
  default: {},
});

export const extraCarType = atom<CarTypeExtra | null>({
  key: "carTypeExtra",
  default: null,
});

export const insurancePrice = atom<InsurancePolicyType>({
  key: "insurancePrice",
  default: {
    type: "12.000",
    pricePerDay: 0,
    total: 0
  },
});

export const damageTypesState = atom<DamageType[]>({
  key: "damageTypes",
  default: [],
});

export const acceptedTermsAndConditions = atom<boolean>({
  key: "acceptedTermsAndConditions",
  default: false,
});

export const timerFinished = atom<boolean>({
  key: "timerFinished",
  default: false,
});

export const creditCardAdded = atom<boolean>({
  key: "creditCardAdded",
  default: false,
});

export const deliverCarBackClicked = atom<boolean>({
  key: "deliverCarBackClicked",
  default: false,
});

export const contactUsModalOpened = atom<boolean>({
  key: "contactUsModalOpened",
  default: false,
});

export const rentalTermsModalOpened = atom<boolean>({
  key: "rentalTermsModalOpened",
  default: false,
});

export const damageImageIds = atom<string[]>({
  key: "damageImageIds",
  default: [],
});

export const addedDrivingLicenseImages = selector<boolean>({
  key: "addedDrivingLicenseImages",
  get: ({ get }) => {
    const order = get(orderState);
    return (
      !!order.order_customer?.driving_license_photo &&
      !!order.order_customer?.driving_license_photo[0] &&
      !!order.order_customer?.driving_license_photo[1]
    );
  },
});

export const showAdditionalDriver = atom<boolean>({
  key: "showAdditionalDriver",
  default: false,
});

export const clickedDisabledBekreft = atom<boolean>({
  key: "clickedDisabledBekreft",
  default: false,
});

export const additionalDriver = selector<Driver | undefined>({
  key: "additionalDriver",
  get: ({ get }) => {
    const order = get(orderState);
    const driver =
      order.order_customer &&
      order.order_customer.additional_driver &&
      order.order_customer.additional_driver[0];

    return driver || undefined;
  },
});

export const additionalDriverDrivingLicenseFront = selector<string | undefined>(
  {
    key: "additionalDriverDrivingLicenseFront",
    get: ({ get }) => {
      const driver = get(additionalDriver);
      return (
        driver &&
        driver.driving_license_photo &&
        driver.driving_license_photo[0]
      );
    },
  }
);

export const additionalDriverDrivingLicenseBack = selector<string | undefined>({
  key: "additionalDriverDrivingLicenseBack",
  get: ({ get }) => {
    const driver = get(additionalDriver);
    return (
      driver && driver.driving_license_photo && driver.driving_license_photo[1]
    );
  },
});

export const phoneNumberAdditionalDriver = selector<string | undefined>({
  key: "phoneNumberAdditionalDriver",
  get: ({ get }) => {
    const driver = get(additionalDriver);
    return driver && driver.phones && driver.phones[0];
  },
});

export const validAdditionalDriver = selector<boolean>({
  key: "validAdditionalDriver",
  get: ({ get }) => {
    const front = get(additionalDriverDrivingLicenseFront);
    const back = get(additionalDriverDrivingLicenseBack);
    const phone = get(phoneNumberAdditionalDriver);
    return !!front && !!back && !!phone && phone?.length > 0;
  },
});

export const currentCarType = selector<CarType>({
  key: "currentCarType",
  get: ({ get }) => {
    const order = get(orderState);
    const currentCarTypeId = order.carType_selected
      ? order.carType_selected.id
      : order.carType_initial?.id;
    const carTypes = get(carTypeState);
    const currentCarType = carTypes.find((ct) => ct.id === currentCarTypeId);
    return currentCarType as CarType;
  },
});

export const initialCarType = selector<CarType>({
  key: "initialCarType",
  get: ({ get }) => {
    const order = get(orderState);
    const carTypes = get(carTypeState);
    const currentCarType = carTypes.find(
      (ct) => ct.id === order.carType_initial?.id
    );
    return currentCarType as CarType;
  },
});

export const carTypesWithSimilarOrHigherPrice = selector<CarType[]>({
  key: "carTypesWithSimilarOrHigherPrice",
  get: ({ get }) => {
    const initCarType = get(initialCarType);
    const selectCarType = get(currentCarType);
    const carTypes = get(carTypeState);
    const carTypesWithSimilarOrHigherPrice =
      (initCarType &&
        carTypes.filter(
          (ct) => ct.price >= initCarType.price && ct.id !== selectCarType.id
        )) ||
      [];

    carTypesWithSimilarOrHigherPrice.sort((a, b) =>
      a.price > b.price ? 1 : -1
    );
    return carTypesWithSimilarOrHigherPrice;
  },
});

export const damagesBefore = selector<Damage[]>({
  key: "damagesBefore",
  get: ({ get }) => {
    const damages = get(damageState);
    const startOrderTime = get(startOrderDate);

    if (!startOrderTime) {
      return [];
    }

    return damages.filter((d) => {
      return startOrderTime > new Date(d.reported_on);
    });
  },
});

export const damagesInitial = selector<Damage[]>({
  key: "damagesInitial",
  get: ({ get }) => {
    const damages = get(damageState);
    const startOrderTime = get(startOrderDate);
    const closedOrderDate = get(orderClosedDate);

    if (!startOrderTime || !closedOrderDate) {
      return [];
    }
    return damages.filter((d) => {
      const reportDate = new Date(d.reported_on);
      return reportDate > startOrderTime && reportDate < closedOrderDate;
    });
  },
});

export const damagesDuring = selector<Damage[]>({
  key: "damagesDuring",
  get: ({ get }) => {
    const damages = get(damageState);
    const closedOrderDate = get(orderClosedDate);

    if (!closedOrderDate) {
      return [];
    }

    return damages.filter((d) => {
      return new Date(d.reported_on) > closedOrderDate;
    });
  },
});

export const startOrderDate = selector<Date | undefined>({
  key: "startOrderDate",
  get: ({ get }) => {
    const order = get(orderState);
    if (!order.timeStamp_start_ordered) {
      return undefined;
    }
    return new Date(order.timeStamp_start_ordered);
  },
});

export const orderClosedDate = selector<Date | undefined>({
  key: "orderClosedDate",
  get: ({ get }) => {
    let rentalStartDate = get(startOrderDate);
    if (!rentalStartDate) {
      return undefined;
    }
    rentalStartDate = new Date(rentalStartDate.getTime());
    rentalStartDate.setMinutes(rentalStartDate.getMinutes() + 15);
    return rentalStartDate;
  },
});
