import { Damage, Order } from './../../typings'

export interface DamagesProps {
    order?: Order
    noDamagesText: string;
}

export enum DamagesPeriod {
    Before,
    Initial,
    During,
}

export type DamageListElement = {
    key: string
    period: DamagesPeriod
    damages: Damage[]
    title: string
}

export type DamagesList = DamageListElement[]
