import React from "react";

const Star = (props: { selected: boolean }) => {
  return (
    <svg
      width="52"
      height="45"
      viewBox="0 0 52 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 0L32.0619 16.9291L51.6785 16.9291L35.8083 27.3918L41.8702 44.3209L26 33.8582L10.1298 44.3209L16.1917 27.3918L0.321474 16.9291L19.9381 16.9291L26 0Z"
        fill={props.selected ? "#417630" : "#C4C4C4"}
      />
    </svg>
  );
};

export default Star;
