import React, { useEffect, Fragment } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import cross from "../../assets/icons/cross.svg";

const ModalContainer = styled(Modal)<{
  header: boolean;
  maxWidth: string | undefined;
}>`
  background: ${(props) => props.theme.colors.lightGreen};
  border: none;
  height: fit-content;
  margin: 0 auto;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "85%")};
  max-height: 85%;
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  border: 1px solid #ccc;
  overflow: auto;
  webkitoverflowscrolling: touch;
  borderradius: 4px;
  outline: none;
  padding: 20px;
  padding-top: ${(props) => (props.header ? "0px" : "20px")};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  padding: 20px;
  padding-right: 0px;
  cursor: pointer;
  background: ${(props) => props.theme.colors.lightGreen};
`;

interface Props {
  children: JSX.Element;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  header: boolean;
  headerTitle?: string;
  maxWidth?: string;
}
function CommonModal(props: Props) {
  useEffect(() => {
    Modal.setAppElement("body");
  }, []);
  return (
    <Fragment>
      <ModalContainer
        maxWidth={props.maxWidth}
        header={props.header}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            zIndex: 200,
          },
        }}
        className="Modal"
        isOpen={props.isOpen}
        onRequestClose={() => props.setIsOpen(false)}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick={false}
        appElement={document.getElementById('root') || undefined}
      >
        {props.header ? (
          <Header onClick={() => props.setIsOpen(false)}>
            <div className="text-18-400">{props.headerTitle}</div>
            <img
              src={cross}
              alt="Lukk dialog"
              onClick={() => props.setIsOpen(false)}
              tabIndex={0}
              onKeyDown={(evt: React.KeyboardEvent) => {
                if (evt.key === "Enter") {
                  props.setIsOpen(false);
                }
              }}
            />
          </Header>
        ) : null}

        {props.children}
      </ModalContainer>
    </Fragment>
  );
}

export default CommonModal;
