import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { Damage, DamagePositionTexts, DamageType, Order, UserRole } from "../../typings";
import { orderState, damageTypesState } from "../../atoms";
import Resources from "./../../resources/Resources";
import { DamagesPeriod } from "./Damages.types";
import DamageForm from "./DamageForm/DamageForm";
import { DamageAction } from "./DamageForm/DamageForm.types";
import { isInInitialTime } from "./Damage.utils";
import { isOrderCanceled } from "./../Order/utils";
import styled from "styled-components";
import ImageLoader from "./../Base/ImageLoader";

interface DamageDetailsProps {
  damage: Damage;
  period: DamagesPeriod;
  editDamage: Function;
}

const periodIsType = (type: DamagesPeriod) => (period: DamagesPeriod) =>
  period === type;
const isBefore = periodIsType(DamagesPeriod.Before);
const isInitial = periodIsType(DamagesPeriod.Initial);
const isDuring = periodIsType(DamagesPeriod.During);

const isInitialInTime = (period: DamagesPeriod, order: Order) =>
  isInitial(period) && isInInitialTime(order.timeStamp_start_ordered as string);

const checkIfEditable = (period: DamagesPeriod, order: Order) =>
  !isOrderCanceled(order) &&
  !isBefore(period) &&
  (isInitialInTime(period, order) || isDuring(period));

function DamageDetails({ damage, period, editDamage }: DamageDetailsProps) {
  const [isEditOpen, setIisEditOpen] = useState(false);
  const order = useRecoilValue(orderState);
  const damageTypes: DamageType[] = useRecoilValue(damageTypesState);
  const damageType: DamageType | undefined = damageTypes.find(
    (dt) => dt.id === damage?.damageType?.id
  );
  const isEditable: boolean = checkIfEditable(period, order);
  const isRenterDamage = damage.reported_by === UserRole.RENTER;

  const closeEditForm = () => setIisEditOpen(false);
  const edit = () => {
    setIisEditOpen(!isEditOpen);
    editDamage(damage);
  };

  const renderImages = (images: string[]) => {
   return images?.map(img => (
            <ImageLoader
              key={img}
              src={Resources.file.src(img, 160, 160)}
              alt="Bilde av skade"
              imageStyle={{width: 200, objectFit: 'contain'}}
            />
   ));
  }

  const showDetails = (label: string, data: string | undefined) => {
    if(data){
      return (
        <DamageContentContainer>
          <Label>{label}</Label>
          <Description>{data}</Description>
        </DamageContentContainer>
      )
    }
  }

  return (
    <WrapperContainer>
      <DamageContainer>
        <Content>
          <TextHolder>
            {(isEditable && isRenterDamage) && <Edit onClick={edit}>Rediger</Edit>}
            {showDetails("Skadetype", damageType?.description)}
            {showDetails("Skadested", DamagePositionTexts[damage.position])}
            {showDetails("Beskrivelse", damage.description)}
          </TextHolder>
          <ImageHolder>
           {renderImages(damage.imagesIds)}
          </ImageHolder>
        </Content>
        {isEditOpen && (
          <FormHolder>
            <DamageForm
              action={DamageAction.Update}
              editedDamage={damage}
              onClose={closeEditForm}
            />
          </FormHolder>
        )}
      </DamageContainer>
    </WrapperContainer>
  );
}

export default DamageDetails;

const WrapperContainer = styled.div`
  margin-top: ${(props) => props.theme.margins.sm};
  background-color: ${(props) => props.theme.colors.lightGreen};
  width: 100%;
`;

const Content = styled.div`
  gap: ${(props) => props.theme.margins.sm};
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  `;
  
  const TextHolder = styled.div`
  width: 100%;
  align-self: flex-start;
`;

const DamageContentContainer = styled.div`
  margin-bottom: ${(props) => props.theme.margins.md};
`;

const ImageHolder = styled.div`
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  top: 0;
  right: 0;
  justify-content: center;
`;

const Description = styled.div`
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.normal};
`;

const DamageContainer = styled.div`
  margin-top: ${(props) => props.theme.margins.sm};
  margin-bottom: ${(props) => props.theme.margins.xs};
  padding: 0px 15px;
`;

const Edit = styled.div`
  text-align: right;
  bottom: 0px;
  margin-bottom: ${(props) => props.theme.margins.sm};
  text-decoration: underline;
  cursor: pointer;
`;

const FormHolder = styled.div`
  background-color: #fff;
  padding: 5px;
  margin-top: 5px;
  border-radius: 2px;
`;

const Label = styled.div`
font-size: ${(props) => props.theme.fontSizes.lg};
font-weight: ${(props) => props.theme.fontWeights.superBold};
margin-bottom: ${(props) => props.theme.margins.xs};
`;
