import React from "react";
import styled from "styled-components";
import checkbox_icon from "../../assets/icons/checkbox_icon.svg";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  clickedDisabledBekreft,
  acceptedTermsAndConditions,
} from "../../atoms";

function TermsAndConditionsCheckbox() {
  const [isTermsAccepted, setTermsAccepted] = useRecoilState(
    acceptedTermsAndConditions
  );
  const isDisabledConfirmClicked = useRecoilValue(clickedDisabledBekreft);
  const isInvalid = isDisabledConfirmClicked && !isTermsAccepted;
  const checkTermsBox = () => setTermsAccepted(!isTermsAccepted);
  const submitWithEnter = (evt: React.KeyboardEvent) => {
    if (evt.key === "Enter") {
      setTermsAccepted(!isTermsAccepted);
    }
  };

  return (
    <CheckboxContainer>
      <Container validationError={isInvalid}>
        <Label>
          <CheckBoxHolder>
            <CheckBox
              type="checkbox"
              value="Bike"
              checked={isTermsAccepted}
              onChange={checkTermsBox}
              onKeyDown={submitWithEnter}
            />
            <Box>
              {isTermsAccepted && (
                <Checkmark
                  src={checkbox_icon}
                  alt="Checkbox"
                  className="checkbox"
                />
              )}
            </Box>
          </CheckBoxHolder>
          <div>
            <CheckLabelSpan>Jeg godkjenner</CheckLabelSpan>
            <CheckLabelSpan>
              <TermsAndConditionsModal
                linktitle={"leievilkår og personvern"}
                showSummaryVilkar={true}
              />
            </CheckLabelSpan>
          </div>
        </Label>
      </Container>
      {isInvalid && (
        <WarningText>Leievilkår og personvern må godkjennes</WarningText>
      )}
    </CheckboxContainer>
  );
}

export default TermsAndConditionsCheckbox;

const WarningText = styled.div`
  color: #b20a0a;
  margin-top: 4px;
`;

const CheckBoxHolder = styled.div`
  position: relative;
  width: 25px;
  height: 25px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const CheckBox = styled.input`
  width: 20px;
  padding: 0;
  margin-left: 3px;
  height: 20px;
  grid-area: first;
`;

const Box = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  background: white;
  padding: 4px 0px 0px 5px;
  width: 19px;
  height: 20px;
  border: 1px solid #000000;
  cursor: pointer;
`;

const Checkmark = styled.img`
  margin: auto;
  margin-top: -2px;
`;

const CheckLabelSpan = styled.span`
  margin-right: 3px;
  font-size: ${(props) => props.theme.fontSizes.md}
`;

const Label = styled.label`
  display: grid;
  grid-template-columns: 24px auto;
  grid-template-areas: "first second";
  column-gap: 16px;
  align-items: center;
`;

const Container = styled.div<{ validationError: boolean }>`
  display: flex;
  align-items: flex-end;
  border: ${(props) =>
    props.validationError
      ? `1px solid ${props.theme.colors.signalRed}`
      : "none"};
  padding-bottom: 8px;
  padding-top: 8px;
  @media (min-width: 370px) {
    padding-left: 16px;
  }
`;

const CheckboxContainer = styled.div`
  margin-top: 41px;
  margin-bottom: 35px;
  @media (max-width: 370px) {
    display: flex;
    justify-content: center;
  }
`;
