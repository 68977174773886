import React from "react";
import styled from "styled-components";

interface PaymentDataFieldProps {
    fieldTitle: string;
    errorText: string;
    children?: JSX.Element;
    isError: boolean;
    styles?: React.CSSProperties
}
function PaymentDataField(props: PaymentDataFieldProps) {
    return <div
        style={{
            ...props.styles
        }}>
        <label>{props.fieldTitle}</label>
        <div style={{
            marginTop: '6px',
            borderRadius: '2px',
            border: '0.5px solid #7f9898c7',
            backgroundColor: '#F6FCFC',
            padding: '5px',
        }}>
            {props.children}
        </div>
        <ErrorLabel>{props.isError ? props.errorText : ''}</ErrorLabel>
    </div>

}

const ErrorLabel = styled.span`
    color: ${props => props.theme.colors.disableRed}
`

export default PaymentDataField;