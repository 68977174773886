import React from "react";
import styled from "styled-components";

const PolicyDescription = () => {
  return (
    <Container>
      <Heading>Forsikringen dekker</Heading>
      <List>
        <Item>
          Ansvar. Personskade på fører og passasjer, skade på personer utenfor
          kjøretøyet, skade påført bygninger og andre gjenstander, og skade
          påført andre kjøretøy
        </Item>
        <Item>Brann</Item>
        <Item>
          Tyveri. Tyveri av selve kjøretøyet og tyveri av fastmontert utstyr fra
          kjøretøyet eller skade som kjøretøyet blir påført i forbindelse med
          forsøk på tyveri av kjøretøyet
        </Item>
        <Item>
          Kasko. Skade på eget kjøretøy ved utforkjøring, velt, kollisjon eller
          hærverk, samt andre skader som skyldes en tilfeldig, plutselig og ytre
          hendelse
        </Item>
        <Item>
          Glass. Glassruteskader – knusing og sprekker – samt skade på glasstak
        </Item>
        <Item>
          Veihjelp. Veihjelp i Europa ved driftsstopp, utelåsing, sykdom eller
          ulykke
        </Item>
        <Item>
          Transport etter skade • Leiebil. Ubegrenset leiebil så lenge bilen
          står på verksted
        </Item>
        <Item>
          Underslag hvis leietaker ikke skulle komme tilbake med bilen etter
          endt leieperiode, og den må anses som tapt
        </Item>
      </List>

      <Heading>Forsikringen ikke dekker </Heading>

      <List>
        <Item>Motorskade</Item>
        <Item>Superdekning</Item>
      </List>
    </Container>
  );
};

const List = styled.ul`
  padding-inline-start: 10px;
`;

const Item = styled.li`
  font-size: ${(props) => props.theme.fontSizes.xs};
`;

const Heading = styled.label`
  font-size: ${(props) => props.theme.fontSizes.xs};
  font-weight: ${(props) => props.theme.fontWeights.superBold};
`;
const Container = styled.div`
  padding: 4%;

  // @media screen and (max-width: 786px) {
  //   padding: 10px;
  // }
`;
const Input = styled.input`
  margin: 0px;
`;

export default PolicyDescription;
