export interface Order {
  insuranceCompany?: IdName;
  workshopCompany?: IdName;
  rentalCompany?: Company;
  order_customer?: OrderCustomer;
  damage_claim_number?: string;
  carType_initial?: CarTypeInitial;
  carType_selected?: IdName;
  timeStamp_start_planned?: string;
  timeStamp_end_planned?: string;
  timeStamp_start_ordered?: string | Date;
  timeStamp_end_ordered?: string | Date;
  order_state?: OrderState;
  pickup_location?: SelectedEnteredValue;
  dropoff_location?: SelectedEnteredValue;
  insuranceCoverageType?: string;
  order_number?: string;
  orderExtraCharges?: any;
  orderExtraCosts?: any;
  orderIncludedExtras?: any;
  mileage_start?: number;
  mileage_end?: number;
  overviewImages?: string[];
  carRenterImagesBeforeRentalStart?: string[];
  carRenterImagesWhenRentalIsFinished?: string[];
  paymentClientSecret?: string;
  id?: string;
  locations: Locations,
  imagesFinished?: any;
}

interface Locations {
  dropoff: DropOff;
  pickup: Pickup;
}
interface DropOff {
  address: string;
  city: string;
  companyName: string;
  phones: string[];
  latitude?: number;
  longitude?: number;
}
interface Pickup {
  address: string;
  city: string;
  companyName: string;
  phones: string[];
  latitude?: number;
  longitude?: number;
}

interface SelectedEnteredValue {
  selected_id: string;
  selected_name: string;
  entered_value: string;
  properties: any[];
}

export const UserRole = Object.freeze({
  RENTER: "Leietager"
})

export enum OrderState {
  PendingConfirmation,
  Confirmed,
  PendingDelivery,
  PendingCustomerPickup,
  RentalInProgress,
  CarReturned,
  CarChecked,
  Closed,
  Canceled,
}
export const OrderStatusMap = new Map<OrderState, string>([
  [OrderState.PendingConfirmation, "Venter på bekreftelse"],
  [OrderState.Confirmed, "Klar for henting"],
  [OrderState.PendingDelivery, "Klar for henting"],
  [OrderState.PendingCustomerPickup, "er klar for henting"],
  [OrderState.RentalInProgress, "Under leie"],
  [OrderState.CarReturned, "Returnert"],
  [OrderState.Closed, "Returnert"],
  [OrderState.CarChecked, "Returnert"],
  [OrderState.Canceled, "Kansellert"],
]);

export interface IdName {
  id: string;
  name: string;
}
export interface CarTypeInitial {
  id: string;
  name: string;
  price?: number;
}

export interface Company {
  name: string;
  type: CompanyType;
  organizationNumber: string;
  phones: string[];
  email: string;
  address: string;
  zipCode: string;
  city: string;
  dbsNumber: string;
  vismaCustomerId: string;
}

export interface OrderCustomer {
  customer_type: CustomerType;
  customer: Customer;
  company: Company;
  subscriptionId: number;
  car_license_plate: string;
  insurance_coverage_type: string;
  driving_license_photo: string[];
  driving_license_number: string;
  additional_driver: Driver[] | null;
}

export interface Driver {
  driving_license_photo: string[];
  phones: string[];
}
export interface Customer {
  name: string;
  surname: string;
  phones: string[];
  email: string;
  address: string;
  zipcode: string;
  city: string;
}
export enum CustomerType {
  Company,
  Customer,
}
enum CompanyType {
  Insurance,
  Workshop,
  Greenpool,
  Rental,
}

export interface Code {
  code: string;
  id: string;
  modelType: string;
}

export interface CarType {
  name: string;
  order: number;
  insurance: IdName;
  rental: IdName;
  codes: Code[];
  price: number;
  minimum_liability: number;
  id: string;
  modelType: string;
}

export interface Damage {
  carId: string;
  damageType: BaseDamageType;
  description: string;
  imagesIds: string[];
  reported_on: Date;
  reported_by: string;
  incidentId?: string;
  id?: string;
  position: string;
}

export interface UpdateDamage {
  data: {
    damageTypeId: string;
    description: string;
    imagesIds: string[];
    position: string;
  },
  orderId: string;
  damageId: string;
}

export interface BaseDamageType {
  name: string;
  id: string;
}

export interface DamageType {
  name: string;
  description: string;
  id: string;
}

export interface Car {
  carModelImagesUrls?: string[];
  id?: string;
  code?: string;
  producer?: string;
  model?: string;
  plateNumber?: string;
  color?: string;
  year?: number;
  state?: any;
  vismaProductId?: string;
}

export interface CarTypeExtra {
  carType: IdName;
  extraAccessories: BaseExtraCarType[];
  extraInsurances: BaseExtraCarType[];
  extraInsurancesPackages: BaseExtraCarType[];
}

export interface BaseExtraCarType {
  id: string;
  name: string;
  description: string;
  vat: boolean;
  includeType: IncludeType;
  global: ExtraGlobal;
  options: any;
}

export enum IncludeType {
  IncludedByDefault,
  IncludedAsExtra,
}

export enum ExtraGlobal {
  Global,
  Custom,
}

export enum LinkType {
  PaymentConfirmation = 3,
}

export interface OrderLink {
  link_type: LinkType;
  order_id: string;
}

export interface CreateFeedbackI {
  rating: number;
  message?: string;
  source: string;
  sourceVersion: string;
  additionalData?: {}
}

export enum DamagePosition {
  LeftFrontWindow = 1,
  LeftFrontDoor = 2,
  LeftRearDoor = 3,
  LeftRearWindow = 4,
  RearBumper = 5,
  RightFrontWindow = 6,
  RightFrontDoor = 7,
  RightRearDoor = 8,
  RightRearWindow = 9,
  FrontBumper = 10,
  Roof = 11,
  Windshield = 12,
  RearWindow = 13,
  Rims = 14,
  Interior = 15,
  Other = 0,
}

export const DamagePositionTexts: Record<string, string> = {};
DamagePositionTexts[DamagePosition[DamagePosition.Other]] = "Annet";
DamagePositionTexts[DamagePosition[DamagePosition.LeftFrontWindow]] = "Venstre skjerm foran";
DamagePositionTexts[DamagePosition[DamagePosition.LeftFrontDoor]] = "Venstre dør foran";
DamagePositionTexts[DamagePosition[DamagePosition.LeftRearDoor]] = "Venstre dør bak";
DamagePositionTexts[DamagePosition[DamagePosition.LeftRearWindow]] = "Venstre skjerm bak";
DamagePositionTexts[DamagePosition[DamagePosition.RearBumper]] = "Bakfanger";
DamagePositionTexts[DamagePosition[DamagePosition.RightFrontWindow]] = "Høyre skjerm foran";
DamagePositionTexts[DamagePosition[DamagePosition.RightFrontDoor]] = "Høyre dør foran";
DamagePositionTexts[DamagePosition[DamagePosition.RightRearDoor]] = "Høyre dør bak";
DamagePositionTexts[DamagePosition[DamagePosition.RightRearWindow]] = "Høyre skjerm bak";
DamagePositionTexts[DamagePosition[DamagePosition.FrontBumper]] = "Frontfanger";
DamagePositionTexts[DamagePosition[DamagePosition.Roof]] = "Tak";
DamagePositionTexts[DamagePosition[DamagePosition.Windshield]] = "Frontrute";
DamagePositionTexts[DamagePosition[DamagePosition.RearWindow]] = "Bakrute";
DamagePositionTexts[DamagePosition[DamagePosition.Rims]] = "Felger";
DamagePositionTexts[DamagePosition[DamagePosition.Interior]] = "Interiør";

export const DamagePositionKeys = Object.keys(DamagePosition).filter(key => isNaN(parseInt(key)));

export interface InsurancePolicyType {
  type?: string;
  pricePerDay?: number;
  total?: number;
}
export interface ExtraCost {
  name?: string;
  total?: number;
  vatRate?: number;
  id?: string;
  pricePerDay?: number;
}
export interface ExtraCosts {
  id?: string;
	insuranceCompanyName?: string;
	customer?: {
		name?: string
	},
	state?: number, 
  extraCosts?: ExtraCost[];
}
