import { DefaultTheme } from "styled-components";

const myTheme: (screenWidth: number) => DefaultTheme = (
  screenWidth: number
) => ({
  borderRadius: "5px",

  colors: {
    black: "#0E2F04",
    darkGrey: "#FFFFFF",
    backgroundGrey: "#F6FCFC",
    darkGreen: "#536357",
    darkOrange: "#E17A40",
    statusGreen: "#FAF0C8",
    mainGreen: "#417630",
    mediumGreen: "#728A78",
    signalRed: "#BB4837",
    dustyPink: "#BB4837",
    disableRed: "#BB4837",
    disableGreen: "#7A8A75",
    softLightTeal: "#F8E7A0",
    azureBackground: "#D8E9F5",
    white: "#FFFFFF",
    lightGreen: "#ECEFE8",
    softRed: "#F09494",
    lightGrey: "#D9D9D9",
    cyan: "#3A8EAA"
  },
  fonts: {
    default: "worksans-regular, sans-serif",
  },
  fontSizes:
    screenWidth <= 300
      ? {
          xxs: "5px",
          xs: "8px",
          sm: "9px",
          md: "10px",
          lg: "12px",
          xl: "14px",
          xxl: "16px",
        }
      : screenWidth <= 500
      ? {
          xxs: "6px",
          xs: "9px",
          sm: "10px",
          md: "12px",
          lg: "14px",
          xl: "16px",
          xxl: "18px",
        }
      : {
          xxs: "9px",
          xs: "12px",
          sm: "14px",
          md: "16px",
          lg: "18px",
          xl: "20px",
          xxl: "41px",
        },
  fontWeights: {
    superlight: "100",
    lighter: "300",
    normal: "400",
    bolder: "600",
    superBold: "800",
  },
  margins: {
    xs: "4px",
    sm: "8px",
    md: "16px",
    lg: "24px",
    xl: "32px",
  },
});

export { myTheme };
