import React, { Fragment } from "react";
import styled from "styled-components";
import { useRecoilState, useRecoilValue } from "recoil";

import { OrderCustomer, OrderState } from "../../typings";
import Section from "../View/Section";
import ButtonLink from "../View/ButtonLink";
import RegisterExtraDriver from "./RegisterExtraDriver";
import AddDrivingLicense from "./AddDrivingLicense";
import {
  orderState,
  showAdditionalDriver,
  addedDrivingLicenseImages,
  validAdditionalDriver,
  clickedDisabledBekreft,
} from "../../atoms";

const LinkWrap = styled.div`
  margin-top: ${(props) => props.theme.margins.lg};
  margin-left: 13%;
`;

function UploadDrivingLicense() {
  const [order, setOrder] = useRecoilState(orderState);
  const [showExtraDriver, setShowExtraDriver] = useRecoilState(
    showAdditionalDriver
  );
  const showValidationMessages = useRecoilValue(clickedDisabledBekreft);
  const addedDrivingLicense = useRecoilValue(addedDrivingLicenseImages);
  const validExtraDriver = useRecoilValue(validAdditionalDriver);
  const orderConfirmed = order.order_state === OrderState.Confirmed;

  return (
    <Section
      header="Førerkort"
      expandable={false}
      validationBorder={
        showValidationMessages &&
        ((showExtraDriver && !validExtraDriver) || !addedDrivingLicense)
      }
    >
      <Fragment>
        <AddDrivingLicense
          missingValidation={showValidationMessages && !addedDrivingLicense}
        />
        {!orderConfirmed && (showExtraDriver ? (
          <Fragment>
            <RegisterExtraDriver />
            <LinkWrap>
              <ButtonLink
                onClick={() => {
                  const orderCustomer = order.order_customer as OrderCustomer;
                  const newOrder = {
                    ...order,
                    order_customer: {
                      ...orderCustomer,
                      additional_driver: null,
                    },
                  };
                  setOrder(newOrder);
                  setShowExtraDriver(false);
                }}
              >
                Fjern ekstra fører
              </ButtonLink>
            </LinkWrap>
          </Fragment>
        ) : (
          <LinkWrap>
            <ButtonLink onClick={() => setShowExtraDriver(true)}>
              Legg til fører
            </ButtonLink>
          </LinkWrap>
        ))}
      </Fragment>
    </Section>
  );
}

export default UploadDrivingLicense;
