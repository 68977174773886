
export const calculateTimeLeft = (startDate: string) => {
    const now = new Date();
    const rentalStartDate = new Date(startDate);
    rentalStartDate.setMinutes(rentalStartDate.getMinutes() + 15);
    const difference = +rentalStartDate - +now;
    let timeLeft = { minutes: 0, seconds: 0 };

    if (difference > 0) {
        timeLeft = {
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    }
    return timeLeft;
}

export const isInInitialTime = (startDate: string) => {
    const timeLeft = calculateTimeLeft(startDate)
    return (timeLeft.minutes * 60) + timeLeft.seconds > 0
}