import { Damage, DamageType, UpdateDamage } from "../../../typings";
import {
  RegisterDamageType,
  DamageCall,
  DamageAction,
  ErrorCall,
  UpdateDamageType,
} from "./DamageForm.types";
import Resources from "../../../resources/Resources";
import { DropdownItem } from "../../View/Dropdown";

export const getActionTitle = (action: DamageAction) =>
  action === DamageAction.Insert ? "Register skade" : "Rediger skade";

export const getActionButtonTitle = (action: DamageAction) =>
  action === DamageAction.Insert ? "Register skade" : "Oppdater";

export const dropdownSelector = (set: any, options: any) => (
  item: DropdownItem
) => set(options.find((option: any) => option.id === item.id) as DamageType);

export const onRequestError: ErrorCall = (err: Error) => {
  console.error("err: ", err);
};

export const registerDamage: RegisterDamageType = async (
  action: DamageCall,
  onRequestError: ErrorCall,
  damage: Damage
) =>
  Resources.damage
    .post(damage)
    .then(Resources.response.json)
    .then(action)
    .catch(onRequestError);

export const updateDamage: UpdateDamageType = async (
  action: DamageCall,
  onRequestError: ErrorCall,
  damage: UpdateDamage,
) =>
  Resources.damage
    .put(damage)
    .then(Resources.response.json)
    .then(action)
    .catch(onRequestError);
