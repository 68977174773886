import React, { useState } from "react";
import styled from "styled-components";
import arrowDown from "../../assets/icons/small_arrow_down.svg";
import arrowUp from "../../assets/icons/small_arrow_up.svg";

const DropDown = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
`;

const ChoosenItem = styled.div<{ validationError: boolean | undefined }>`
  display: flex;
  background-color: ${(props) => props.theme.colors.mainGreen};
  color: ${(props) => props.theme.colors.white};
  margin-bottom: 2px;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
  border: ${(props) =>
    props.validationError
      ? `1px solid ${props.theme.colors.signalRed}`
      : "none"};
  border-radius: 8px;
`;

const Arrow = styled.img`
  margin-left: 6px;
  filter: invert(1);
`;

const RightContent = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.mainGreen};
`;

const DropdownContent = styled.div`
  background-color: ${(props) => props.theme.colors.backgroundGrey};
`;

const DropdownItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
  &:hover {
    font-weight: ${(props) => props.theme.fontWeights.bolder};
  }
  &:focus {
    font-weight: ${(props) => props.theme.fontWeights.bolder};
  }
`;

const ValidationMessage = styled.div`
  color: ${(props) => props.theme.colors.signalRed};
  font-size: ${(props) => props.theme.fontSizes.sm};
  font-weight: ${(props) => props.theme.fontWeights.normal};
`;

export interface DropdownItem {
  description: string | JSX.Element;
  id: string;
}

interface Props {
  onClickItem: (item: DropdownItem) => void;
  items: DropdownItem[];
  choosenItem: DropdownItem | null;
  defaultText?: string;
  validationError?: boolean;
  validationMessage?: string;
}

function Dropdown(props: Props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const itemsWithoutChoosen = props.choosenItem
    ? props.items.filter((i) => i.id !== props.choosenItem?.id)
    : props.items;
  const items = itemsWithoutChoosen.map((item, key) => (
    <DropdownItem
      key={key}
      tabIndex={0}
      onKeyDown={(evt: React.KeyboardEvent) => {
        if (evt.key === "Enter") {
          props.onClickItem(item);
          setDropdownOpen(false);
        }
      }}
      onClick={() => {
        props.onClickItem(item);
        setDropdownOpen(false);
      }}
    >
      {item.description}
    </DropdownItem>
  ));

  return (
    <DropDown>
      <ChoosenItem
        validationError={props.validationError}
        tabIndex={0}
        onKeyDown={(evt: React.KeyboardEvent) => {
          if (evt.key === "Enter") {
            setDropdownOpen(!dropdownOpen);
          }
        }}
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        {props.choosenItem?.description || props.defaultText}
          <Arrow
            src={dropdownOpen ? arrowUp : arrowDown}
            alt="Pil for å åpne/lukke nedtrekksmeny"
            className="arrow"
          />
      </ChoosenItem>
      <DropdownContent>{dropdownOpen ? items : null}</DropdownContent>
      {props.validationError && props.validationMessage && (
        <ValidationMessage>{props.validationMessage}</ValidationMessage>
      )}
    </DropDown>
  );
}

export default Dropdown;
