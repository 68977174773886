import React from "react";
import styled, { CSSProperties, keyframes } from "styled-components";

const bounce = keyframes`
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
`;

const DoubleBounce = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.lightGreen};
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: ${bounce} 2s infinite ease-in-out;
  animation: ${bounce} 2s infinite ease-in-out;
`;

const SecondDoubleBounce = styled(DoubleBounce)`
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
`;

const Spinner = styled.div`
  grid-area: first;
  z-index: 101;
  margin: auto;
  width: 40px;
  height: 40px;
  position: relative;
`;

interface SimpleSpinnerProps {
  style?: CSSProperties;
}

function SimpleSpinner(props: SimpleSpinnerProps) {
  return (
    <Spinner style={props.style}>
      <DoubleBounce></DoubleBounce>
      <SecondDoubleBounce></SecondDoubleBounce>
    </Spinner>
  );
}

export default SimpleSpinner;
