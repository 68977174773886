import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { api } from "../../resources/Resources";
import SimpleSpinner from "../View/SimpleSpinner";

interface Props {
  onOpenFile: (file: File) => void;
  imageId?: string | null;
  placeholderImage?: string | null;
  altLabel: string;
  height?: number;
  width?: number;
}

const Image = styled.img`
  grid-area: first;
  z-index: 100;
  width: 100%;
  cursor: pointer;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.lightGreen};
  border-radius: 4px;
  padding: 20px;
`;

const Label = styled.label`
  display: grid;
  grid-template-areas: "first";
`;

const Input = styled.input`
  grid-area: first;
  z-index: 1;
  height: 100%;
  font-size: 2px;
  opacity: 0;
  cursor: pointer;
`;

export function CarPhoto(props: Props) {
  const [fetchLoad, setFetchLoad] = useState(false);

  useEffect(() => {
    if (props.imageId) {
      setFetchLoad(true);
    }
  }, [props.imageId]);

  const finishedFetch = (
    evt: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    evt.preventDefault();
    setFetchLoad(false);
  };

  return (
    <ImageWrapper>
      <Label>
        {fetchLoad ? <SimpleSpinner /> : null}
        <Image
          onLoad={finishedFetch}
          height={props.height}
          width={props.width}
          src={
            props.imageId
              ? `${api}GetFile/${props.imageId}`
              : props.placeholderImage
                ? props.placeholderImage
                : undefined
          }
          alt={props.altLabel}
        />
        <Input
          type="file"
          accept=".jpeg, .jpg, .png, .gif, .bmp"
          onChange={(evt) => {
            if (evt.target.files) {
              setFetchLoad(true);
              props.onOpenFile(evt.target.files[0]);
            }
          }}
        />
      </Label>
    </ImageWrapper>
  );
}

export default CarPhoto;
