import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  orderState,
  orderClosedDate,
  timerFinished,
  carState,
  deliverCarBackClicked,
  extraCostState,
} from "../atoms";
import DuringRental from "./StatusPages/DuringRental";
import AwaitingCustomerConfirmation from "./StatusPages/AwaitingCustomerConfirmation";
import { OrderState } from "../typings";
import Resources from "../resources/Resources";
import FirstFifteenMinutesOfRental from "./StatusPages/FirstFifteenMinutesOfRental";
import PendingOrCollectionDelivery from "./StatusPages/PendingOrCollectionDelivery";
import RentalCanceled from "./StatusPages/RentalCanceled";
import { ShowTimer } from "../utils";
import DeliverRentalCar from "./StatusPages/DeliverRentalCar";
import Spinner from "./View/Spinner";
import Tabs from "./Tabs/Tabs";
import { isOrderFinished } from "./Order/utils";
import RentalFinished from "./StatusPages/RentalFinished";
import { useParams } from "react-router-dom";

function OrderPage() {
  const setExtraCosts = useSetRecoilState(extraCostState);
  const setCar = useSetRecoilState(carState);
  const closedOrderDate = useRecoilValue(orderClosedDate);
  const deliverCarClicked = useRecoilValue(deliverCarBackClicked);
  const finishedTimer = useRecoilValue(timerFinished);
  const [order, setOrder] = useRecoilState(orderState);
  const showTimer = ShowTimer(closedOrderDate);
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [loader, toggleLoader] = useState<boolean>(false);
  const isOrderCancelled = order.order_state === OrderState.Canceled;
  const param = useParams<{ link: string }>();

  const checkOrderState = (orderState: number) => {
    if (order?.order_state) return order.order_state > orderState;
    return false;
  };

  const isTimerFinished = () =>
    (!showTimer && finishedTimer) ||
    order?.imagesFinished?.beforeRentalFinishedAt;

  const getTabs = (order: any) => {
    return [
      {
        label: "Venter på Bekreftelse",
        index: 1,
        Component: AwaitingCustomerConfirmation,
        completed: checkOrderState(OrderState.PendingConfirmation),
      },
      {
        label: "Bilen klar for henting",
        index: 2,
        Component: PendingOrCollectionDelivery,
        completed: checkOrderState(OrderState.PendingCustomerPickup),
      },
      {
        label: "Under leie",
        index: 3,
        Component: isTimerFinished()
          ? DuringRental
          : FirstFifteenMinutesOfRental,
        completed:
          checkOrderState(OrderState.RentalInProgress) ||
          deliverCarClicked ||
          !!order?.carRenterImagesWhenRentalIsFinished?.length,
      },
      {
        label: "Lever tilbake bilen",
        index: 4,
        Component: DeliverRentalCar,
        completed: !!isOrderFinished(order),
      },
    ];
  };

  const setTab = (order: any) => {
    let tabNumber = 0;
    switch (order.order_state) {
      case 0:
        tabNumber = 1;
        break;
      case 1:
      case 2:
      case 3:
        tabNumber = 2;
        break;
      case 4:
        if (
          deliverCarClicked ||
          !!order?.carRenterImagesWhenRentalIsFinished?.length
        ) {
          tabNumber = 4;
        } else {
          tabNumber = 3;
        }
        break;
    }
    setSelectedTab(tabNumber);
  };

  useEffect(() => {
    toggleLoader(true);

    const checkExtraData = async (_order: any) => {
      const res = await Resources.order.getExtraCosts(param?.link ?? "");
      const data = await Resources.response.json(res);
      if(data?.assignedCar?.carModelImagesUrls?.length){
        setCar(prevState => ({...prevState, carModelImagesUrls: data?.assignedCar?.carModelImagesUrls }));
      }
      if (data?.imagesFinished?.beforeRentalFinishedAt) {
        _order.imagesFinished = await { ...data?.imagesFinished };
      }
      if (data?.carRenterImagesBeforeRentalStart?.length) {
        _order.carRenterImagesBeforeRentalStart =
          await data?.carRenterImagesBeforeRentalStart;
      }
      if (data?.carRenterImagesWhenRentalIsFinished?.length) {
        _order.carRenterImagesWhenRentalIsFinished =
          await data?.carRenterImagesWhenRentalIsFinished;
      }

      if (_order.id) await setOrder(_order);
      
      setExtraCosts(data);
      setTab(_order);
      toggleLoader(false);
    };

    if (order?.id) {
      const _order = { ...order };
      const orderState = order.order_state || 0;
      if (
        order.order_state === OrderState.RentalInProgress ||
        order.order_state === OrderState.PendingCustomerPickup
      ) {
        Resources.order
          .getAssignedCarToOrder(order)
          .then(Resources.response.json)
          .then((data) => setCar(prevState => ({...prevState, ...data})));
      }
      if (
        orderState === OrderState.PendingConfirmation ||
        orderState === OrderState.Confirmed ||
        orderState === OrderState.PendingCustomerPickup ||
        orderState === OrderState.Canceled ||
        orderState >= OrderState.RentalInProgress
      ) {
        checkExtraData(_order);
      } else {
        setTab(_order);
        toggleLoader(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order.id, order.order_state, setCar, deliverCarClicked, finishedTimer, order?.imagesFinished?.beforeRentalFinishedAt, setExtraCosts]);

  if (loader || !order.id) return <Spinner />;

  if (isOrderCancelled) return <RentalCanceled order={order} />;
  return (
    <div>
      <Tabs selectedTab={selectedTab} tabs={getTabs(order)} data={order} />
      {isOrderFinished(order) ? <RentalFinished /> : null}
    </div>
  );
}

export default OrderPage;
