
import hmacSHA512 from "crypto-js/hmac-sha512";
import Base64 from "crypto-js/enc-base64";

const SET_BODY = (method: string, data: any) => {
    switch (method) {
        case 'GET':
            return {}
        default:
            return { body: JSON.stringify(data) }
    }
}

interface BaseConfig {
    method: string
    headers?: any
    host?: string
    signRequest?: boolean
    body?: (method: string, data: any) => {}
}

interface Config {
    method: string
    headers: any
    host: string
    body: (method: string, data: any) => {}
}

const TemplateConfig = {
    headers: {
        "Content-Type": "application/json; charset=UTF-8",
    },
    host: '',
    body: SET_BODY
}

const DecorateConfig = (config: BaseConfig): Config => ({ ...TemplateConfig, ...config })

const FETCH = (config: BaseConfig) => {
    let conf: Config = DecorateConfig(config)
    return async (url: string = '', data: any = {}) => {
        let signatureHeaders = {};

        if (config.signRequest) {
            const key = process.env.REACT_APP_REQUEST_SIGN_KEY ?? '';
            let unixNow = Math.round(Date.now() / 1000);
            
            const systemDataResponse = await fetch(conf.host.replace("/api", ""));
            
            if (systemDataResponse.ok) {
                const systemData = await systemDataResponse.json();
                unixNow = systemData.time;
            }

            // 60 sec expiration time
            const expireUnixTimestamp = unixNow + 60;

            const payload = expireUnixTimestamp + "." + JSON.stringify(data);
            const hashSignature = hmacSHA512(payload, key);
            const signature = Base64.stringify(hashSignature);

            signatureHeaders = {
                "X-Signature": signature,
                "X-Signature-Expire": expireUnixTimestamp,
            }
        }

        const response = await fetch(`${conf.host}${url}`, {
            method: conf.method,
            headers: { ...conf.headers, ...signatureHeaders },
            ...(conf.body(config.method, data))
        });

        if (!response.ok) {
            throw new Error(`Request ${conf.method} ${url} failed. Status ${response.status}`);
        }

        return response;
    }
}


export default FETCH
