import React, { Fragment } from "react";
import CarPhoto from "./CarPhoto";
import styled from "styled-components";
import Section from "../View/Section";
import Resources from "../../resources/Resources";
import { outsidePictures, insidePictures } from "./CarPicturesPlaceholders";
import { CardContainer } from "../../styled-components";

interface Props {
  isValid: boolean;
  description: string;
  pictures: string[];
  updatePictures: (pictures: string[]) => void;
}

interface ImagesContainerProps {
  error?: boolean;
}

const ImagesContainer = styled.div<ImagesContainerProps>`
  width: 95%;
  display: grid;
  grid-template-columns: repeat(2, 48%);
  gap: 16px;
  border-radius: 4px;
  padding: 3px;
  justify-content: center;
`;

const Description = styled.p`
  margin-top: -5px;
  margin-bottom: 20px;
  font-size: ${(props) => props.theme.fontSizes.md};
`;
const CardTitle = styled.p`
  font-size: ${(props) => props.theme.fontSizes.lg};
  font-weight: ${(props) => props.theme.fontWeights.superBold};
`;

function TakePicturesOfCar(props: Props) {
  const handleFileInput = (file: File, name: string) => {
    file &&
      Resources.file.upload
        .picture(file)
        .then(Resources.response.json)
        .then(
          (data: any) => {
            const allImages = [...outsidePictures, ...insidePictures];

            const newPics = allImages.map((imageType, index) =>
              imageType.name === name
                ? data.id
                : (props.pictures && props.pictures[index]) || ""
            );

            props.updatePictures(newPics);
          },
          (error) => {
            console.error(error);
          }
        );
  };

  return (
    <Section header="DIN LEIEBIL" expandable={false}>
      <CardContainer>
        <CardTitle>Bilder av bil</CardTitle>
        <Description>{props.description}</Description>
        <ImagesContainer error={!props.isValid}>
          {outsidePictures.map((el, index) => (
            <CarPhoto
              key={el.name}
              onOpenFile={(file: File) => handleFileInput(file, el.name)}
              altLabel={el.description}
              imageId={props.pictures && props.pictures[index]}
              placeholderImage={el.placeholderImage}
            />
          ))}
          {insidePictures.map((el, index) => (
            <CarPhoto
              key={el.name}
              onOpenFile={(file: File) => handleFileInput(file, el.name)}
              altLabel={el.description}
              imageId={
                props.pictures && props.pictures[index + outsidePictures.length]
              }
              placeholderImage={el.placeholderImage}
            />
          ))}
        </ImagesContainer>
      </CardContainer>
    </Section>
  );
}

export default TakePicturesOfCar;
