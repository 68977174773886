import React from "react";
import styled from "styled-components";

const Link = styled.button`
  text-align: left;
  padding-left: 0px;
  color: ${(props) => props.theme.colors.mainGreen};
  text-decoration-line: underline;
  background-color: none;
  border: none;
  background: none;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.lighter};
  &:hover {
    font-weight: ${(props) => props.theme.fontWeights.bolder};
  }
  &:focus {
    font-weight: ${(props) => props.theme.fontWeights.bolder};
  }
`;

interface Props {
  onClick: () => void;
  children: string;
}
function ButtonLink(props: Props) {
  return <Link onClick={() => props.onClick()}>{props.children}</Link>;
}

export default ButtonLink;
