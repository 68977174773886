import React, { Fragment, useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import TakePicturesOfCar from "../Photo/TakePicturesOfCar";
import RentalInformation from "../CustomerAndCarInformation/RentalInformation";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import CustomerInformation from "../CustomerAndCarInformation/CustomerInformation";
import Button from "../View/Button";
import RegisterKmStand from "../CustomerAndCarInformation/RegisterKmStand";
import { ValidateImages } from "../../utils";
import { useRecoilState } from "recoil";
import { orderState } from "../../atoms";
import Resources from "../../resources/Resources";
import FeedbackModal from "../../components/FeedbackModal";
import { OrderState } from "../../typings";
import { ButtonContainer } from "../../styled-components";
import StepperWrapper from "../View/StepperWrapper";

function DeliverRentalCar() {
  const childRef = useRef<{changeStep: () => void}>(null);
  const [order, setOrder] = useRecoilState(orderState);
  const [deliverCarClicked, setDeliverCarClicked] = useState(false);
  const [kmStand, setKmStand] = useState(0);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const [securedSteps,setSecuredSteps] = useState<number[]>([1,2,3,4])

  const param = useParams<{ link: string }>();
  const mileageStart = order.mileage_start as number;
  const validKmStand = kmStand > mileageStart;
  const validImages = ValidateImages(order.carRenterImagesWhenRentalIsFinished);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep])

  async function deliverCar() {
    if (loading) return;

    setDeliverCarClicked(true);

    if (!validKmStand || !validImages) {
      return;
    }

    setLoading(true);

    const date = new Date();

    await Resources.order.updateMileage(kmStand, param.link ?? "");
    await Resources.order.carReturnedOrderState(
      { timestamp: date },
      order?.id
    );

    setIsFeedbackOpen(true);
  }

  async function sendFeedback(rating: number, message: string) {
    try {
      await Resources.feedback.sent({
        rating,
        message,
        source: "CarRenter",
        sourceVersion: process.env.REACT_APP_VERSION ?? "1.0.0",
        additionalData: {
          orderId: order.id,
        },
      });

      // setIsFeedbackOpen(false);

      setOrder({
        ...order,
        order_state: OrderState.CarReturned,
        timeStamp_end_ordered: new Date(),
        mileage_end: kmStand,
      });
      navigate(0);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFeedbackOpen(false);
      setLoading(false);
    }
  }

  const renderSteps = () => {
    switch (activeStep) {
      case 0:
        return (
          <TakePicturesOfCar
            pictures={order.carRenterImagesWhenRentalIsFinished || []}
            updatePictures={(p) => {
              void Resources.order.updateImagesOfCarAfterRentalIsFinished(
                p,
                param.link ?? ""
              );
              setOrder({
                ...order,
                carRenterImagesWhenRentalIsFinished: p,
              });
            }}
            isValid={!deliverCarClicked || validImages}
            description="Forsikringen er gyldig fra det har blitt tatt bilder av bilen. Vi trenger totalt 8 bilder - 4 av utsiden, og 4 av innsiden. Husk å ta bilde av batteristatus."
          />
        );
      case 1:
        return (
          <RegisterKmStand
            registeredKmStand={kmStand}
            notValidKmStand={deliverCarClicked && !validKmStand}
            setKmStand={setKmStand}
            kmStand={order.mileage_start as number}
          />
        );
      case 2:
        return <TermsAndConditions asExpandableContainer={false} />;
      case 3:
        return (
          <RentalInformation
            order={order}
            showLeiebiltypeDropdown={false}
            asExpandableContainer={false}
          />
        );
      case 4:
        return <CustomerInformation order={order} showForerkortInfo={true} />;
    }
  };

  const goBack = () => {
    if (activeStep > 0) setActiveStep((state) => state - 1);
  };

  const checkStep = (stepNo: number): boolean => {
    if (stepNo === 0) return !validImages;
    
    if (stepNo === 1) return !validKmStand;

    if (stepNo === 4) return true; 

    return false;
  }  

  const disabledNxtBtn = checkStep(activeStep);

  useEffect(() => {
    if (validKmStand) setSecuredSteps([])
    else if (validImages) setSecuredSteps([2,3,4]);
  },[validImages,validKmStand]);

  return (
    <Fragment>
      <FeedbackModal isOpen={isFeedbackOpen} onSend={sendFeedback} />
      <StepperWrapper
        steps={5}
        securedSteps={securedSteps}
        handleActiveStep={setActiveStep}
        activeStep={activeStep}
        ref={childRef}
      >
        <>
            {renderSteps()}
      <ButtonContainer>
        {activeStep ? (
          <Button disabled={!activeStep} onClick={goBack} darkcolor={false}>
            Tilbake
          </Button>
        ) : null}
        {activeStep !== 4 ? (
          <Button
          onClick={() => childRef?.current?.changeStep()}
            darkcolor={false}
            disabled={disabledNxtBtn}
          >
            Neste
          </Button>
        ) : null}
      </ButtonContainer>
      <Button
        sticky={true}
        notValid={!validKmStand || !validImages}
        darkcolor={false}
        onClick={deliverCar}
        disabled={loading || !validKmStand || !validImages}
      >
        {loading ? "Vennligst vent..." : "Lever bilnøkkel"}
      </Button>
          </>
      </StepperWrapper>
    </Fragment>
  );
}

export default DeliverRentalCar;
