export default class Form {
    private _form: FormData;

    constructor(){
        this._form = new FormData();
        return this
    }

    append(type: string, file: File): Form {
        this._form.append(type, file, file.name);
        return this
    }

    get(): FormData{
        return this._form
    }
    
}