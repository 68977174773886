import React from "react";
import styled from "styled-components";
import { CarType, IdName } from "../../typings";
import { useRecoilValue, useRecoilState } from "recoil";
import Dropdown from "../View/Dropdown";

import {
  currentCarType,
  initialCarType,
  carTypesWithSimilarOrHigherPrice,
  orderState,
} from "../../atoms";
import Resources from "../../resources/Resources";
import { useParams } from "react-router-dom";

const DropdownContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
function UpgradeCarType() {
  const param = useParams<{ link: string }>();
  const [order, setOrder] = useRecoilState(orderState);
  const carType = useRecoilValue(currentCarType);
  const initCarType = useRecoilValue(initialCarType);
  const carTypesAvailableForUpgrade = useRecoilValue(
    carTypesWithSimilarOrHigherPrice
  );

  const getPrice = (ct: CarType) => {
    if (!ct || !initCarType) {
      return;
    }
    return ct.id === initCarType.id
      ? "Inkludert"
      : `${ct.price - initCarType.price},- pr. døgn`;
  };

  const getDropdownItem = (ct: CarType) => ({
    description: (
      <DropdownContent>
        <div>{ct.name}</div>
        <div>{getPrice(ct)}</div>
      </DropdownContent>
    ),
    id: ct.id,
  });
  const items =
    carTypesAvailableForUpgrade &&
    carTypesAvailableForUpgrade.map((ct) => getDropdownItem(ct));

  const update = (selectedCar: CarType) => {
    const carType: IdName = {
      id: selectedCar.id,
      name: selectedCar.name,
    };
    setOrder({
      ...order,
      carType_selected: carType,
    });

    void Resources.order.updateCarType(carType, param.link ?? "");
  };
  return (
    <Dropdown
      choosenItem={carType && getDropdownItem(carType)}
      items={items}
      onClickItem={(item) => {
        const dt = carTypesAvailableForUpgrade.find((d) => d.id === item.id);
        update(dt as CarType);
      }}
    />
  );
}

export default UpgradeCarType;
