import React from "react";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: 30px;
`;

export const HorizontalBar = styled.div`
  font-size: ${(props) => props.theme.fontSizes.sm};
  display: flex;
  justify-content: center;
  border-bottom: 3px solid;
  padding: 20px 0px 10px 0px;
  border-bottom-color: ${(props) => props.theme.colors.mainGreen};
  margin-bottom: 20px;
`;
export const Content = styled.div`
  font-size: ${(props) => props.theme.fontSizes.sm};
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  white-space: pre-wrap;
  text-align: center;
`;

export function Footer() {
  return (
    <Container>
      <HorizontalBar>
        For hvert leieforhold planter Greenpool et tre. 🌳
      </HorizontalBar>
      <Content>
        {`Greenpool AS
Org. nr. 925 535 745
Epost: post@greenpool.no
Tlf: 40 00 17 12\n\n\nwww.greenpool.no
© Greenpool 2023`}
      </Content>
    </Container>
  );
}

export default Footer;
