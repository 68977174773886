import React, { Fragment } from "react";
import { Order } from "../../typings";
import CreditCardInformation from "../CardRegistration/CreaditCardInformation";
import Section from "../View/Section";
import ValueLabel from "../View/ValueLabel";

interface Props {
  order: Order;
}

export default function Payment({ order }: Props) {
  return (
    <>
      <Section expandable={false} header="Leieforhold">
        <Fragment>
          <ValueLabel
            value={order.order_number as string}
            label="Reservasjonsnr." />
        </Fragment>
      </Section>
      <CreditCardInformation
        order={order} />
    </>
  );
}
