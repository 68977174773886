import React, { useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import RentalInformation from "../CustomerAndCarInformation/RentalInformation";
import CustomerInformation from "../CustomerAndCarInformation/CustomerInformation";
import UploadDrivingLicense from "../DrivingLicence/UploadDrivingLicense";
import {
  clickedDisabledBekreft,
  acceptedTermsAndConditions,
  showAdditionalDriver,
  validAdditionalDriver,
  addedDrivingLicenseImages,
  orderState,
  additionalDriver,
  creditCardAdded,
  insurancePrice,
  currentCarType,
} from "../../atoms";
import Button from "../View/Button";
import CreditCardInformation from "../CardRegistration/CreaditCardInformation";
import "./styles.css";
import styled from "styled-components";
import Resources from "../../resources/Resources";
import { OrderState } from "../../typings";
import TermsAndConditionsCheckbox from "../TermsAndConditions/TermsAndConditionsCheckbox";
import { ButtonContainer } from "../../styled-components";
import ConfirmMessageModal from "../ConfirmMessage";
import InsurancePolicy from "../InsurancePolicy/InsurancePolicy";
import { getCarTypePrice } from "../../utils";
import StepperWrapper from "../View/StepperWrapper";

function AwaitingCustomerConfirmation() {
  const childRef = useRef<{changeStep: () => void}>(null);
  const isAcceptedTermsAndConditions = useRecoilValue(
    acceptedTermsAndConditions
  );
  const insurancePriceVal = useRecoilValue(insurancePrice);
  const isCreditCardAdded = useRecoilValue(creditCardAdded);
  const showExtraDriver = useRecoilValue(showAdditionalDriver);
  const validExtraDriver = useRecoilValue(validAdditionalDriver);
  const extraDriver = useRecoilValue(additionalDriver);
  const addedDrivingLicense = useRecoilValue(addedDrivingLicenseImages);
  const setClickedDisabledBekreft = useSetRecoilState(clickedDisabledBekreft);
  const [order, setOrder] = useRecoilState(orderState);
  const carType = useRecoilValue(currentCarType);
  const [confirmModal, openConfirmModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [btnLoader, toggleBtnLoader] = useState(false);
  const [securedSteps,setSecuredSteps] = useState([3,4])
  const param = useParams<{ link: string }>();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [activeStep]);
    

  const renderSteps = () => {
    switch (activeStep) {
      case 0:
        return (
          <RentalInformation
            order={order}
            showLeiebiltypeDropdown={true}
            asExpandableContainer={false}
          />
        );
      case 1:
        return <InsurancePolicy order={order} />;
      case 2:
        return <CreditCardInformation order={order} />;
      case 3:
        return <UploadDrivingLicense />;
      case 4:
        return (
          <CustomerInformation
            order={order}
            showForerkortInfo={false}
            notLastSection={true}
          />
        );
    }
  };

  const goBack = () => {
    if (activeStep > 0) setActiveStep((state) => state - 1);
  };

  const getExtraCostData = () => {
    let extraCosts = [];
    const carTypePrice = getCarTypePrice(order, carType);

    if(insurancePriceVal.pricePerDay){
      extraCosts.push({
        name: `Redusjon egenandel (Forsikring med ${insurancePriceVal.type})`,
        pricePerDay: insurancePriceVal.pricePerDay,
        vatRate: 0,
      });
    }
    
    if(carTypePrice){
      extraCosts.push({
        name: `Bil oppgradering`,
        pricePerDay: carTypePrice,
        vatRate: 0,
      });
    }

    return extraCosts;
  }

  const submitNext = async () => {
      if (activeStep < 4) return;

      if (!allowedToConfirm || orderConfirmed) return;
      toggleBtnLoader(true);
      const link = param.link || "";
      setClickedDisabledBekreft(true);

      if (extraDriver && validExtraDriver) {
        await Resources.order.addAdditionalDriver(extraDriver, link);
      }
      
      const extraCosts = getExtraCostData();
      await Resources.order.confirmOrderState({ extraCosts }, order?.id);

      toggleBtnLoader(false);
      openConfirmModal(true);
  };

  const toggleModal = () => {
    setOrder({
      ...order,
      order_state: OrderState.Confirmed,
    });
    openConfirmModal((prev) => !prev);
  };

  const allowedToConfirm =
    isAcceptedTermsAndConditions &&
    addedDrivingLicense &&
    isCreditCardAdded &&
    (!showExtraDriver || validExtraDriver);

  const inActiveBackBtn = activeStep === 0;
  const orderConfirmed = order.order_state === OrderState.Confirmed;
  const finalStep = activeStep === 4;
  const disableNxtBtn = (activeStep === 2 && !isCreditCardAdded) || (activeStep === 3 && !addedDrivingLicense);

  useEffect(() => {
    if (isCreditCardAdded && !addedDrivingLicense) setSecuredSteps([4]);
    else if (isCreditCardAdded && addedDrivingLicense) setSecuredSteps([]);
  }, [isCreditCardAdded, addedDrivingLicense]);

  return (
    <div>
      <StepperWrapper
        steps={5}
        securedSteps={securedSteps}
        handleSubmitNext={submitNext}
        handleActiveStep={setActiveStep}
        activeStep={activeStep}
        ref={childRef}
      >
      <>
      {renderSteps()}
      {finalStep && !orderConfirmed && (
        <ColumnContainer>
          <TermsAndConditionsCheckbox />
        </ColumnContainer>
      )}
      <ButtonContainer>
        {!inActiveBackBtn && (
          <Button disabled={inActiveBackBtn} onClick={goBack} darkcolor={false}>
            Tilbake
          </Button>
        )}
        <Button
          onClick={() => childRef?.current?.changeStep()}
          darkcolor={false}
          disabled={(orderConfirmed && finalStep) || btnLoader || disableNxtBtn}
          loader={btnLoader}
        >
          {finalStep ? "Bekreft Leieforhold" : "Neste"}
        </Button>
      </ButtonContainer>
        </>
      </StepperWrapper>
      <ConfirmMessageModal showModal={confirmModal} toggleModal={toggleModal} />
    </div>
  );
}

export const ColumnContainer = styled.div`
  width: 65%;
  margin: -40px auto -20px auto;
`;

export default AwaitingCustomerConfirmation;
