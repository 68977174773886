import React, { ReactNode, KeyboardEvent, useState } from "react";
import styled from "styled-components";
import SectionHeader from "./SectionHeader";
import arrowDown from "../../assets/icons/arrow_down.svg";
import arrowUp from "../../assets/icons/arrow_up.svg";
import { Heading3 } from "../../styled-components";

const Container = styled.div<{
  validationBorder: boolean;
  lastSectionOnPage: boolean;
}>`
  margin-top: ${(props) => props.theme.margins.sm};
  // margin-bottom: ${(props) => (props.lastSectionOnPage ? "100px" : "0px")};
  background: white;
  border: ${(props) => (props.validationBorder ? `1px solid #B20A0A` : "none")};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // padding-left: 24px;
  // padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
`;

const ExpandableTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

interface Props {
  children: ReactNode;
  header: string;
  expandable: boolean;
  validationBorder?: boolean;
  lastSectionOnPage?: boolean;
}

function Section(props: Props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const header = <Heading3>{props.header}</Heading3>;

  const submitWithEnter = (evt: KeyboardEvent) => {
    if (evt.key === "Enter") {
      setMenuOpen(!menuOpen);
    }
  };
  const isExpanded = !props.expandable || (props.expandable && menuOpen);

  return (
    <Container
      validationBorder={!!props.validationBorder}
      lastSectionOnPage={!!props.lastSectionOnPage}
    >
      <Content>
        {props.expandable ? (
          <ExpandableTitle
            onClick={() => setMenuOpen(!menuOpen)}
            tabIndex={0}
            onKeyDown={submitWithEnter}
          >
            {header}
            <img
              style={{ width: 18, height: 11 }}
              src={menuOpen ? arrowUp : arrowDown}
              alt={menuOpen ? "Pil for å lukke" : "Pil for å åpne"}
            />
          </ExpandableTitle>
        ) : (
          header
        )}
        {isExpanded && props.children}
      </Content>
    </Container>
  );
}

export default Section;
