import React, { useEffect, useRef, useState } from "react";
import RentalInformation from "../CustomerAndCarInformation/RentalInformation";
import CustomerInformation from "../CustomerAndCarInformation/CustomerInformation";
import { OrderState } from "../../typings";
import { useRecoilState, useRecoilValue } from "recoil";
import { orderState, carState } from "../../atoms";
import Button from "../View/Button";
import "./styles.css";
import YourRentalCar from "../CustomerAndCarInformation/YourRentalCar";
import Resources from "../../resources/Resources";
import { ButtonContainer } from "../../styled-components";
import StepperWrapper from "../View/StepperWrapper";

function PendingOrCollectionDelivery() {
  const childRef = useRef<{changeStep: () => void}>(null);
  const car = useRecoilValue(carState);
  const [order, setOrder] = useRecoilState(orderState);
  const [activeStep, setActiveStep] = useState(0);
  const [btnLoader, toggleBtnLoader] = useState(false);
  
  const pendingCustomerPickup =
  order.order_state === OrderState.PendingCustomerPickup;
  const submitStep = pendingCustomerPickup && activeStep === 2;
  const finalStep = pendingCustomerPickup ? 2 : 1;
  const [securedSteps,setSecuredSteps] = useState<number[]>([finalStep])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep])

  const renderSteps = () => {
    if (pendingCustomerPickup) {
      switch (activeStep) {
        case 0:
          return <YourRentalCar car={car} mileageStart={order.mileage_start} reservationNumber={order?.order_number} />;
        case 1:
          return (
            <RentalInformation
              order={order}
              showLeiebiltypeDropdown={false}
              asExpandableContainer={false}
            />
          );
        case 2:
          return <CustomerInformation order={order} showForerkortInfo={true} />;
      }
    } else {
      switch (activeStep) {
        case 0:
          return (
            <RentalInformation
              order={order}
              showLeiebiltypeDropdown={false}
              asExpandableContainer={false}
            />
          );
        case 1:
          return <CustomerInformation order={order} showForerkortInfo={true} />;
      }
    }
  };

  const goBack = () => {
    if (activeStep > 0) setActiveStep((state) => state - 1);
  };

  const submitNext = () => {
    if (activeStep < (pendingCustomerPickup ? 2 : 1))
      setActiveStep((state) => state + 1);
  };

  const pickUpCar = async () => {
    toggleBtnLoader(true);

    const date = new Date();

    await Resources.order.inProgressOrderState(
      { timestamp: date },
      order?.id
    );
    setOrder({
      ...order,
      timeStamp_start_ordered: date,
      order_state: OrderState.RentalInProgress,
    });
    toggleBtnLoader(false);
  }

  const nextButtonDisabled = pendingCustomerPickup ? false : activeStep === 1;

  useEffect(() => {
    if (!nextButtonDisabled) setSecuredSteps([]);
  }, [nextButtonDisabled]);

  return (
    <div>
      <StepperWrapper
          steps={finalStep + 1}
          securedSteps={securedSteps}
          handleSubmitNext={submitNext}
          handleActiveStep={setActiveStep}
          activeStep={activeStep}
          ref={childRef}
      >
      <>
      {renderSteps()}
      <ButtonContainer>
        {activeStep ? <Button disabled={!activeStep} onClick={goBack} darkcolor={false}>
          Tilbake
        </Button> : null}
        {(!nextButtonDisabled && !submitStep) ? 
        <Button
          onClick={() => childRef?.current?.changeStep()}
          darkcolor={false}
          disabled={nextButtonDisabled || btnLoader}
        >
          Neste
        </Button> : null}
      </ButtonContainer>
      <Button
        sticky={true}
        onClick={pickUpCar}
        darkcolor={false}
        disabled={!pendingCustomerPickup || btnLoader}
        loader={btnLoader}
      >
        Start Leieforhold
      </Button> 
      </>
      </StepperWrapper>
    </div>
  );
}

export default PendingOrCollectionDelivery;
