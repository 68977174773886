import { Damage, Order, OrderState, IdName, Driver, CreateFeedbackI, ExtraCosts, UpdateDamage } from "../typings";
import Form from "./Form";
import FETCH from "./Fetch";

export const api = process.env.REACT_APP_API;
export const map_base_url = `https://maps.google.com/?q=`;

export const GET = FETCH({ method: "GET", host: api });
export const POST = FETCH({ method: "POST", host: api });
export const SIGNED_POST = FETCH({ method: "POST", host: api, signRequest: true });
export const PUT = FETCH({ method: "PUT", host: api });
export const DELETE = FETCH({ method: "DELETE", host: api });
export const POST_FILE = FETCH({
  method: "POST",
  headers: { "notset-content": "true" },
  host: api,
  body: (type, data) => ({ body: data }),
});

export interface UpdateOrderState {
  State: OrderState;
  Timestamp: Date;
}

export default {
  response: {
    json: (response: any) => (response.status === 204 ? null : response.json()),
  },
  file: {
    src: (id: string, width: number = 80, height: number = 80) =>
      `${api}GetFile/${id}?width=${width}&height=${height}`,
    upload: {
      picture: (file: File) =>
        POST_FILE(`UploadFile`, new Form().append("picture", file).get()),
    },
  },
  damage: {
    get: (id: string) => GET(`GetDamage/${id}`),
    getAll: () => GET(`GetAllDamages`),
    getAllByCarId: (id: string) => GET(`GetAllDamagesByCarId/${id}`),
    post: (damage: Damage): any => POST(`AddDamage`, damage),
    put: ({ data, damageId, orderId }: UpdateDamage): any => PUT(`v2/damages/${damageId}/${orderId}`, data),
    delete: (damageId?: string, orderId?: string) => DELETE(`v2/damages/${damageId}/${orderId}`),
    getAllDamageTypes: (): any => GET(`GetAllDamageTypes`),
  },
  order: {
    getExtraCosts: (id: string) =>
      GET(`v2/missions/${id}/link`),
    getAssignedCarToOrder: (order: Order) =>
      GET(`GetAssignedCarToOrder/${order.id}`),
    updateDrivingLicenseImages: (images: string[], link: string) =>
      PUT(`UpdateDrivingLicenseImagesByCustomer/${link}`, images),
    confirmOrderState: (data: ExtraCosts, id?: string) =>
      SIGNED_POST(`v2/missions/${id}/confirm`, data),
    inProgressOrderState: (data: {timestamp: Date}, id?: string) =>
      SIGNED_POST(`v2/missions/${id}/in-progress`, data),
    carReturnedOrderState: (data: {timestamp: Date}, id?: string) =>
      SIGNED_POST(`v2/missions/${id}/car-returned`, data),
    updateOrderState: (state: UpdateOrderState, link: string) =>
      PUT(`UpdateOrderStateByCustomer/${link}`, state),
    updateImagesOfCarBeforeRentalStart: (images: string[], link: string) =>
      PUT(`UpdateCustomerImagesBeforeRentalStartByCustomer/${link}`, images),
    updateImagesOfCarAfterRentalIsFinished: (images: string[], link: string) =>
      PUT(
        `UpdateCustomerImagesAfterRentalIsFinishedByCustomer/${link}`,
        images
      ),
    updateCarType: (carType: IdName, link: string) =>
      PUT(`UpdateSelectedCarTypeByCustomer/${link}`, carType),
    updateMileage: (mileage: number, link: string) =>
      PUT(`UpdateMileageByCustomer/${link}`, mileage),
    addAdditionalDriver: (driver: Driver, link: string) =>
      PUT(`AddAdditionalDriver/${link}`, driver),
    beforeRentalFinished: (id?: string) =>
      SIGNED_POST(`v2/missions/${id}/images/BeforeRental/finish`),  
    afterRentalFinished: (id?: string) =>
      SIGNED_POST(`v2/missions/${id}/images/AfterRental/finish`),  
  },
  payments: {
    setPaymentMethodForOrder: (orderId: string, paymentMethodId: string) =>
      PUT(`payments/${orderId}/method`, { paymentMethodId: paymentMethodId })
  },
  carType: {
    getExtraCarType: (cartypeId: string) =>
      GET(`GetExtraCarTypeByCarTypeId/${cartypeId}`),
  },
  feedback: {
    sent: (request: CreateFeedbackI) =>
      SIGNED_POST('feedbacks/car-renter', request)
  }
};
