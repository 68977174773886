import React, { useEffect, Fragment, useState, useRef } from "react";
import {
  carState,
  damageState,
  damageTypesState,
  orderState,
} from "../../atoms";
import { useSetRecoilState, useRecoilState, useRecoilValue } from "recoil";
import TakePicturesOfCar from "../Photo/TakePicturesOfCar";
import RentalInformation from "../CustomerAndCarInformation/RentalInformation";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import CustomerInformation from "../CustomerAndCarInformation/CustomerInformation";
import Damages from "../Damage/Damages";
import DamageTimer from "../Damage/DamageTimer";
import { ValidateImages } from "../../utils";
import Resources from "./../../resources/Resources";
import { useParams } from "react-router-dom";
import { ButtonContainer } from "../../styled-components";
import Button from "../View/Button";
import StepperWrapper from "../View/StepperWrapper";

function FirstFifteenMinutesOfRental() {
  const childRef = useRef<{changeStep: () => void}>(null);
  const [activeStep, setActiveStep] = useState(0);
  const car = useRecoilValue(carState);
  const [order, setOrder] = useRecoilState(orderState);
  const param = useParams<{ link: string }>();
  const setDamage = useSetRecoilState(damageState);
  const setDamageTypes = useSetRecoilState(damageTypesState);
  const validImages = ValidateImages(order.carRenterImagesBeforeRentalStart);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep])

  useEffect(() => {
    if (car.id) {
      Resources.damage
        .getAllByCarId(car.id)
        .then(Resources.response.json)
        .then(setDamage);
    }
  }, [car.id, setDamage]);

  useEffect(() => {
    Resources.damage
      .getAllDamageTypes()
      .then(Resources.response.json)
      .then(setDamageTypes);
  }, [setDamageTypes]);

  const renderSteps = () => {
    switch (activeStep) {
      case 0:
        return (
          <TakePicturesOfCar
            pictures={order.carRenterImagesBeforeRentalStart || []}
            updatePictures={(p) => {
              void Resources.order.updateImagesOfCarBeforeRentalStart(
                p,
                param.link ?? ""
              );
              setOrder({
                ...order,
                carRenterImagesBeforeRentalStart: p,
              });
            }}
            isValid={validImages}
            description="Forsikringen er gyldig fra det har blitt tatt bilder av bilen. Vi trenger totalt 8 bilder - 4 av utsiden, og 4 av innsiden. Husk å ta bilder av batteristatus."
          />
        );
      case 1:
        return (
          <Damages noDamagesText="Det er ingen skader registrert. Registrer eventuelle skader innen 15 min." />
        );
      case 2:
        return <TermsAndConditions asExpandableContainer={false} />;
      case 3:
        return (
          <RentalInformation
            order={order}
            showLeiebiltypeDropdown={false}
            asExpandableContainer={false}
          />
        );
      case 4:
        return <CustomerInformation order={order} showForerkortInfo={true} />;
    }
  };

  const goBack = () => {
    if (activeStep > 0) setActiveStep((state) => state - 1);
  };

  const submitNext = () => {
    if (activeStep < 4) setActiveStep((state) => state + 1);
  };

  return (
    <Fragment>
      <StepperWrapper
        steps={5}
        handleSubmitNext={submitNext}
        handleActiveStep={setActiveStep}
        activeStep={activeStep} 
        ref={childRef}
      > <>
            {renderSteps()}
      <ButtonContainer>
        {activeStep ? (
          <Button disabled={!activeStep} onClick={goBack} darkcolor={false}>
            Tilbake
          </Button>
        ) : null}
        {activeStep !== 4 ? (
          <Button
            onClick={() => childRef?.current?.changeStep()}
            darkcolor={false}
            disabled={activeStep === 4}
          >
            Neste
          </Button>
        ) : null}
      </ButtonContainer>
      <DamageTimer
        order={order}
        rentalStartDate={order.timeStamp_start_ordered as string}
      /></>
      </StepperWrapper>
    </Fragment>
  );
}

export default FirstFifteenMinutesOfRental;
