import styled from "styled-components";

export const InputLabel = styled.label`
  border-radius: 4px;
  width: calc(100% - 10px);
  min-height: 50px;
  height: auto;
  display: grid;
  grid-template-areas: "first";
`;

export const Input = styled.input`
  grid-area: first;
  display: none;
  &:focus {
    outline: solid 2px ${(props) => props.theme.colors.dustyPink} !important;
  }
`;

export const LabelTextContainer = styled.div`
  padding: 5px;
  background: #D8E9F5;
  border-radius: 8px;
  display: flex;
  width: 100%;
  height: calc(100% - 10px);
  justify-content: center;
  align-items: center;
  font-size: ${(props) => props.theme.fontSizes.sm};
  font-weight: ${(props) => props.theme.fontWeights.lighter};
  grid-area: first;
  cursor: pointer;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 33% 33% 33%;
  gap: 24px;
  width: 75%;
  margin-bottom: ${(props) => props.theme.margins.lg};
`;

export const Image = styled.img`
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  z-index: 1;
  border-radius: 2px;
`;

export const ImageWrap = styled.div`
  position: relative;
`;

export const DeleteCross = styled.img`
  position: absolute;
  top: -6px;
  right: -7px;
  z-index: 100;
  cursor: pointer;
`;

export const Title = styled.div`
  margin-top: ${(props) => props.theme.margins.md};
  margin-bottom: ${(props) => props.theme.margins.sm};
  font-size: ${(props) => props.theme.fontSizes.sm};
`;
