import React from "react";
import styled from "styled-components";

interface Props {
  label: string | JSX.Element;
  checked: boolean;
  onChange: () => void;
}

const RadioButton = ({ label, checked, onChange }: Props) => {
  return (
    <Label>
      <Input type="radio" checked={checked} onChange={onChange} />
      {label}
    </Label>
  );
};

const Label = styled.label`
  font-size: ${(props) => props.theme.fontSizes.xs};
  display: flex;
  align-items: center;
  gap: 5px;
`;
const Input = styled.input`
  margin: 0px;
`;

export default RadioButton;
