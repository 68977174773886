import React, { Fragment, useEffect, useState } from "react";
import { CardNumberElement, CardExpiryElement, CardCvcElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import { loadStripe, StripeCardNumberElement } from "@stripe/stripe-js";
import Section from "../View/Section";
import PaymentDataField from './PaymentDataField'
import Button from "../View/Button";
import CardValidationError from './CardValidationError'
import SuccessComponent from './SuccessComponent'
import Spinner from '../View/Spinner'
import Resources from "../../resources/Resources";
import { clickedDisabledBekreft, creditCardAdded } from "../../atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { Order } from "../../typings";
import styled from "styled-components";
import card from "../../assets/icons/card.png"

const ApiKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY as string;

const stripePromise = loadStripe(ApiKey, {
  locale: 'no'
});

const StripeElementsStyles = {
  base: {
    lineHeight: '30px',
  }
}

type CreditCardInformationProps = {
  order: Order;
}

function CreditCardInformation({ order }: CreditCardInformationProps) {
  const [isCreditCardAdded, _] = useRecoilState(creditCardAdded);
  const showValidationMessages = useRecoilValue(clickedDisabledBekreft);
  if (!order.paymentClientSecret) {
    return <></>
  }

  return (<Elements stripe={stripePromise}>
    <Section
      header="Kortopplysninger"
      expandable={false}
      validationBorder={!isCreditCardAdded && showValidationMessages}
    >
      <CreditCardComponent order={order}></CreditCardComponent>
    </Section>
  </Elements>
  );
}

const CreditCardComponent = ({ order }: CreditCardInformationProps) => {
  const stripe = useStripe();
  const [_, setCreditCardAdded] = useRecoilState(creditCardAdded);
  const elements = useElements();
  const [validation, setValidation] = useState({
    message: '',
    error: false,
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [creditCardNumberEmpty, setCreditCardNumberEmpty] = useState(false);
  const [creditCardExpDateEmpty, setCreditCardExpDateEmpty] = useState(false);
  const [creditCardCvvEmpty, setCreditCardCvvEmpty] = useState(false);

  const customerFullName = order.order_customer!.customer
    ? `${order.order_customer!.customer.name} ${order.order_customer!.customer.surname}`
    : order.order_customer!.company.name;

  const confirmCreditCard = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setValidation({ message: '', error: false });
    setSuccess(false);
    const cardNumberElement = elements.getElement(CardNumberElement);

    try {
      const result = await stripe.confirmCardSetup(order.paymentClientSecret as string,
        {
          payment_method: {
            card: cardNumberElement as StripeCardNumberElement,
            billing_details: {
              name: customerFullName,
            },
          }
        });
      setLoading(true);
      if (result.error) {
        setValidation({ message: result.error.message as string, error: true });
      } else {
        setCreditCardAdded(true);
        await Resources.payments.setPaymentMethodForOrder(order.id!, result.setupIntent?.payment_method as string)
          .then(() => setSuccess(true),
            err => {
              throw err;
            });
      }
    }
    catch (ex) {
      setValidation({
        /* @ts-ignore */
        message: ex.toString(),
        error: true,
      });
    }
    finally {
      setLoading(false);
    }
  }

  async function verifySetupIntent() {
    if (!order.paymentClientSecret || !stripe) return;
    setLoading(true);

    const data = await stripe.retrieveSetupIntent(order.paymentClientSecret);

    if (data.setupIntent?.status === "succeeded") {
      await Resources.payments.setPaymentMethodForOrder(order.id!, data.setupIntent.payment_method as string);
      setSuccess(true);
      setCreditCardAdded(true);

      // return;
    } else if (data.error) {
      setValidation({
        message: data.error.message!,
        error: true
      });
    }

    setLoading(false);
  }

  useEffect(() => {
    verifySetupIntent();
  }, [order, stripe]);

  // if (success) {
  //   return <SuccessComponent></SuccessComponent>
  // }

  return <>
    {loading ? <Spinner></Spinner> :
      success ? <SuccessComponent></SuccessComponent> :
    <div style={{ display: 'block' }}>
      <RowContainer>
      <ImageContainer>
      <ImageWrapper src={card}/>
      </ImageContainer>
      <ColumnContainer>
      <CardValidationError show={validation.error} message={validation.message}></CardValidationError>
      <div style={{ width: '100%' }}>
        <PaymentDataField fieldTitle={'Kortnummer'} errorText={'Kortnummer mangler'} isError={creditCardNumberEmpty}>
          <CardNumberElement options={
            {
              showIcon: true,
              style: StripeElementsStyles
            }
          } onChange={x => setCreditCardNumberEmpty(x.empty)} />
        </PaymentDataField >
        <BottomContainer>

        <PaymentDataField fieldTitle={'Utløpsdato'} errorText={'Utløpsdato mangler'} isError={creditCardExpDateEmpty} styles={{ width: '45%', float: 'left' }}>
          <CardExpiryElement options={{ style: StripeElementsStyles }} onChange={x => setCreditCardExpDateEmpty(x.empty)} />
        </PaymentDataField>
        <PaymentDataField fieldTitle={'CVC'} errorText={'CVC mangler'} isError={creditCardCvvEmpty} styles={{ width: '45%', float: 'right' }}>
          <CardCvcElement options={{ style: StripeElementsStyles }} onChange={x => setCreditCardCvvEmpty(x.empty)} />
        </PaymentDataField>
        </BottomContainer>
        </div>
        
      </ColumnContainer>
      </RowContainer>
      <Decription>For å kunne betale for bompasseringer og eventuelt andre utgifter trenger vi at du registrerer betalingskortet ditt.</Decription>
      { 
      <div style={{
        paddingTop: '20px',
        width: '100%',
        display: "flex",
        alignItems: "center"
      }}>
        
        <Button width="60%" fontSize="12px" height="40px" disabled={!stripe} onClick={confirmCreditCard} darkcolor={false}>Bekreft kortopplysninger</Button>
      </div>
}
    </div>}
  </>;


}

const BottomContainer = styled.div`
  margin-top:15px;
`;
const Decription = styled.div`
font-size: ${props => props.theme.fontSizes.sm};
display: flex;
flex-direction: row;
background: ${(props) => props.theme.colors.lightGreen};
padding: 0px 20px 20px 20px; 
`;
const ImageContainer = styled.div`
      width: 30%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    @media screen and (max-width: 786px) {
  width: 20%;
  margin-top:-6px;
}
`;
const ImageWrapper = styled.img`
width: 150px;
@media screen and (max-width: 786px) {
  width: 50px;
}
`;
const RowContainer = styled.div`
  display: flex;
  gap: 10px;
  background: ${(props) => props.theme.colors.lightGreen};
  padding: 30px;
  @media screen and (max-width: 786px) {
    padding: 20px;
    gap: 10px;
  }
`;
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  
`

export default CreditCardInformation;
