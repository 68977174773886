import styled from "styled-components";

export const CarImage = styled.img`
  width: 20em;
  margin: 0 auto;
`;

export const CarPlate = styled.div`
  position: relative;
  padding: 10px;
  border-radius: 8px;
  border: 4px solid;
  borer-color: ${(props) => props.theme.colors.black};
  width: 6em;
  text-align: center;
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.superBold};
`

export const LeftIndicator = styled.div`
position: absolute;
left: 0;
top: 0;
width: 10px;
background: ${props => props.theme.colors.mainGreen};
height: 100%;
`

export const RowContainer = styled.div`
display: flex;
flex-direction: row;
gap: 20px;
margin-bottom: 20px;
align-items: center;
`
export const CarName = styled.div`
  font-size: ${props => props.theme.fontSizes.xl};
  font-weight: ${props => props.theme.fontWeights.superBold}
`;
export const Row2 = styled.div`
display: flex;
flex-direction: row;
gap: 10px;
`;

export const SubText = styled.div`
font-size: ${props => props.theme.fontSizes.sm}
`;