import { Order, OrderState } from "../../typings";

export const orderIsInState = (state: OrderState) => (order: Order | undefined) => order && order.order_state && order.order_state === state

export const isOrderCanceled = orderIsInState(OrderState.Canceled)
export const isOrderCarReturned = orderIsInState(OrderState.CarReturned)
export const isOrderCarChecked = orderIsInState(OrderState.CarChecked)
export const isOrderClosed = orderIsInState(OrderState.Closed)

export const isOrderFinished = (order: Order | undefined) => {
    return (
        isOrderCarReturned(order) ||
        isOrderCarChecked(order) ||
        isOrderClosed(order)
    )
  }