import React from "react";
import styled from "styled-components";
import SuccessIcon from './SuccessIcon'


const SuccessComponent = () => {
    return <SuccessContainer>
        <SuccessIcon></SuccessIcon>
        <SuccessText>Betalingskort er registrert.</SuccessText>
    </SuccessContainer>
}

const SuccessContainer = styled.div`
    // background: ${(props) => props.theme.colors.lightGreen};
    padding: 15px 20px;
    margin: 0 auto;
    display: flex;
`

const SuccessText = styled.div`
float:left;
margin-left: 12px;
margin-top: 8px;
`

export default SuccessComponent;