import React, { useState } from "react";
import styled from "styled-components";
import { Order } from "../../typings";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import RentalInformation from "../CustomerAndCarInformation/RentalInformation";
import CustomerInformation from "../CustomerAndCarInformation/CustomerInformation";
import ShowCarPhotos from "../Photo/ShowCarPhotos";
import Damages from "../Damage/Damages";
import { Link, Horizontal } from "../Base/Base";
import Button from "../View/Button";
import config from "../../config";
import leaf from "../../assets/icons/leaf.svg";
import { useRecoilValue } from "recoil";
import { orderState } from "../../atoms";
import { CardContainer } from "../../styled-components";
import bannerImage from '../../assets/icons/renter_banner.png'

const openFeedbackForm = () => {
  window.open(config.url.feedback);
};

function RentalFinished() {
  const order = useRecoilValue(orderState);

  if (!order) {
    return <p>No order</p>;
  }

  return (
    <RentalFinishedMessage role="main">
      <CardContainer padding="0px">
        <Header>Takk for at du brukte Greenpool</Header>
        <Description>
          Du har bidratt å spare miljøet for unødvendige utslipp.
        </Description>
        <BannerImage src={bannerImage} />
        <Description>
          Les mer om å
          bli utleier på{" "}
          <br/>
          <Link href={config.url.greenpool} target="blank">greenpool.no/slik-fungerer-det</Link>
        </Description>
        <BoldText>Vi ønsker å høre om din opplevelse.</BoldText>
        <ButtonWrapper>
          <Button darkcolor={false} onClick={openFeedbackForm}>
            Gi oss tilbakemelding
          </Button>
        </ButtonWrapper>
      </CardContainer>
    </RentalFinishedMessage>
  );
}

export default RentalFinished;

const RentalFinishedMessage = styled.div`
  text-align: center;
  margin-top: 50px;
`;

const Header = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.xxl};
  font-weight: ${(props) => props.theme.fontWeights.superBold};
  padding: 5% 3% 0px 3%;
`;

const Description = styled.div`
  font-size: ${(props) => props.theme.fontSizes.lg};
  font-weight: ${(props) => props.theme.fontWeights.lighter};
  margin-top: 16px;
  margin-bottom: 32px;
  text-align: center;
  padding: 0px 5%;
`;

const BoldText = styled.div`
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.superBold};
  margin-top: 15px;
  margin-bottom: 32px;
  text-align: center;
  padding: 0px 5%;
`;

const ButtonWrapper = styled.div`
  padding-left: 20%;
  padding-right: 20%;
  margin-bottom: 10%;
`;

const BannerImage = styled.img`
  width: 100%;
`
