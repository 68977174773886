export const insurancePackage = [
  {
    label: "12.000",
    value: 0,
  },
  {
    label: "6.000",
    value: 100,
  },
  {
    label: "2.000",
    value: 150,
  },
];