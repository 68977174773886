import React, { useState, Fragment } from "react";
import styled from "styled-components";
import Modal from "./View/Modal";
import Button from "./View/Button";
import check from "../assets/icons/circle-check-dark.svg";

const Content = styled.div`
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 3%;
  margin-bottom: 0px;
`;

const Text = styled.span`
  text-align: center;
  margin-bottom: ${(props) => props.theme.margins.lg};
  white-space: pre-wrap;
`;
const HeadingText = styled.div`
  text-align: center;
  font-weight: ${(props) => props.theme.fontWeights.superBold};
  font-size: ${(props) => props.theme.fontSizes.xl};
`;

const Checkmark = styled.img`
  width: 80px;
  height: 80px;
  margin: 20px 0px;
`;

const ButtonContainer = styled.div`
  max-width: 136px;
  width: 100%;
`;
interface Props {
  isOpen: boolean;
  onClose?: () => void;
}

function TimeFinishedModal(props: Props) {
  const [modalIsOpen, setIsOpen] = useState(props.isOpen);

  return (
    <Fragment>
      <Modal
        isOpen={modalIsOpen}
        header={false}
        setIsOpen={(open: boolean) => setIsOpen(open)}
      >
        <Content>
          <HeadingText>God tur!</HeadingText>
          <Checkmark
                  src={check}
                  alt="Checkbox"
                  className="checkbox"
                />
          <Text>
          {`Dine bilder (og skader) er nå registrert,
og du kan ta i bruk bilen.`}
            
          </Text>
          <ButtonContainer>
            <Button onClick={() => setIsOpen(false)} darkcolor={false}>
              Ok
            </Button>
          </ButtonContainer>
        </Content>
      </Modal>
    </Fragment>
  );
}

export default TimeFinishedModal;
