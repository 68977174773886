import React, { useEffect, useState } from "react";
import {
  useParams,
  Outlet,
  useNavigate,
} from "react-router-dom";
import styled from "styled-components";
import { orderState, carTypeState } from "../atoms";
import { useSetRecoilState, useRecoilState } from "recoil";
import logo from "../assets/icons/logo.svg";
import { api } from "../resources/Resources";
import { LinkType, OrderLink } from "../typings";
import Payment from "./Payment/Payment";
import { Topbar } from "../styled-components";
import Footer from "./Footer";

const Main = styled.div`
  margin-left: 5%;
  margin-right: 5%;
  background-color: ${(props) => props.theme.colors.white};

  @media (max-width: 900px) {
    padding-bottom: 48px;
  }
`;

const Logobar = styled.div`
  margin-top: ${(props) => props.theme.margins.lg};
  margin-bottom: ${(props) => props.theme.margins.md};
  display: flex;
  justify-content: center;
  > img {
    width: 200px;
  }
`;
function App() {
  const param = useParams<{ link: string }>();
  const [order, setOrder] = useRecoilState(orderState);
  const setAvailableCarTypes = useSetRecoilState(carTypeState);
  const [showPayment, setShowPayment] = useState(false);

  async function fetchData() {
    const response = await fetch(`${api}GetLinkByCode/${param.link}`);
    const responseData = await response.json();
    const link = responseData[0] as OrderLink;

    const orderResponse = await fetch(
      `${api}GetOrderByLink/${link.order_id}/${param.link}`
    );
    setOrder(await orderResponse.json());

    if (link.link_type === LinkType.PaymentConfirmation) {
      setShowPayment(true);
    } else {
      const carTypesResponse = await fetch(
        `${api}GetCarTypesByLink/${link.order_id}/${param.link}`
      );
      setAvailableCarTypes(await carTypesResponse.json());
    }
  }

  useEffect(() => {
    fetchData();
  }, [param.link]);

  

  return (
    <Main role="main">
      <Topbar>Enkel | Trygg | Bærekraftig</Topbar>
      <Logobar>
        <img src={logo} alt="Greenpool logo" />
      </Logobar>
      {showPayment && <Payment order={order} />}
      {!showPayment && <Outlet />}
      <Footer/>
    </Main>
  );
}

export default App;
