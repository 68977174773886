import React, { Fragment } from "react";
import styled from "styled-components";
import Section from "../View/Section";
import { CardContainer } from "../../styled-components";

function RoadsideAssistance() {
  return (
    <Section header="Veihjelp" expandable={false}>
    <CardContainer>
      <Fragment>
        <Text>
          Problemer med bilen? Klikk på knappen for å kontakte Viking.{" "}
        </Text>
        <Link
          target="_blank"
          href="https://vikingredning.no/greenpool-veihjelp/"
        >
          Bestill veihjelp
        </Link>
      </Fragment>
    </CardContainer>
    </Section>
  );
}

const Link = styled.a`
  background-color: ${(props) => props.theme.colors.mainGreen};
  text-decoration: none;
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.md};
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 8px;
  width: 90%;
  margin-right: 5%;
  margin-left: 5%;
  margin-top: ${(props) => props.theme.margins.lg};
  color:  ${(props) => props.theme.colors.backgroundGrey};
`;

const Text = styled.div`
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.lighter};
`;
export default RoadsideAssistance;
