import React, { useEffect, useState } from "react";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import { orderClosedDate, orderState, timerFinished } from "../../atoms";
import { ShowTimer, ValidateImages } from "../../utils";
import Button from "../View/Button";
import { Order } from "../../typings";
import { calculateTimeLeft } from "./Damage.utils";
import styled from "styled-components";
import Resources from "../../resources/Resources";

interface Props {
  rentalStartDate: string;
  order: Order;
}

function DamageTimer(props: Props) {
  const closedOrderDate = useRecoilValue(orderClosedDate);
  const setFinishedTimer = useSetRecoilState(timerFinished);
  const [order, setOrder] = useRecoilState(orderState);
  const [timeLeft, setTimeleft] = useState<{
    minutes: number;
    seconds: number;
  }>(calculateTimeLeft(props.rentalStartDate));
  const validImages = ValidateImages(
    props.order.carRenterImagesBeforeRentalStart
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeleft(calculateTimeLeft(props.rentalStartDate));
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    const showTimer = ShowTimer(closedOrderDate);
    if (!showTimer) {
      setFinishedTimer(true);
    }
  }, [timeLeft, closedOrderDate, setFinishedTimer, props]);

  const finishTimer = async () => {
    if(!validImages) return;
    const res = await Resources.order.beforeRentalFinished(props?.order?.id);
    const _order = {...order};
    const data = await Resources.response.json(res);
    _order.imagesFinished = data;

    setOrder(_order);
    setFinishedTimer(true);
  }

  return (
    <Container>
      <TextContainer>
        <Title>{`Legg inn bilder av bilen og evt.\nskader innen 15 min.`}</Title>
        <Description>
          Du kan bli belastet for skader som ikke er registrert.
        </Description>
      </TextContainer>
      <Timer>
        <TimerLabel>Tid som gjenstår:</TimerLabel>
        <TimerText>
          {`${timeLeft.minutes}:${
            timeLeft.seconds < 10 ? "0" + timeLeft.seconds : timeLeft.seconds
          }`}
        </TimerText>
        <Button
          darkcolor={false}
          onClick={finishTimer}
          disabled={!validImages}
        >
          Fullført
        </Button>
      </Timer>
    </Container>
  );
}

export default DamageTimer;

export const Title = styled.div`
  color: ${(props) => props.theme.colors.mainGreen};
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.superBold};
  margin-bottom: ${(props) => props.theme.margins.md};
  white-space: pre-wrap;
`;

export const Description = styled.div`
  font-size: ${(props) => props.theme.fontSizes.sm};
  font-weight: ${(props) => props.theme.fontWeights.superBold};
`;

export const Timer = styled.div`
  margin-right: ${(props) => props.theme.margins.md};
  width: 120px;
`;

export const TimerText = styled.div`
  text-align: center;
  background: white;
  height: 32px;
  line-height: 32px;
  margin-bottom: ${(props) => props.theme.margins.md};
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.superBold};
  border-radius: 6px;
`;

export const TextContainer = styled.div`
  margin-left: ${(props) => props.theme.margins.lg};
`;
export const TimerLabel = styled.div`
  font-size: ${(props) => props.theme.fontSizes.xs};
  font-weight: ${(props) => props.theme.fontWeights.superBold};
  margin-bottom: ${(props) => props.theme.margins.sm};
  color: ${(props) => props.theme.colors.mainGreen};
`;

export const Container = styled.div`
  padding: 20px 0px;
  gap: ${(props) => props.theme.margins.md};
  margin-top: 70px;
  background: ${(props) => props.theme.colors.lightGreen};
  border: none;
  height: 139px;
  z-index: 200;
  display: block;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: auto auto;
  @media (max-width: 900px) {
    width: 100%;
    border-radius: 0;
    bottom: 0;
    left: 0;
  }
  @media (min-width: 900px) {
    margin-top: ${(props) => props.theme.margins.lg};
    bottom: 0px;
  }
`;
