import { useState, useEffect } from "react";
import { CarType, CarTypeExtra, IncludeType, Order } from "./typings";
import { insurancePackage } from "./staticData";

const Months = [
  "Januar",
  "Februar",
  "Mars",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Desember",
];

const Days = [
	"Søndag",
  "Mandag",
	"Tirsdag",
	"Onsdag",	
	"Torsdag",
	"Fredag",	
	"Lørdag",	
];

export const formatDate = (datestring: string) => {
  const date = new Date(datestring);
  return `${Days[date.getDay()]}. ${date.getDate()} ${Months[date.getMonth()]} ${date.getFullYear()}`;
};

export const ShowTimer = (closedOrderDate: Date | undefined) => {
  if (!closedOrderDate) {
    return false;
  }
  const now = new Date();
  return closedOrderDate >= now;
};

export const ValidateImages = (imageIds?: string[]) => {
  if (!imageIds) {
    return false;
  }
  if (imageIds.length < 8) {
    return false;
  }

  const onlyRequiredImages = imageIds.slice(6);

  return onlyRequiredImages.every((img) => img !== "");
};

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const getAllIncludedExtras = (carTypeExtra: CarTypeExtra | null) => {
  const includedExtraAccessories = carTypeExtra?.extraAccessories.filter(
    (ea) => ea.includeType === IncludeType.IncludedByDefault
  );

  const includedExtraInsurances = carTypeExtra?.extraInsurances.filter(
    (ea) => ea.includeType === IncludeType.IncludedByDefault
  );
  const includedExtraInsurancePackages = carTypeExtra?.extraInsurancesPackages.filter(
    (ea) => ea.includeType === IncludeType.IncludedByDefault
  );

  const allIncluedExtras = [
    ...(includedExtraAccessories ? includedExtraAccessories : []),
    ...(includedExtraInsurances ? includedExtraInsurances : []),
    ...(includedExtraInsurancePackages ? includedExtraInsurancePackages : []),
  ];

  return allIncluedExtras;
};

export const downloadFile = (fileUrl: string) => {
  fetch(fileUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
    },
  })
  .then((response) => response.blob())
  .then((blob) => {
    const fileName = `InsurancePolicy.pdf`;
    // Create blob link to download
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      fileName,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link?.parentNode?.removeChild(link);
  });
}

export const getNumberOfDays = (startDate: string | Date,  endDate: string | Date) => {
	const date1 = new Date(startDate);
  const date2 = new Date(endDate);
      
  const diff = (date2).valueOf() - (date1).valueOf();
      
  return Math.round(Math.abs(diff / (1000 * 3600 * 24))) + 1; //+1 to include corner dates.
}

export const getInsurancePrice = (order: Order, totalPrice: number) => {
    // @ts-ignore
  const days = getNumberOfDays(order.timeStamp_start_planned, order.timeStamp_end_planned);
  return (totalPrice/days) || 0;
}

export const getInsuranceTypeByPrice = (price: number) => {
  return insurancePackage.find(p => p.value === price)?.label || 0;
}

export const getCarTypeTotalPrice = (order: Order, carType: CarType) => {
  const carTypePrice = getCarTypePrice(order, carType) || 0;
  const days = getNumberOfDays(
    // @ts-ignore
    order.timeStamp_start_planned,
    order.timeStamp_end_planned
  );
  return days * carTypePrice;
};

export const getCarTypePrice = (order: Order, carType: CarType) => {
  const initCarType = order?.carType_initial;
  if (!carType || !initCarType) return;
  const carTypePrice =
    carType.id === initCarType.id
      ? 0
      : carType.price - (initCarType?.price || 0);

  return carTypePrice;
};