import React from "react";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import {
  orderState,
  additionalDriverDrivingLicenseFront,
  additionalDriverDrivingLicenseBack,
} from "../../atoms";
import Resources from "./../../resources/Resources";
import ImageLoader from "./../Base/ImageLoader";

interface Props {
  additionalDriver?: boolean;
}

const Container = styled.div`
  display: flex;
  align-items: flex-end;
`;

const ImageHolder = styled.div`
  width: 145px;
  height: 145px;
  margin-right: 15px;
`;

const CardImage = ({ src, alt }: { src: string; alt: string }) => {
  return (
    <ImageHolder>
      <ImageLoader
        src={Resources.file.src(src as string, 145, 145)}
        alt={alt}
      />
    </ImageHolder>
  );
};

function ShowDrivingLicense({ additionalDriver }: Props) {
  const forsideImageIdAdditionalDriver = useRecoilValue(
    additionalDriverDrivingLicenseFront
  );
  const baksideImageIdAdditionalDriver = useRecoilValue(
    additionalDriverDrivingLicenseBack
  );
  const order = useRecoilValue(orderState);
  const forsideImageId = additionalDriver
    ? forsideImageIdAdditionalDriver
    : order.order_customer?.driving_license_photo[0];
  const baksideImageId = additionalDriver
    ? baksideImageIdAdditionalDriver
    : order.order_customer?.driving_license_photo[1];

  return (
    <Container>
      {forsideImageId && (
        <CardImage src={forsideImageId} alt={"Førerkort forside"} />
      )}
      {baksideImageId && (
        <CardImage src={baksideImageId} alt={"Førerkort bakside"} />
      )}
    </Container>
  );
}

export default ShowDrivingLicense;
