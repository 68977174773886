import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Order } from "../../typings";
import { insurancePrice } from "../../atoms";
import { getNumberOfDays } from "../../utils";
import Section from "../View/Section";
import styled from "styled-components";
import arrowDown from "../../assets/icons/arrow_down.svg";
import arrowUp from "../../assets/icons/arrow_up.svg";
import RadioButton from "../RadioButton/RadioButton";
import pdfIcon from "../../assets/icons/pdf_icon.png";

import { Link } from "react-router-dom";
import PolicyDescription from "./PolicyDescription";
import { insurancePackage } from "../../staticData";

interface Props {
  order: Order;
}

function InsurancePolicy({ order }: Props) {
  const [showMore, toggleShowMore] = useState(false);
  const [insurancePriceState, setInsurancePrice] =
    useRecoilState(insurancePrice);


  const getPrice = (price: number) => {
    if (price) {
      return (
        <Price>
          {price} NOK<SubText>per dag</SubText>
        </Price>
      );
    }
    return <Price>Inkludert</Price>;
  };

  const getLabel = (value: string) => {
    let initial = `Forsikring med `;
    let end = `,- i redusert egenandel`;

    if (value === "12.000") {
      initial = `Standard forsikring med `;
      end = `,- i egenandel`;
    }
    return (
      <div>
        {initial}
        <b>{value}</b>
        {end}
      </div>
    );
  };

  const numberOfDays = getNumberOfDays(
    // @ts-ignore
    order.timeStamp_start_planned,
    order.timeStamp_end_planned
  );
  return (
    <Section header="Forsikringer" expandable={false}>
      <Subtitle>Velg forsikring for å redusere egenandel ved skade.</Subtitle>
      <SelectionContainer>
        {insurancePackage.map((data) => (
            <RadioButtonContainer key={data.value}>
              <div>
                <RadioButton
                  checked={data.label === insurancePriceState.type}
                  label={getLabel(data.label)}
                  onChange={() => {
                    setInsurancePrice({
                      type: data.label,
                      total: numberOfDays * data.value,
                      pricePerDay: data.value
                    });
                  }}
                />

                <Link
                  target="_blank"
                  to={`/InsurancePolicy${data.value}.pdf`}
                  download={`Greenpool Forsikringsbevis_egenandel ${data.label}.pdf`}
                >
                  <LinkContainer>
                    <Icon src={pdfIcon} />
                    Les forsikringsbevis
                  </LinkContainer>
                </Link>
              </div>
              {getPrice(data.value)}
            </RadioButtonContainer>
        ))}
      </SelectionContainer>

      <Description>
        Leietaker er ansvarlig for å betale egenandelen ved skade. Hvis skadens
        kostnad er mindre enn egenandel betaler leietaker kun If Skadeforsikring
        sine kostnader knyttet til skade. Ved skifte av glass er egenandelen 2 500 kr.
      </Description>

      {showMore ? <PolicyDescription /> : null}
      <MoreInfo onClick={() => toggleShowMore(!showMore)}>
        {showMore ? "LUKK" : "MER INFO"}{" "}
        <Arrow src={showMore ? arrowUp : arrowDown} alt="arrow down" />
      </MoreInfo>

      <Ruler />
      <EstimateContainer>
        <Estimate>Estimert beløp (med redusert egenandel)</Estimate>
        <EstimateCost>{`${insurancePriceState.total || 0} NOK`}</EstimateCost>
      </EstimateContainer>
    </Section>
  );
}

const EstimateContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
`;

const Ruler = styled.div`
  width: 100%;
  border-style: solid;
  border-width: 0.5px;
  margin: 20px 0px;
  border-color: ${(props) => props.theme.colors.darkGreen};
`;

const Estimate = styled.div`
  font-size: ${(props) => props.theme.fontSizes.xs};
`;

const EstimateCost = styled.div`
  font-size: ${(props) => props.theme.fontSizes.xs};
  font-weight: ${(props) => props.theme.fontWeights.superBold};
`;

const Arrow = styled.img`
  width: 10px;
`;

const MoreInfo = styled.div`
  font-size: ${(props) => props.theme.fontSizes.sm};
  color: ${(props) => props.theme.colors.mainGreen};
  text-decoration: underline;
  display: flex;
  align-items: center;
  align-self: self-end;
  gap: 5px;
  width: 160px;
  justify-content: flex-end;
  cursor: pointer;
  // position: absolute;
  // right: 5%;
  margin-bottom: -12px;
  width: fit-content;
  padding: 10px;
`;

const Description = styled.div`
  font-size: ${(props) => props.theme.fontSizes.xs};
  text-align: center;
  padding: 10px 15px;
  margin: 10px 0px;
  background: ${(props) => props.theme.colors.lightGreen};
`;

const LinkContainer = styled.div`
  color: ${(props) => props.theme.colors.cyan};
  font-size: ${(props) => props.theme.fontSizes.xxs};
  text-decoration: underline;
  margin-left: 17px;
  display: flex;
  align-items: flex-end;
  gap: 3px;
  margin-top: 5px;
`;
const Icon = styled.img`
  width: 8px;
`;

const Subtitle = styled.div`
  font-size: ${(props) => props.theme.fontSizes.sm};
  margin: 14px auto 22px auto;
`;

const RadioButtonContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: center;
`;

const SelectionContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  @media screen and (max-width: 786px) {
    width: 95%;
  }
`;

const Price = styled.div`
  font-size: ${(props) => props.theme.fontSizes.xxs};
  display: flex;
  align-items: flex-end;
  gap: 3px;
  background-color: ${(props) => props.theme.colors.lightGrey};
  padding: 5px;
  width: 170px;
  border-radius: 2px;
  justify-content: center;
  font-weight: ${(props) => props.theme.fontWeights.superBold};

  @media screen and (max-width: 765px) {
    width: 70px;
  }
`;

const SubText = styled.div`
  font-size: ${(props) => props.theme.fontSizes.xxs};
  font-weight: ${(props) => props.theme.fontWeights.normal};
`;

export default InsurancePolicy;
