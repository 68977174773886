import React from "react";
import { Link, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { orderState } from "../atoms";
import { formatDate } from "../utils";
import styled from "styled-components";
import Modal from "./View/Modal";
import Button from "./View/Button";
import check from "../assets/icons/circle-check-dark.svg";

const BekreftMessage = styled.div`
  text-align: center;
`;

const Header = styled.h1`
  font-size: ${(props) => props.theme.fontSizes.xl};
  font-weight: ${(props) => props.theme.fontWeights.superBold};
`;

const Description = styled.div`
  font-size: ${(props) => props.theme.fontSizes.lg};
  font-weight: ${(props) => props.theme.fontWeights.lighter};
  margin-bottom: 32px;
  white-space: pre-wrap;
`;

const LinkContainer = styled.div`
  font-size: ${(props) => props.theme.fontSizes.lg};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  a:link {
    color: black;
  }
  a:visited {
    color: black;
  }
  cursor: hover;
`;

const ButtonContainer = styled.div`
  max-width: 136px;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
`;
const BolderHeading = styled.div`
  font-size: ${(props) => props.theme.fontSizes.xl};
  font-weight: ${(props) => props.theme.fontWeights.superBold};
`;

const Checkmark = styled.img`
  width: 80px;
  height: 80px;
  margin: 20px 0px;

  @media screen and (max-width: 786px) {
    width: 50px;
    height: 50px;
  }
`;

interface Props {
  showModal: boolean;
  toggleModal: (state: boolean) => void;
}

function ConfirmMessageModal({ showModal, toggleModal }: Props) {
  const order = useRecoilValue(orderState);
  const param = useParams<{ link: string }>();
  const pickupLocation = order.pickup_location?.entered_value
    ? order.pickup_location?.entered_value
    : (order.pickup_location?.selected_name as string);

  return (
    <Modal isOpen={showModal} header={true} setIsOpen={toggleModal}>
      <BekreftMessage>
        <Header>{"Ditt leieforhold er bekreftet"}</Header>
        <Checkmark
                  src={check}
                  alt="Checkbox"
                  className="checkbox"
                />
        <Description>
          {`Ditt leieforhold er nå bekreftet og leiebilen vil være klar for henting`}
        </Description>
        <BolderHeading>
          {`${formatDate(order.timeStamp_start_planned as string)}`}
        </BolderHeading>
        <Description>{`\nhos`}</Description>
        <BolderHeading>{`${pickupLocation}`}</BolderHeading>

        {/* <LinkContainer>
          <Link to={`/links/${param.link}`}>Gå til leieforhold</Link>
        </LinkContainer> */}
        <ButtonContainer>
          <Button onClick={() => toggleModal(false)} darkcolor={false}>
            Ok
          </Button>
        </ButtonContainer>
      </BekreftMessage>
    </Modal>
  );
}

export default ConfirmMessageModal;
