import React from "react";
import styled from "styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import DrivingLicensePhoto from "../Photo/DrivingLicensePhoto";
import Resources from "../../resources/Resources";
import { OrderCustomer, Driver } from "../../typings";
import {
  orderState,
  clickedDisabledBekreft,
  phoneNumberAdditionalDriver,
  additionalDriverDrivingLicenseFront,
  additionalDriverDrivingLicenseBack,
  additionalDriver,
} from "../../atoms";

const ExtraDriverContainer = styled.div`
  margin-top: ${(props) => props.theme.margins.xl};
  background: ${(props) => props.theme.colors.lightGreen};
  width: 100%;
  padding: 20px 0px;
`;

const ExtraDriverPhoneNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${(props) => props.theme.fontSizes.sm};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  width: 80%;
  margin: 0 auto;
  gap: 10px;

  @media screen and (max-width: 786px) {
    width: 70%;
  }
`;

const Description = styled.div<{ missingValidation: boolean }>`
  color: ${(props) =>
    props.missingValidation ? props.theme.colors.signalRed : "default"};
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.lighter};
  margin-bottom: ${(props) => props.theme.margins.md};
  text-align: center;
`;

const PhoneNumberInput = styled.input`
  border: none;
  background-color: ${(props) => props.theme.colors.backgroundGrey};
  height: 40px;
  width: 40%;
  padding: 0px 10px;
  border-radius: 4px;
  font-size: ${props => props.theme.fontSizes.sm};

  @media screen and (max-width: 786px) {
    height: 30px;
    width: 92%;
  }
`;

const ImageWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  margin-top: ${(props) => props.theme.margins.md};
  align-items: center;
  gap: 50px;
  @media screen and (max-width: 786px) {
    gap: 10px;
  }
`;

const PhoneValidation = styled.div`
  color: ${(props) => props.theme.colors.signalRed};
  font-size: ${(props) => props.theme.fontSizes.sm};
  font-weight: ${(props) => props.theme.fontWeights.normal};
`;

function RegisterExtraDriver() {
  const [order, setOrder] = useRecoilState(orderState);
  const phoneNumber = useRecoilValue(phoneNumberAdditionalDriver);
  const isDisabledBekreftClicked = useRecoilValue(clickedDisabledBekreft);
  const forsideImageId = useRecoilValue(additionalDriverDrivingLicenseFront);
  const baksideImageId = useRecoilValue(additionalDriverDrivingLicenseBack);
  const extraDriver = useRecoilValue(additionalDriver);

  const handleFileInput = (file: File, index: number) => {
    const orderCustomer = order.order_customer as OrderCustomer;
    file &&
      Resources.file.upload
        .picture(file)
        .then(Resources.response.json)
        .then(
          (data: any) => {
            let photosArr = (order?.order_customer?.additional_driver && order?.order_customer?.additional_driver[0]?.driving_license_photo && [...order?.order_customer?.additional_driver[0]?.driving_license_photo]) || [];
            photosArr[index] = data.id;
            const newOrder = {
              ...order,
              order_customer: {
                ...orderCustomer,
                additional_driver: [
                  {
                    ...(extraDriver as Driver),
                    driving_license_photo: [...photosArr],
                  },
                ],
              },
            };
            setOrder(newOrder);
          },
          (error) => {
            console.error(error);
          }
        );
  };
  return (
    <ExtraDriverContainer>
      <Description
        missingValidation={
          isDisabledBekreftClicked && (!forsideImageId || !baksideImageId)
        }
      >
        Førerkort ekstra fører
      </Description>

      <ExtraDriverPhoneNumberContainer>
        <label htmlFor="phoneNumber"></label>
        <PhoneNumberInput
          placeholder="Tlf.nr. til ekstra fører"
          onBlur={(evt) => {
            const orderCustomer = order.order_customer as OrderCustomer;
            const newOrder = {
              ...order,
              order_customer: {
                ...orderCustomer,
                additional_driver: [
                  {
                    ...(extraDriver as Driver),
                    phones: [evt.target.value],
                  },
                ],
              },
            };
            setOrder(newOrder);
          }}
          type="tel"
          id="phoneNumber"
          name="phoneNumber"
        />
      </ExtraDriverPhoneNumberContainer>
      {isDisabledBekreftClicked && !(phoneNumber && phoneNumber.length > 0) ? (
        <PhoneValidation>
          Vi må ha tlf.nr. til alle førere av bilen
        </PhoneValidation>
      ) : null}
      <ImageWrap>
        <DrivingLicensePhoto
          front={true}
          onOpenFile={(file: File) => handleFileInput(file, 0)}
          imageId={
            order?.order_customer &&
            order?.order_customer?.additional_driver &&
            order?.order_customer?.additional_driver?.length > 0
              && order?.order_customer?.additional_driver[0]
              ?.driving_license_photo?.length > 0 ?  order?.order_customer?.additional_driver[0]
                  ?.driving_license_photo[0]
              : forsideImageId
          }
        />
        <DrivingLicensePhoto
          front={false}
          onOpenFile={(file: File) => handleFileInput(file, 1)}
          imageId={
            order?.order_customer &&
            order?.order_customer?.additional_driver &&
            order?.order_customer?.additional_driver?.length > 0
            && order?.order_customer?.additional_driver[0]
            ?.driving_license_photo?.length > 0 ?  order?.order_customer?.additional_driver[0]
                ?.driving_license_photo[1]
              : baksideImageId
          }
        />
      </ImageWrap>
    </ExtraDriverContainer>
  );
}

export default RegisterExtraDriver;
