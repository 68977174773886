import React from "react";
import spinner_background from "../../assets/icons/spinner_background.svg";
import spinner_car from "../../assets/icons/spinner_car.svg";
import styled, { keyframes } from "styled-components";

const move = keyframes`
  0% {
    transform: translateX(-40px);
  }
  100% {
    transform: translateX(300px);
  }
`;

const Background = styled.img`
  grid-area: first;
`;

const Car = styled.img`
  grid-area: first;
  height: 15%;
  animation: ${move} 2s linear;
  animation-iteration-count: infinite;
`;

const Container = styled.div<{ marginTop?: number }>`
  display: grid;
  grid-template-areas: "first";
  align-items: end;
  margin: auto;
  width: 300px;
  margin-top: ${(props) => props.marginTop ?? 200}px;
  overflow: hidden;
`;

function Spinner(props: { marginTop?: number }) {
  return (
    <Container marginTop={props.marginTop}>
      <Background src={spinner_background} /> <Car src={spinner_car} />
    </Container>
  );
}

export default Spinner;
