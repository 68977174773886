import React, { useMemo, forwardRef, useImperativeHandle } from "react";
import { Step, Stepper } from "react-form-stepper";

interface IProps {
  securedSteps?: number[];
  handleSubmitNext?: () => void;  
  activeStep: number;
  steps: number;
  className?: any;
  children?: React.ReactNode;
  handleActiveStep: Function;
}

export type IRef = {
  changeStep: (key?: number) => void;
}

const StepperWrapper = forwardRef<IRef, IProps>((props, ref) => {
  const {
    securedSteps,
    handleSubmitNext,
    steps,
    activeStep,
    handleActiveStep,
    className,
    ...rest
  } = props;

  useImperativeHandle(ref, () => ({ changeStep }))

  const renderSteps = useMemo(() => {
    const nodes = [];
    for (let i = 0; i < steps; i++) {
      nodes.push(
        <Step
          index={i}
          onClick={() => changeStep(i)}
          key={"Step" + i}
          style={i > 0 && activeStep < i ? { backgroundColor: "white" } : {}}
        />
      );
    }
    return nodes;
  }, [steps, activeStep, securedSteps]);

  const finalstep = steps - 1;

  const changeStep = (key?: number): void => {
    const newKey: number = key ?? activeStep + 1;
    if (securedSteps?.includes(newKey)) return;

    if (typeof key === "number") return handleActiveStep(key);

    if (activeStep < finalstep) handleActiveStep((state: number) => state + 1);
    else handleSubmitNext?.();
  };
  return (
    <>
      <Stepper activeStep={activeStep} className={`renter-stepper ${className}`} {...rest} nonLinear>
        {renderSteps}
      </Stepper>
      {props.children}
    </>
  );
});

export default React.memo(StepperWrapper);
