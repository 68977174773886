import React, { Fragment } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import Modal from "../View/Modal";
import TermsAndConditionsSummary from "./TermsAndConditionsSummary";
import ButtonLink from "../View/ButtonLink";
import { rentalTermsModalOpened } from "../../atoms";

interface Props {
  linktitle: string;
  showSummaryVilkar: boolean;
}

const Header = styled.span`
  font-size: ${(props) => props.theme.fontSizes.xs};
  font-weight: ${(props) => props.theme.fontWeights.normal};
`;

const Content = styled.div`
  font-size: ${(props) => props.theme.fontSizes.xs};
  font-weight: ${(props) => props.theme.fontWeights.lighter};
`;

function TermsAndConditionsModal(props: Props) {
  const [modalIsOpen, setIsOpen] = useRecoilState(rentalTermsModalOpened);

  return (
    <Fragment>
      <Modal
        isOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        header={true}
        headerTitle="Leievilkår"
      >
        <Fragment>
          <div style={{ marginTop: "20px" }}>
            {props.showSummaryVilkar ? <TermsAndConditionsSummary /> : null}
          </div>
          <Content>
            Norges bilutleieforbunds leievilkår er utarbeidet i samarbeid med NHO Reiseliv. Kontraktsvilkårene er
            utarbeidet i samarbeid med Forbrukerombudet For leieavtale vedrørende nedenfor omtalte motorkjøretøy
            («Kjøretøyet») mellom Utleier («Utleier») og leier («Leietaker») gjelder følgende vilkår:
            <br />
            <br />
            <Header>1. Leieforholdets varighet</Header>
            <br />
            Leietaker skal tilbakelevere Kjøretøyet til Utleiers stasjon som er spesifisert av denne digitale kontrakten. Kjøretøyet skal tilbakeleveres på det avtalte tidspunkt. Levering anses for å ha skjedd når Kjøretøyet er returnert Utleier og nøkkel overlevert Utleier innenfor utleiestasjonens ordinære åpningstider, med mindre annet er skriftlig avtalt mellom Utleier og Leietaker. Såfremt kjøretøyet returneres og nøkler overleveres Utleier utenfor utleiestasjonens ordinære åpningstider, anses levering først når bil har funnet sted den påfølgende virkedag, med mindre annet er skriftlig avtalt mellom Utleier og Leietaker. Utleier kan dersom det foreligger saklig grunn, forlange kjøretøyet tilbakelevert på et tidligere tidspunkt enn det avtalte. Etter anmodning fra Leietaker skal grunn oppgis, dog slik at Leietaker plikter å levere tilbake bilen uavhengig av dennes vurdering av grunngivelsen. Leieforholdet kan forlenges etter forutgående avtale med Utleier.
            <br />
            <br />
            Kjøretøyet skal tilbakeleveres i samme stand som og med alt tilbehør og utstyr som Kjøretøyet var utstyrt med ved leieforholdets inntreden. Kjøretøyet skal tilbakeleveres i rengjort tilstand eller minst i den tilstanden Kjøretøyet ble levert. Ved tilbakelevering av ikke rengjort kjøretøy, forutsettes det at utleier gis rimelig tid til vask for endelig skadekontroll. Det kan påløpe rengjøringsgebyr på kr. 500,- for manglende rengjøring.
            <br />
            <br />
            Elektriske kjøretøy skal leveres med minimum samme batterinivå som ved henting av kjøretøyet. Returneres Kjøretøyet med mindre batterinivå enn ved utlevering, vil Leietaker måtte betale et gebyr på kr. 399,-.
            <br />
            <br />
            OBS! Røyking er ikke tillatt i bilen. Hvis det røykes i bilen tilkommer det et ekstra gebyr på kr. 4500,- eks mva for rens.
            <br />
            <br />
            <Header>2. Oppgjør for påløpt vederlag under leieforholdet</Header>
            <br />
            Leietaker aksepterer at Utleier kan belaste ham for de tjenester Leietaker har signert for, og for de belastninger som kan omfattes av den digitale kontrakt jfr. pkt. 3., enten ved kredittkort, debetkort eller ved alminnelig fakturering. 
            Ved for sen betaling beregnes renter i henhold til regnskapsloven,
            Leietaker kan velge å betale kontant ved tilbakelevering av bilen, men Utleier har alltid rett til å kreve
            at Leietaker benytter kredittkort eller debetkort ved bestilling av bilen og at det blir foretatt
            en reservasjon av beløp for leieforholdet ved utlevering av bilen, som ikke skal overstige de estimerte
            leiekostnadene. Utleier kan kreve annen relevant sikkerhet i form av digital autentisering og bekreftelse
            av debet/kredittkort.
            <br /> <br />
            Utleier kan etterbelaste Leietakers bankkonto vedrørende forhåndsavtalt fastavgift, samt krav forbundet
            med Utleiers kilometeravgift og forbrukt drivstoff, forsikring og beløp som Leietaker md betale for evt
            redusert egenandelen, utgifter til tilbaketransport ved mislighold eller levering av bilen til annet sted
            enn avtalt, innkrevningsgebyr og forsinkelsesrenter. Utleier kan også etterbelaste Leietakers bankkonto
            med krav som refererer seg til offentlige bøter, parkeringsgebyr, gebyr for ubetalt bompengepassering,
            piggdekkavgift og tilsvarende offentlige gebyrer der bileier (bilutleier) hefter solidarisk med bilfører.
            <br /> <br />
            Utleier skal kunne belaste kontoen direkte via et debet- eller kredittkort når leietaker har bekreftet den sum som skal betales enten ved at han/hun har benyttet autentisering eller ved at han/hun bekreftet leieforholdet digitalt. Tilsvarende gjelder dersom Leietaker bekreftet inngåelse av leieforholdet som start av denne kontrakten. Etterbelastning kan også skje direkte på det oppgitte betalingskortet ved hurtigutsjekking i forbindelse med tilbakelevering av bilen.
            <br /> <br />
            Dersom leietaker ikke ved bruk av autentisering eller signatur har godkjent belastningen eller beløp reservert på betalingskortet, skal det sendes et forhåndsvarsel til leietaker/kortholder før belastning.
            <br /> <br />
            Øvrige krav, så som eksempelvis belastning for skader pd leiebilen, faktureres eller belastes Leietaker på alminnelig måte.
            <br /> <br />
            Dersom leietaker bor utenfor Norge, kan enhver belastning skje uten forhåndsvarsel. I disse tilfeller kan også krav beskrevet i avsnitt 6 under dette pkt. etterbelastes leietakers debetkort.
            <br /> <br />
            <Header> 3. Vederlag mv påløpt i leieforholdet</Header>
            <br />
            Leietaker plikter å betale følgende:
            <br />
            Leieavgift, avgiftene for ytterligere ansvarsbegrensning (egenandel), personlig ulykkesforsikring og tilleggskrav påført leiekontrakten.
            <br /> <br />
            Utleiers kilometeravgifter etter de satser som er påført leiekontrakten for Kjøretøyets kjørte kilometer i leietiden. Skulle kilometerteller på Kjøretøyet ikke fungere, beregnes kilometeravgift i henhold til den strekning som det fremgår av veikartet at Kjøretøyet har tilbakelagt. Forbrukt drivstoff jfr. pkt. 1 ovenfor. Eventuelle utgifter for tilbaketransport av Kjøretøyet til utleiestasjon i henhold til leiekontrakten. Etterlates Kjøretøyet uten Utleiers skriftlige samtykke på et annet sted enn utleiestasjonen, belastes en avgift for tid og utgifter i forbindelse med tilbakefrakt av Kjøretøyet.
            <br /><br />
            Samtlige avgifter, gebyrer, bøter og utgifter som påløper på. grunn av Leietakers forhold forut for tilbakelevering av bilen i henhold til disse vilkårs pkt. 1., samt administrasjonsgebyr på kr 189 ved etterfakturering vil påløpe.
            <br /> <br />
            Egenandel i tilfelle skade eller tyveri. Eller ansvar for skade eller tyveri som helt eller delvis ikke dekkes av egenandelen.
            <br /> <br />
            Utleiers utgifter til inndrivelse av Leietakers forpliktelser, herunder de gebyrer lovgivningen til enhver tid tillater ved inndrivelse av slike beløp.
            <br /> <br />
            Ved forsinket betaling påløper forsinkelsesrenter etter lov om renter ved forsinket betaling av 17 desember nr. 100 1976, pt. 12% p.a, fra forfall og til betaling skjer.
            <br /> <br />
            Eventuelle offentlige gebyrer.
            <br /> <br />
            <Header>4. Utleiers plikter</Header>
            <br />
            Utleier plikter til avtalt tid og på avtalt sted å stille kjøretøyet til Leietakers rådighet. Kjøretøyet skal være i god stand og inneholde minst 20 liter drivstoff eller 80% oppladede batterier for elektriske biler. Leietaker plikter så langt det lar seg gjøre å undersøke kjøretøyet umiddelbart etter at dette er stillet til Leietakers rådighet.
            <br /> <br />
            Leietaker taper sin rett til å gjøre mislighold, det være seg mangel eller forsinkelse, gjeldende dersom han/hun ikke innen rimelig tid etter han/hun har oppdaget eller burde ha oppdaget misligholdet gir Utleier melding som angir hva slags mislighold det gjelder.
            <br /> <br />
            Dersom Utleier ikke oppfyller sine plikter etter avtalen kan Leietaker kreve at Utleier for egen regning retter mangelen dersom dette kan skje uten å volde Utleier urimelig kostnad eller ulempe. Selv om Leietakeren ikke krever det kan Utleier for egen kostnad rette mangel eller påta omlevering når dette kan skje uten vesentlig ulempe for Leietakeren.
            <br /> <br />
            Dersom retting eller omlevering ikke kommer på tale eller ikke blir foretatt innen rimelig tid etter at Leietakeren har klaget over mangelen, kan Leietakeren kreve forholdsmessig prisavslag. I tilfelle vesentlig mislighold kan Leietaker heve leiekontrakten.
            <br /> <br />
            Leietakeren kan ikke heve kjøpet med mindre han/hun gir Utleier melding om heving innen rimelig tid etter han/hun fikk. eller burde ha fått kjennskap til misligholdet.
            <br /> <br />
            Leietaker kan videre kreve erstatning for det han/hun lider som følge av misligholdet, for så vidt Utleier ikke godtgjør at misligholdet eller årsaken til dette skyldes hindring utenfor hans /hennes kontroll som han/hun ikke med rimelighet kunne ventes å ta i betraktning på avtaletiden eller å unngå eller overvinne følgene av. Erstatning for indirekte tap, så som driftsavbrudd, avsavn, tapt fortjeneste som følge av at en kontrakt med tredjemann faller bort eller ikke blir riktig oppfylt, eller tap som følge av skade på annet enn utleiebilene, jfr. kriteriene som brukes i kjøpslovens§ 67, kan kun kreves dersom Utleier har utvist grov uaktsomhet eller forsett. Utleier plikter å tegne ansvarsforsikring for Kjøretøyet i samsvar med bilansvarslovens regler.
            <br /> <br />
            <Header>5. Leietakers vedlikeholdsplikt</Header>
            <br />
            Leietaker har overtatt Kjøretøyet i god stand jfr. pkt. 4, og skal i leieperioden sørge for at Kjøretøyet blir vedlikeholdt med tanke på smøreoljer, spylevæske og lufttrykk i dekk og lignende.
            <br /> <br />
            <Header>6. Leietakers behandling og bruk av Kjøretøyet</Header>
            <br />
            Leietaker plikter å behandle og bruke Kjøretøyet på forsvarlig måte, og ikke:
            <br />
            - Disponere over Kjøretøyet uten å ha kontrollert at de nødvendige tillatelser for å føre Kjøretøyet besittes hensatt til Kjøretøyets art og dets bruk.
            <br />
            - Ta Kjøretøyet ut av landet uten Utleiers skriftlige samtykke.
            <br />
            - Mot vederlag befordre passasjerer og varer.
            <br />
            - Benytte Kjøretøyet i ulovlig øyemed eller på ulovlig måte; herunder, men ikke begrenset til, følgende eksempler på ulovlig benyttelse:
            <br />
            - Kjøring i alkohol- og/eller narkotikapåvirket og/eller svekket tilstand.
            <br />
            - Leietaker, herunder utenlandske Leietakere, forutsettes å gjøre seg kjent med norsk lovgivning på dette punkt.
            <br />
            - Befordring av flere passasjerer og/eller tyngre last enn Kjøretøyet er registrert for.
            <br />
            - Bryte bilens speedometerplombe.
            <br />
            - Overlate Kjøretøyet til andre eller at det kjøres av annen sjåfør enn godkjent av Utleier.
            <br />
            - Benytte Kjøretøyet til konkurranse, fartsprøve eller annen form for testkjøring.
            <br />
            - Benytte Kjøretøyet til øvelseskjøring
            <br />
            - Slepe, skyve eller flytte annet kjøretøy.
            <br />
            - Forlate Kjøretøyet ulåst eller på en slik måte at det kan benyttes av andre.
            <br />
            - Fylle feil drivstoff.
            <br /><br />
            Leietaker må ikke behandle bilen slik at følgende inntreffer:
            <br />
            - Skader påført Kjøretøyet på grunn av last som har forskjøvet seg, som er dårlig fastgjort eller emballert.
            <br />
            - Brann- og/eller kuttskader i Kjøretøyets setetrekk o.l, og/eller flekker på Kjøretøyets interiør som ikke lar seg fjerne.
            <br />
            - Felgkjøring av dekk.
            <br />
            - Skader oppstått ved nedfall fra hustak hvor det er oppsatt anvisere.
            <br />
            - Skader på Kjøretøyets karosseri/understell som følge av bruk av snøkjettinger.
            <br />
            - Skader på Kjøretøyet som følge av kjøring på fysisk utilgjengelige steder grunnet Kjøretøyets høyde, bredde og/eller lengde.
            <br />
            - Skader som følge av kjøring utenfor offentlig vei eller i område stengt for alminnelig ferdsel, uten forutgående skriftlig aksept fra Utleier.
            <br />
            - Lukt eller merker som følge av røyking i Kjøretøyet
            <br /> <br />
            Dersom Leietaker behandler bilen grovt uaktsomt vil han/hun risikere å måtte dekke Utleiers tap fullt ut.
            <br /> <br />
            <Header>7. Leietakers plikter i tilfelle av skader m.v inntruffet i leieperioden</Header>
            <br />
            Alle skader, tekniske feil eller uhell skal av Leietaker omgående meldes til Utleieren eller representant for denne. Ved tyveri, brann, hærverksskader, personskader eller skader på dyr skal forholdet også alltid meldes til politiet. Leietaker skal ved skadetilfeller utfylle en fullstendig skademelding for motorvogn, og ikke etterlate Kjøretøyet uten forskriftsmessige sikkerhetsforanstaltninger. Skademelding i utfylt stand skal leveres Utleier uten ugrunnet opphold.
            <br /><br />
            Såfremt melding og/eller skademelding ikke gis Utleier i samsvar med ovenstående retningslinjer, er Leietaker ansvarlig for merkostnader som påløper i forbindelse med den inntrufne skade, og som kan tilbakeføres til forsinket eller unnlatt levering av skademelding.
            <br /> <br />
            <Header>8. Leietakers ansvar for skader i leieperioden</Header>
            <br />
            I leietiden og inntil Kjøretøyet er levert tilbake til Utleier på dennes stasjon i samsvar med pkt. 1 i denne avtale, bærer Leietakeren alt ansvar for Kjøretøyet og bruken av dette med mindre det skyldes forhold på utleiers side. Leietakers maksimale egenandel i tilfelle skade eller tyveri fremgår av skjemaet for utleie. Særskilt avtale som reduserer Leietakers egenandel ytterligere kan inngås etter nærmere avtale mellom partene. For skade eller ansvarstilfelle er Leietaker pliktig til å betale egenandelen. Leietakers egenandel forfaller umiddelbart til betaling. Dersom Leietaker har utvist forsett eller grov uaktsomhet, og ikke omstendighetene tilsier at det er grunnlag for nedsettelse av ansvaret (lempning), gjelder ikke begrensningen til egenandelen og Leietaker må i sin helhet dekke skade eller ansvar. I den grad Leietaker har inngått konkret avtale med Utleier, og derfor ikke er dekket av Utleier innenfor egenandelen, plikter Leietaker å tegne forsikring for sitt ansvar for Kjøretøyet og bruken av dette.
            <br /> <br />
            <Header>9. Omfanget av Leietakers ansvar for skader i leieperioden</Header>
            <br />
            Leietaker er økonomisk ansvarlig for avsavnstap og samtlige utgifter som påfaller Utleier i forbindelse med rengjøring, reparasjoner og transport grunnet innvendige og utvendige skader og/eller skadeverk påført Kjøretøyet i leieperioden, begrenset til gjeldende egenandel jfr. Pkt. 8. Skade eller ansvar som helt eller delvis ikke dekkes av Leietakers egenandel, f. eks, fordi det er utvist forsett eller grov uaktsomhet, må i sin helhet dekkes av Leietaker.
            <br /> <br />
            <Header>10. Leietakers avslutning av leieforholdet forut for leieperiodens utløp</Header>
            <br />
            Tekniske mangler Tekniske feil som måtte oppstå eller komme til Leietakers kunnskap i leieperioden, og som kan utbedres innen en tid som står i rimelig forhold til Leietakers behov og leietiden, gir ikke Leietaker rett til å avbryte Leieforholdet jfr. pkt. 4. Om Leietaker likevel velger å avbryte leieforholdet, vil Leietaker være ansvarlig for leien i leieperioden, dog med fradrag av den tid som ville medgått til utbedring. Leietakeren er videre ansvarlig for de utgifter Utleieren blir påført i forbindelse med å få Kjøretøyet brakt tilbake til Utleiers stasjon. Dersom ikke reparasjon kan besørges innenfor den nevnte frist, avgjør Utleieren om han vil levere nytt Kjøretøy til Leietaker for fortsettelse av leieforholdet eller om leieforholdet skal anses som opphørt fra den tid da Utleieren fikk melding om forholdet fra Leietaker. Utleier besørger da Kjøretøyet tilbaketransportert for egen regning, og Utleieren er da uten ansvar for ethvert tap eller ansvar som Leietaker måtte påføres.
            <br /> <br />
            <Header>11. Utleiers ansvar for gjenglemte gjenstander m.v</Header>
            <br />
            Utleier påtar seg intet ansvar for skade på eller tap av eiendom som av Leietaker eller annen person er etterlatt, oppbevart eller befordret i Kjøretøyet i leieperioden, eller etter tilbakelevering til Utleier. Leietaker fritar Utleier for alle krav som på grunn av slike tap eller skader kan gjøres gjeldende mot Utleier. Utleier fraskriver seg ethvert ansvar overfor Leietaker med hensyn til tap av tid, penger eller annet som måtte oppstå av, eller ha forbindelse med leieforholdet, utover ansvar beskrevet i pkt. 4.
            <br /> <br />
            <Header>12. Endringer</Header>
            Krav til skriftligheter Tillegg til og endringer avleievilkårene i denne avtale er bindende bare dersom de foreligger i skriftlig overenskomst.
            <br /> <br />
            <Header>13. Lovvalg</Header>
            <br />
            Denne kontrakt er underlag norsk rett.
            <br />
            <br />
            <Header>14. Verneting</Header>
            <br />
            Leietaker vedtar det av Utleiers forretningssted er hvor kontrakt avsluttet, dvs. kontraktsverneting i henhold til tvistemålsloven §25, som verneting i anledning tvister som oppstår i forbindelse med leieforholdet.
          </Content>
        </Fragment>
      </Modal>
      <ButtonLink onClick={() => setIsOpen(true)}>{props.linktitle}</ButtonLink>
    </Fragment>
  );
}

export default TermsAndConditionsModal;
