import React from "react";
import styled from "styled-components";
import SimpleSpinner from "./SimpleSpinner";

interface ButtonProps {
  notValid?: boolean;
  disabled?: boolean;
  disabledColor?: boolean;
  children: string | JSX.Element | JSX.Element[];
  darkcolor: boolean;
  backgroundColor?: string;
  color?: string;
  onClick: () => void;
  sticky?: boolean;
  fontSize?: string;
  height?: string;
  width?: string;
  loader?: boolean;
}

const ButtonComponent = styled.button<ButtonProps>`
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.disabledColor
      ? props.theme.colors.disableGreen
      : props.darkcolor
      ? props.theme.colors.darkOrange
      : props.theme.colors.mainGreen};
  width: ${(props) => props.width ?? "100%"};
  margin: 0 auto;
  border: none;
  border-radius: 8px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  font-size: ${(props) => props.fontSize ?? props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  height: ${(props) => props.height ?? "40px"};
  color: ${(props) =>
    props.color
      ? props.color
      : props.disabledColor
      ? props.theme.colors.darkGrey
      : props.darkcolor
      ? props.theme.colors.black
      : props.theme.colors.backgroundGrey};

  &:disabled {
    background-color: #c4c4c4;
  }
`;

const StickyButton = styled(ButtonComponent)`
  height: 64px;
  z-index: 200;
  display: block;
  border-radius: 8px 8px 0 0;

  @media (max-width: 900px) {
    position: fixed;
    width: 100%;
    border-radius: 0;
    bottom: 0px;
    left: 0px;
  }
  @media (min-width: 900px) {
    margin-top: ${(props) => props.theme.margins.lg};
    position: -webkit-sticky;
    position: sticky;
    bottom: 0px;
  }
`;

function Button(props: ButtonProps) {
  const ButtonType = props.sticky ? StickyButton : ButtonComponent;
  return <ButtonType {...props}>{props.loader ? <SimpleSpinner /> : props.children}</ButtonType>;
}

export default Button;
