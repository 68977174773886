import React, { useEffect, Fragment, useState, useRef } from "react";
import {
  damageState,
  damageTypesState,
  timerFinished,
  deliverCarBackClicked,
  carState,
  orderState,
} from "../../atoms";
import { useSetRecoilState, useRecoilValue } from "recoil";
import ShowCarPhotos from "../Photo/ShowCarPhotos";
import RentalInformation from "../CustomerAndCarInformation/RentalInformation";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import CustomerInformation from "../CustomerAndCarInformation/CustomerInformation";
import Damages from "../Damage/Damages";
import RoadsideAssistance from "../CustomerAndCarInformation/RoadsideAssistance";
import TimerFinishedModal from "../TimerFinishedModal";
import Button from "../View/Button";
import Resources from "../../resources/Resources";
import { ButtonContainer } from "../../styled-components";
import StepperWrapper from "../View/StepperWrapper";

function DuringRental() {
  const childRef = useRef<{changeStep: () => void}>(null);
  const [activeStep, setActiveStep] = useState(0);
  const setDamage = useSetRecoilState(damageState);
  const setDamageTypes = useSetRecoilState(damageTypesState);
  const finishedTimer = useRecoilValue(timerFinished);
  const userClickedDeliverCarBack = useSetRecoilState(deliverCarBackClicked);
  const isDeliverCarBack = useRecoilValue(deliverCarBackClicked);
  const car = useRecoilValue(carState);
  const order = useRecoilValue(orderState);
  const deliverCarBack = () => userClickedDeliverCarBack(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep])

  useEffect(() => {
    if (car.id) {
      Resources.damage
        .getAllByCarId(car.id)
        .then(Resources.response.json)
        .then(setDamage);
    }
  }, [car.id, setDamage]);

  useEffect(() => {
    Resources.damage
      .getAllDamageTypes()
      .then(Resources.response.json)
      .then(setDamageTypes);
  }, [setDamageTypes]);

  const goBack = () => {
    if (activeStep > 0) setActiveStep((state) => state - 1);
  };

  const submitNext = () => {
    if (activeStep < 5) setActiveStep((state) => state + 1);
  };

  const renderSteps = () => {
    switch (activeStep) {
      case 0:
        return <RoadsideAssistance />;
      case 1:
        return <TermsAndConditions asExpandableContainer={false} />;
      case 2:
        return <Damages noDamagesText="Det er ingen skader registrert." />;
      case 3:
        return <ShowCarPhotos order={order} title={"Bilder av bil"} />;
      case 4:
        return (
          <RentalInformation
            order={order}
            showLeiebiltypeDropdown={false}
            asExpandableContainer={false}
          />
        );
      case 5:
        return <CustomerInformation order={order} showForerkortInfo={true} />;
    }
  };

  return (
    <Fragment>
      <StepperWrapper
        steps={6}
        activeStep={activeStep}
        handleSubmitNext={submitNext}
        handleActiveStep={setActiveStep}
        ref={childRef}
      >
        <>
             {renderSteps()}
      <ButtonContainer>
        {activeStep ? (
          <Button disabled={!activeStep} onClick={goBack} darkcolor={false}>
            Tilbake
          </Button>
        ) : null}
        {activeStep !== 5 ? (
          <Button
          onClick={() => childRef?.current?.changeStep()}
            darkcolor={false}
            disabled={activeStep === 5}
          >
            Neste
          </Button>
        ) : null}
      </ButtonContainer>

      <TimerFinishedModal isOpen={finishedTimer && !isDeliverCarBack} />
      <Button sticky={true} darkcolor={false} onClick={deliverCarBack}>
        Lever tilbake leiebil
      </Button></>
      </StepperWrapper>
    </Fragment>
  );
}

export default DuringRental;
