import styled from "styled-components";

 interface ContainerProps {
  direction?: string;
  padding?: string;
  width?: string;
}

export const Topbar = styled.div`
  font-size: ${(props) => props.theme.fontSizes.md};
  display: flex;
  justify-content: center;
  border-bottom: 3px solid;
  padding: 20px 0px 20px 0px;
  border-bottom-color: ${(props) => props.theme.colors.mainGreen}
`;

export const Heading3 = styled.div`
  font-size: ${(props) => props.theme.fontSizes.lg};
  color: ${(props) => props.theme.colors.black};
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CardContainer = styled.div`
    display: flex;
    padding: ${(props: ContainerProps) => props.padding ?? "5%"};
    justify-content: space-between;
    width: ${(props: ContainerProps) => props.width ?? "80%"};
    border: 1px solid #ECEFE8;
    // box-shadow: 0px 4px 4px #dedfdb;
    border-radius: 10px;
    margin: 0 auto;
    flex-direction: ${(props: ContainerProps) => props.direction ?? "column"};
    `;
    
    export const InfoContainer = styled.div`
    background-color: ${(props) => props.theme.colors.lightGreen};
    margin: 0px auto;
    width: 100%;
    padding: 50px 0px;
    display: flex;
    gap: 4%;
    position: relative;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  margin: 0 auto;
  gap: 40px;
  padding-bottom: 20px;
  margin-top: 30px;

  @media (max-width: 500px) {
    gap: 5px;
    width: 70%;
  }
`;