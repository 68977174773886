import React, { Fragment } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import DrivingLicensePhoto from "../Photo/DrivingLicensePhoto";
import Resources from "../../resources/Resources";
import { orderState } from "../../atoms";
import { OrderCustomer, OrderState } from "../../typings";
import { useParams } from "react-router-dom";
import DriverLicense from "../../assets/icons/license.png"

const ImageWrap = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 50px;
  justify-content: center;
  width: 80%;
  @media screen and (max-width: 786px) {
    gap: 15px;
  }
`;

const Description = styled.div<{ missingValidation: boolean }>`
  color: ${(props) =>
    props.missingValidation ? props.theme.colors.signalRed : "default"};
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.lighter};
  margin-bottom: ${(props) => props.theme.margins.md};
  white-space: pre-wrap;
`;

interface Props {
  missingValidation: boolean;
}

function AddDrivingLicense(props: Props) {
  const param = useParams<{ link: string }>();
  const [order, setOrder] = useRecoilState(orderState);
  const orderConfirmed = order.order_state === OrderState.Confirmed;
  const handleFileInput = (file: File, index: number) => {
    if(orderConfirmed) return;
    const orderCustomer = order.order_customer as OrderCustomer;
    file &&
      Resources.file.upload
        .picture(file)
        .then(Resources.response.json)
        .then(
          (data: any) => {
            const forside =
              index === 0
                ? data.id
                : orderCustomer.driving_license_photo &&
                  orderCustomer.driving_license_photo[0];
            const bakside =
              index === 1
                ? data.id
                : orderCustomer.driving_license_photo &&
                  orderCustomer.driving_license_photo[1];

            const newOrder = {
              ...order,
              order_customer: {
                ...orderCustomer,
                driving_license_photo: [forside, bakside],
              },
            };
            setOrder(newOrder);
            
            void Resources.order.updateDrivingLicenseImages(
              newOrder.order_customer.driving_license_photo,
              param.link ?? ""
            );
          },
          (error) => {
            console.error(error);
          }
        );
  };
  return (
    <Container>
      <TopContainer>
      <ImageContainer>
      <ImageWrapper src={DriverLicense}/>
      </ImageContainer>
      <Description missingValidation={props.missingValidation}>
        {`Vi trenger bilde av forsiden og baksiden til førerkortet ditt. Klikk for å legge til.`}
      </Description>
      </TopContainer>
      <ImageWrap>
        <DrivingLicensePhoto
          front={true}
          onOpenFile={(file: File) => handleFileInput(file, 0)}
          imageId={
            order.order_customer &&
            order.order_customer?.driving_license_photo &&
            order.order_customer?.driving_license_photo[0]
          }
          viewOnly={orderConfirmed}
        />
        <DrivingLicensePhoto
          front={false}
          onOpenFile={(file: File) => handleFileInput(file, 1)}
          imageId={
            order.order_customer &&
            order.order_customer?.driving_license_photo &&
            order.order_customer?.driving_license_photo[1]
          }
          viewOnly={orderConfirmed}
        />
      </ImageWrap>
    </Container>
  );
}

const Container = styled.div`
  align-items: center;
  display: flex;
    flex-direction: column;
    margin: 0 auto;
    background: ${(props)=> props.theme.colors.lightGreen};
    padding: 30px 0px;
    width: 100% 
`
const TopContainer = styled.div`
  display: flex;
  gap: 15px;
  width: 80%;
`;

const ImageContainer = styled.div`
      width: 30%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
`;
const ImageWrapper = styled.img`
width: 150px;
@media screen and (max-width: 786px) {
  width: 60px;
}
  
`;

export default AddDrivingLicense;
