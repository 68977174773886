import React from "react";
import styled from "styled-components";

const SuccessIcon = () => {
    return <IconContainer>
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ ...IconPathStyles }}>
            <circle cx="16" cy="16" r="15.5" fill="#417630" stroke="#417630" />
        </svg>
        <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
            marginTop: '8px',
            marginLeft: '5px',
            ...IconPathStyles
        }}>
            <path d="M10.1925 15.2445C9.81285 15.6587 9.16651 15.6789 8.76175 15.2891L0.964457 7.78059C0.133327 6.98024 1.09409 5.64138 2.11841 6.17251L8.91578 9.69707C9.27032 9.88091 9.70024 9.83617 10.0093 9.58327L20.7029 0.833961C21.6636 0.0480003 22.912 1.36871 22.0733 2.28365L10.1925 15.2445Z" fill="white" />
        </svg>
    </IconContainer >

}

const IconContainer = styled.div`
width:35px;
height:35px;
position:relative;
float: left;
`

const IconPathStyles: React.CSSProperties = {
    position: 'absolute',
}

export default SuccessIcon