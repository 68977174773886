import React, { Fragment } from "react";
import Modal from "../../View/Modal";
import styled from "styled-components";
import { DamageAction } from "./DamageForm.types";
import { Damage } from "../../../typings";
import Button from "../../View/Button";

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  margin: 10%;
  margin-top: 0px;
`;

interface ModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  action: DamageAction;
  submit: () => void;
  damage: Damage | null;
}

const DamageModal = (props: ModalProps) => (
  <Fragment>
    <Modal
      isOpen={props.isOpen}
      header={true}
      setIsOpen={props.setIsOpen}
      maxWidth="350px"
    >
      <Content>
        Er du sikker på at du ønsker å slette den registrerte skaden?
        <br />
        <br />
        <ButtonBar>
          <Button
            darkcolor={false}
            backgroundColor={"#BB4837"}
            color={"#FFFFFF"}
            onClick={props.submit}
          >
            Slett
          </Button>
          <Button
            darkcolor={true}
            backgroundColor={"#E17A40"}
            onClick={() => {
              props.setIsOpen(false);
            }}
          >
            Avbryt
          </Button>
        </ButtonBar>
      </Content>
    </Modal>
  </Fragment>
);

export default DamageModal;

export const ButtonBar = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
`;
