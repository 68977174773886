import styled from "styled-components";

interface LinkStyle {
    color?: string
    fontSize?: string
    fontWeight?: string
  }
  
interface LinkProps extends LinkStyle {
    hover?: LinkStyle
}
  
export const Link = styled.a<LinkProps>`
    color: ${(props) => props.color || props.theme.colors.mainGreen};
    font-size: ${(props) => props.fontSize || props.theme.fontSizes.lg};
    font-weight: ${(props) => props.fontWeight || props.theme.fontWeights.lighter};
    &:hover {
      color: ${(props) => props.hover?.color || props.theme.colors.darkGreen};
      font-size: ${(props) => props.hover?.fontSize || props.fontSize || props.theme.fontSizes.lg};
      font-weight: ${(props) => props.hover?.fontWeight || props.theme.fontWeights.lighter};
    }
`

interface HorizontalProps {
    position?: string
    height?: string
    width?: string
}
  
export const Horizontal = styled.div<HorizontalProps>`
    position: ${props => props.position || 'relative'};
    height: ${(props) => props.height || '10px'};
    width: ${(props) => props.width || '100%'}
`