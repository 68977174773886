import React, { useState } from "react";
import styled from "styled-components";
import Placeholder from "./../../assets/icons/placeholder.png";
import SimpleSpinner from "../View/SimpleSpinner";

enum ImageState {
  Initial,
  Loaded,
  Errored,
}

interface ImageLoaderProps {
  src: string;
  alt?: string;
  imageStyle?: any;
}

const changeOpacity = (imageState: ImageState) => ({
  opacity: imageState === ImageState.Loaded ? 1 : 0,
});

const ImageLoader = ({ src, alt = "", imageStyle = {} }: ImageLoaderProps) => {
  const [imageState, setImageState] = useState(ImageState.Initial);
  const handleImageLoaded = () => setImageState(ImageState.Loaded);
  const handleImageErrored = () => setImageState(ImageState.Errored);
  return (
    <>
      {imageState === ImageState.Initial && (
        <Wrap>
          <Image src={Placeholder} alt="Placeholder" />
          <SpinnerCentered>
            <SimpleSpinner />
          </SpinnerCentered>
        </Wrap>
      )}
      <Image
        style={{ ...changeOpacity(imageState), ...imageStyle }}
        src={src}
        alt={alt}
        onLoad={handleImageLoaded}
        onError={handleImageErrored}
      />
    </>
  );
};

export default ImageLoader;

const Wrap = styled.div`
  position: relative;
`;

const Image = styled.img`
  border-radius: 2px;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const SpinnerCentered = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: calc(50% - 20px);
`;
