import React, { Fragment } from "react";
import { useRecoilValue } from "recoil";
import { Order, CustomerType, Customer, Company, CarType, OrderState } from "../../typings";
import ValueLabel from "../View/ValueLabel";
import Section from "../View/Section";
import ShowDrivingLicense from "../DrivingLicence/ShowDrivingLicense";
import { additionalDriver, currentCarType, insurancePrice } from "../../atoms";
import { CardContainer } from "../../styled-components";
import { getCarTypeTotalPrice } from "../../utils";

interface Props {
  order: Order;
  showForerkortInfo: boolean;
  notLastSection?: boolean;
}
function CustomerInformation(props: Props) {
  const isCustomer =
    props.order.order_customer?.customer_type === CustomerType.Customer;
  const customer = props.order.order_customer?.customer as Customer;
  const company = props.order.order_customer?.company as Company;
  const driver = useRecoilValue(additionalDriver);
  const insurance_price = useRecoilValue(insurancePrice);
  const carType = useRecoilValue(currentCarType);

  return (
    <>
      <Section
        header="DINE OPPLYSNINGER"
        expandable={false}
        lastSectionOnPage={!props.notLastSection}
      >
        <CardContainer>
          <ValueLabel
            value={
              isCustomer
                ? `${customer?.name} ${customer?.surname}`
                : `${company?.name}`
            }
            label="Navn"
            bolderValue
          />
          <ValueLabel
            value={isCustomer ? customer?.email : company?.email}
            label="E-post"
            bolderValue
          />
          <ValueLabel
            value={props.order.order_customer?.car_license_plate as string}
            label="Registreringsnr."
            bolderValue
          />
          <ValueLabel
            value={props.order.insuranceCompany?.name}
            label="Forsikringsselskap"
            bolderValue
          />
          <ValueLabel
            value={props.order.damage_claim_number}
            label="Skadenr."
            bolderValue
          />
          <ValueLabel
            value={props.order.order_customer?.insurance_coverage_type ?? "n/a"}
            label="Leiebildekning"
            bolderValue
          />

          {props.showForerkortInfo && (
            <ValueLabel value={<ShowDrivingLicense />} label="Førerkort" />
          )}
          {props.showForerkortInfo && driver && (
            <Fragment>
              <ValueLabel
                value={<ShowDrivingLicense additionalDriver={true} />}
                label="Førerkort ekstra fører"
              />
              <ValueLabel
                value={driver.phones[0]}
                label="Tlf.nr. ekstra fører"
              />
            </Fragment>
          )}
        </CardContainer>
      </Section>

      {props.order.order_state === OrderState.PendingConfirmation ? (
        <Section header="EKSTRA KOSTNADER" expandable={false}>
          <CardContainer>
            <ValueLabel
              value={getCarTypeTotalPrice(props.order, carType)+" NOK"}
              label="Estimert beløp (med oppgradert bilen) "
              bolderValue
            />
            <ValueLabel
              value={`${insurance_price?.total} NOK`}
              label="Estimert beløp (med redusert egenandel)"
              bolderValue
            />
          </CardContainer>
        </Section>
      ) : null}
    </>
  );
}

export default CustomerInformation;
