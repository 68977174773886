import React, { Fragment } from "react";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import TermsAndConditionsSummary from "./TermsAndConditionsSummary";
import Section from "../View/Section";
import { CardContainer } from "../../styled-components";

interface Props {
  asExpandableContainer: boolean;
}

function TermsAndConditions(props: Props) {
  return (
    <Section expandable={false} header="Leievilkår">
      <CardContainer>
        <TermsAndConditionsSummary />
        <TermsAndConditionsModal
          linktitle="Les fullstendige leievilkår og personvern"
          showSummaryVilkar={false}
        />
      </CardContainer>
    </Section>
  );
}

export default TermsAndConditions;
