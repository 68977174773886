import React, { ReactNode } from "react";
import styled, { DefaultTheme } from "styled-components";
interface LabelProps {
  theme: DefaultTheme,
  marginBottom?: string;
}
const ValueLabelContainer = styled.div`
  width: 100%;
  width: 100%;
  max-width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: ${(props: LabelProps) => props.marginBottom ?? props.theme.margins.lg};
`;
const Label = styled.div`
  font-size: ${(props) => props.theme.fontSizes.sm};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  text-align: start;
`;

const Value = styled.div`
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.lighter};
  text-align: start;
  margin-top: 3px;
  width: 100%;
  @media (max-width: 768px) {
    font-size: ${(props) => props.theme.fontSizes.xs};
  }
`;
const BolderValue = styled.div`
  font-size: ${(props) => props.theme.fontSizes.lg};
  font-weight: ${(props) => props.theme.fontWeights.superBold};
  text-align: start;
  margin-top: 3px;
  width: 100%;
`;

interface Props {
  label: string;
  bolderValue?: boolean;
  value: string | ReactNode;
  marginBottom?: string;
}
function ValueLabel(props: Props) {
  return (
    <ValueLabelContainer marginBottom={props?.marginBottom}>
      <Label>{`${props.label}`}</Label>
      {props.bolderValue? <BolderValue>{props.value}</BolderValue> : <Value>{props.value}</Value>}
    </ValueLabelContainer>
  );
}

export default ValueLabel;
