import React, { Fragment, useEffect, useState } from "react";
import ValueLabel from "../View/ValueLabel";
import Leiebiltype from "./CarType";
import { useRecoilValue, useRecoilState } from "recoil";
import { Order, OrderState } from "../../typings";
import { currentCarType, extraCarType, extraCostState } from "../../atoms";
import {
  formatDate,
  getAllIncludedExtras,
  getCarTypePrice,
  getCarTypeTotalPrice,
  getInsurancePrice,
  getInsuranceTypeByPrice,
} from "../../utils";
import Section from "../View/Section";
import styled from "styled-components";
import Resources, { map_base_url } from "../../resources/Resources";
import { CardContainer, InfoContainer } from "../../styled-components/index";
import pickedupcar from "../../assets/icons/pickedupcar.png";
import deliveredcar from "../../assets/icons/deliveredcar.png";
import arrowDown from "../../assets/icons/arrow_down.svg";
import arrowUp from "../../assets/icons/arrow_up.svg";
import mapPin from "../../assets/icons/map_pin.svg";

interface Props {
  order: Order;
  showLeiebiltypeDropdown: boolean;
  asExpandableContainer: boolean;
}

interface ExtraCostsProps {
  label: string;
  value?: string;
}

function RentalInformation({
  order,
  asExpandableContainer,
  showLeiebiltypeDropdown,
}: Props) {
  const [showPickupInfo, setShowPickupInfo] = useState(false);
  const [showDropOffInfo, setShowDropOffInfo] = useState(false);
  const carType = useRecoilValue(currentCarType);
  const [carTypeExtra, setCarTypeExtra] = useRecoilState(extraCarType);
  const extraCostsData = useRecoilValue(extraCostState);
  const totalPrice =
    extraCostsData && extraCostsData?.extraCosts?.length
      ? // @ts-ignore
        extraCostsData?.extraCosts[0]?.price
      : 0;
  const price = getInsurancePrice(order, totalPrice || 0);
  const insuranceType = getInsuranceTypeByPrice(price);

  useEffect(() => {
    if (!carTypeExtra && !!carType) {
      Resources.carType
        .getExtraCarType(carType.id as string)
        .then(Resources.response.json)
        .then((data) => setCarTypeExtra(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carType]);

  const allIncluedExtras = getAllIncludedExtras(carTypeExtra);
  const includedExtraItems = allIncluedExtras?.map((ie, idx) => (
    <div key={idx}>{ie.name}</div>
  ));

  const pickupPin = `${map_base_url}${order?.locations?.pickup?.latitude},${order?.locations?.pickup?.longitude}`;
  const dropOffPin = `${map_base_url}${order?.locations?.dropoff?.latitude},${order?.locations?.dropoff?.longitude}`;
  const pickupAddress = `${order?.locations?.pickup?.address}\n${order?.locations?.pickup?.city}\n${order?.locations?.pickup?.phones[0]}`;
  const dropoffAddress = `${order?.locations?.dropoff?.address}\n${order?.locations?.dropoff?.city}\n${order?.locations?.dropoff?.phones[0]}`;
  const companyName = order?.locations?.dropoff?.companyName;
  const carTypePrice = getCarTypePrice(order, carType);

  function ExtraCosts(props: ExtraCostsProps) {
    return (
      <ExtraCostsContainer>
        <ExtraCostLabel>{`${props.label}`}</ExtraCostLabel>
        <ExtraCostValue>{props.value}</ExtraCostValue>
      </ExtraCostsContainer>
    );
  }

  const getCarTypeDescription = () => {
    if(carTypePrice === 0) {
      return `Leiebiltypen som er valgt er inkludert i forsikringen.s                          
Ved oppgradering påfølger ekstra døgnkostnad.`
    }
    return <div>Ekstra døgnkostnad for oppgradert bilen <b>{`${carTypePrice} NOK`}</b> per dag</div>
  }

  return (
    <Section expandable={asExpandableContainer} header="Leieforhold">
      <Fragment>
        <CardContainer>
          <CustomCardContainer>
            <ValueLabel
              value={order.order_number as string}
              label="Reservasjonsnr"
              bolderValue={true}
              marginBottom="0"
            />

            <ValueLabel
              value={
                showLeiebiltypeDropdown ? (
                  <Fragment>
                    <Leiebiltype />
                    <LeiebiltypeDescription>
                      {getCarTypeDescription()}
                    </LeiebiltypeDescription>
                  </Fragment>
                ) : (
                  carType && carType.name
                )
              }
              label="Leiebiltype"
              marginBottom="0"
            />
          </CustomCardContainer>
          {order.order_state !== OrderState.PendingConfirmation ? (
            <>
              <HorizontalBar />
              <ExtraCosts
                value={getCarTypeTotalPrice(order, carType) + " NOK"}
                label="Estimert beløp (med oppgradert bilen)"
              />
              <ExtraCosts
                value={`${totalPrice} NOK`}
                label="Estimert beløp (med redusert egenandel)"
              />
            </>
          ) : null}
        </CardContainer>

        <Container>
          <InfoContainer>
            <ImageContainer src={pickedupcar} alt="pickup car" />
            <ColumnContainer>
              <InfoTitle>Hentes</InfoTitle>
              <DateInfo>
                {formatDate(order.timeStamp_start_planned as string)}
              </DateInfo>
              <CompanyInfo>{companyName}</CompanyInfo>

              {showPickupInfo ? (
                <>
                  <LocationInfo>{pickupAddress}</LocationInfo>
                  <MapInfo href={pickupPin} target="_blank">
                    <MapPin src={mapPin}></MapPin>
                    Vis kart
                  </MapInfo>
                </>
              ) : null}
            </ColumnContainer>
            <MoreInfo onClick={() => setShowPickupInfo(!showPickupInfo)}>
              {showPickupInfo ? "LUKK" : "MER INFORMASJON"}{" "}
              <Arrow
                src={showPickupInfo ? arrowUp : arrowDown}
                alt="arrow down"
              />
            </MoreInfo>
          </InfoContainer>
          <InfoContainer>
            <ImageContainer src={deliveredcar} alt="deliver car" />
            <ColumnContainer>
              <InfoTitle>Leveres</InfoTitle>
              <DateInfo>
                {formatDate(order.timeStamp_end_planned as string)}
              </DateInfo>
              <CompanyInfo>{companyName}</CompanyInfo>

              {showDropOffInfo ? (
                <>
                  <LocationInfo>{dropoffAddress}</LocationInfo>
                  <MapInfo href={dropOffPin} target="_blank">
                    <MapPin src={mapPin}></MapPin>
                    Vis kart
                  </MapInfo>
                </>
              ) : null}
            </ColumnContainer>
            <MoreInfo onClick={() => setShowDropOffInfo(!showDropOffInfo)}>
              {showDropOffInfo ? "LUKK" : "MER INFORMASJON"}{" "}
              <Arrow
                src={showDropOffInfo ? arrowUp : arrowDown}
                alt="arrow down"
              />
            </MoreInfo>
          </InfoContainer>
        </Container>  
      </Fragment>
    </Section>
  );
}

const Icon = styled.img`
  width: 8px;
`;

const RadioButtonContainer = styled.div`
  display: flex;
  margin-top: 5px;
  margin-bottom: 15px;
  justify-content: space-between;
  align-items: flex-start;
`;

const LinkContainer = styled.div`
  color: ${(props) => props.theme.colors.cyan};
  font-size: ${(props) => props.theme.fontSizes.xxs};
  text-decoration: underline;
  display: flex;
  align-items: flex-end;
  gap: 3px;
  margin-top: 5px;
`;

const LeiebiltypeDescription = styled.div`
  font-size: ${(props) => props.theme.fontSizes.xs};
  font-weight: ${(props) => props.theme.fontWeights.lighter};
  margin-top: ${(props) => props.theme.margins.sm};
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: -20px;
  margin-top: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  margin: 15px 0px;
`;

export const CustomCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
`;

const MapPin = styled.img`
  width: 20px;
`;

const Label = styled.span`
  font-weight: ${(props) => props.theme.fontWeights.superBold};
  font-size: ${(props) => props.theme.fontSizes.xs};
`;

const Title = styled.div`
  font-size: ${(props) => props.theme.fontSizes.sm};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  text-align: start;
`;

const InfoTitle = styled.div`
  font-size: ${(props) => props.theme.fontSizes.xs};
`;
const DateInfo = styled.div`
  font-size: ${(props) => props.theme.fontSizes.xl};
  font-weight: ${(props) => props.theme.fontWeights.superBold};
`;
const CompanyInfo = styled.div`
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.superBold};

  @media screen and (max-width: 786px) {
    font-size: ${(props) => props.theme.fontSizes.lg};
  }
`;
const LocationInfo = styled.div`
  font-size: ${(props) => props.theme.fontSizes.sm};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  white-space: pre-wrap;
`;

const MapInfo = styled.a`
  font-size: ${(props) => props.theme.fontSizes.sm};
  border: 1px solid #000000;
  text-align: center;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: ${(props) => props.theme.colors.black};
  max-width: fit-content;
`;
const MoreInfo = styled.div`
  font-size: ${(props) => props.theme.fontSizes.sm};
  color: ${(props) => props.theme.colors.mainGreen};
  text-decoration: underline;
  display: flex;
  align-items: center;
  align-self: self-end;
  gap: 5px;
  width: 160px;
  justify-content: flex-end;
  cursor: pointer;
  position: absolute;
  right: 5%;
  margin-bottom: -12px;
  width: fit-content;
  padding: 10px;
`;

const ImageContainer = styled.img`
  width: 90px;
  height: 90px;
  margin-left: 3%;

  @media screen and (max-width: 786px) {
    margin-left: 0;
  }
`;

const Arrow = styled.img`
  width: 10px;
`;

const Price = styled.div`
  font-family: ${(props) => props.theme.fonts.default};
  font-size: ${(props) => props.theme.fontSizes.xs};
  display: flex;
  align-items: flex-end;
  gap: 3px;
  background-color: ${(props) => props.theme.colors.lightGrey};
  padding: 5px;
  width: 170px;
  border-radius: 2px;
  justify-content: center;
  font-weight: ${(props) => props.theme.fontWeights.bolder};

  @media screen and (max-width: 786px) {
    width: 72px;
  }
`;

const SubText = styled.div`
  font-size: ${(props) => props.theme.fontSizes.xxs};
  font-weight: ${(props) => props.theme.fontWeights.normal};
`;

const EstimatedText = styled.div`
  font-size: ${(props) => props.theme.fontSizes.xs};
  font-weight: ${(props) => props.theme.fontWeights.superBold};
`;

export const HorizontalBar = styled.div`
  font-size: ${(props) => props.theme.fontSizes.sm};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid;
  padding: 12px 0px 5px 0px;
  border-top-color: ${(props) => props.theme.colors.mainGreen};
`;

const ExtraCostsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const ExtraCostLabel = styled.div`
  font-size: ${(props) => props.theme.fontSizes.sm};
  font-weight: ${(props) => props.theme.fontWeights.normal};
  text-align: start;
`;

const ExtraCostValue = styled.div`
  font-size: ${(props) => props.theme.fontSizes.md};
  font-weight: ${(props) => props.theme.fontWeights.superBold};
  text-align: start;
  word-wrap: break-word;
  padding-right: 5px;
`;

export default RentalInformation;
